import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import {
  addConnectionComponent,
  createConnection,
  createConnectionComponent,
  deleteConnection,
  getConnection,
  removeConnectionComponent,
  resetConnection,
  updateConnection,
  updateConnectionComponent,
  setConnectionField,
  deleteConnectionComponent,
  updateComponent,
  createComponent,
  deleteComponent,
  setConnectionComponent,
  moveConnectionComponent,
  getConnectionLogicalServices,
  setConnectionDetail,
  getConnectionAuditHistory,
  addConnectionNote,
  updateConnectionNote,
  removeConnectionNote
} from '../../../actions/connection';
import ConnectionMap from './ConnectionMap';
import AddComponentButton from './AddComponentButton';
import LoadingOverlay from '../../../components/LoadingOverlay';
import form from './form';
import isEmpty from 'lodash.isempty';
import { nanoid } from 'nanoid';
import { detailedDiff, diff, updatedDiff } from 'deep-object-diff';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { NNIService } from '../../../utils/ServiceDB/NNI';
import { PortService } from '../../../utils/ServiceDB/Port';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import SyncButton from './SyncButton'
import { ConnectionService } from '../../../utils/ServiceDB/Connection'
import { DeviceDeploymentEnums } from '../../../utils/Constants/DeviceDeployment';
import { canAccessServiceDb, checkPermission, isSdbManager } from '../../../utils/Auth/AuthService'
import { DeviceDeploymentService } from '../../../utils/ServiceDB/DeviceDeployment';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import DeactivatedEntityWarning from '../../../components/Alerts/DeactivatedEntityWarning';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CollapsibleCard from '../../../components/CollapsibleCard';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';
import ConnectionComponent from './Components/ConnectionComponent';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import queryString from 'query-string';
import AuditHistory from '../../../components/AuditHistory';
import Notes from '../../../components/Notes';
import { formValidator } from '../../../helpers/FormValidator';
import PatchLeadForm from './Components/PatchLead/form';
import WavelengthForm from './Components/Wavelength/form';
import { CarrierCircuitService } from '../../../utils/ServiceDB/CarrierCircuit';
import { setConfirmDialog } from '../../../actions/dialogs';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { defaultErrorFeedback, successFeedback } from '../../../actions/feedback'
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const Connection = ({
  id = null,
  original,
  data,
  aEnd = null,
  bEnd = null,
  logicalServiceTypes,
  logicalServices,
  permissions,
  closeModal = null,
  audits,
}) => {

  // router
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [componentsToDetach, setComponentsToDetach] = useState([]);


  const connectionId = useMemo(() => (id || params.connectionId), [id, params.connectionId])
  const isNew = useMemo(() => connectionId === 'new' && !original.id, [connectionId, original.id])
  const queryParams = useMemo(() => isNew ? queryString.parse(location?.search) : null, [isNew, location?.search]);


  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const includes = [
    'components',
    'components.data.aEnd',
    'notes',
    'aEnd',
    'aEnd.deviceDeployment',
    'aEnd.deviceDeployment.deviceModel',
    'aEnd.deviceDeployment.ports',
    'aEnd.deviceDeployment.ports.nni',
    'aEnd.deviceDeployment.ports.connection',
    'aEnd.port.deviceDeployment',
    'aEnd.port.deviceDeployment.deviceModel',
    'aEnd.port.deviceDeployment.ports',
    'aEnd.port.deviceDeployment.ports.nni',
    'aEnd.port.deviceDeployment.ports.connection',
    'bEnd',
    'bEnd.deviceDeployment',
    'bEnd.deviceDeployment.deviceModel',
    'bEnd.deviceDeployment.ports',
    'bEnd.deviceDeployment.ports.nni',
    'bEnd.deviceDeployment.ports.connection',
    'bEnd.port.deviceDeployment',
    'bEnd.port.deviceDeployment.deviceModel',
    'bEnd.port.deviceDeployment.ports',
    'bEnd.port.deviceDeployment.ports.nni',
    'bEnd.port.deviceDeployment.ports.connection',
    'zabbix'
  ].join(',');

  const withs = 'components';
  const portWiths = [
    'deviceDeployment',
    'deviceDeployment.deviceModel',
    'deviceDeployment.ports',
    'deviceDeployment.ports.nni',
    'deviceDeployment.ports.aConnection',
    'deviceDeployment.ports.bConnection',
  ].join(';');
  const nniWiths = [
    'port.deviceDeployment',
    'port.deviceDeployment.deviceModel',
    'port.deviceDeployment.ports',
    'port.deviceDeployment.ports.nni',
    'port.deviceDeployment.ports.aConnection',
    'port.deviceDeployment.ports.bConnection',
  ].join(';');

  const aEndDevice = useMemo(() => {
    if (data.aEndDevice) {
      return data.aEndDevice
    }
    if (isEmpty(data.aEnd?.data)) {
      return null
    }
    switch (data.aEnd?.type) {
      case 'lnsCluster':
        return { ...data.aEnd.data, isCluster: true }
      case 'port':
        return data.aEnd?.data?.deviceDeployment
      case 'nni':
        return data.aEnd?.data?.port.deviceDeployment
    }
  }, [data.aEndDevice, data.aEnd?.data, data.aEnd?.type])
  const bEndDevice = data.bEndDevice
    || (!isEmpty(data.bEnd?.data) ? (data.bEnd?.type === 'port' ? data.bEnd?.data?.deviceDeployment : data.bEnd?.data?.port.deviceDeployment) : undefined);

  useEffect(() => {
    if(!isNew){
      fetch()
    }else{
      toggleLoading()
      loadEnds().then((response) => toggleLoading())
    }
    return () => dispatch(resetConnection())
  }, []);
  useEffect(() => {
    if (!closeModal) {
      dispatch(addBreadcrumbs([{ name: original.name || 'New'}]));
    }

    return () => {
      if(!closeModal){
        dispatch(resetBreadcrumbs());
      }
    };
  }, [original.name]);

  const loadLogicalServices = () => {
    setLoadingServices(true)
    dispatch(getConnectionLogicalServices(connectionId, logicalServiceTypes))
      .then(() => setLoadingServices(false))
  }

  const getTypeFromPortData = (port) => !isEmpty(port.nni) ? 'nni' : 'port';
  const getDataFromPortData = (port, deviceDeployment) => (
    !isEmpty(port.nni)
      ? { ...port.nni, port: { ...port, deviceDeployment } }
      : { ...port, deviceDeployment }
  );

  const getPortData = (id) => PortService.getPort(id, [
    'deviceDeployment',
    'deviceDeployment.deviceModel',
    'deviceDeployment.ports',
    'deviceDeployment.ports.nni',
    'deviceDeployment.ports.connection'
  ]);

  const getNNIData = (id) => NNIService.getNNI(id, [
    'port.deviceDeployment',
    'port.deviceDeployment.deviceModel',
    'port.deviceDeployment.ports',
    'port.deviceDeployment.ports.nni',
    'port.deviceDeployment.ports.connection'
  ]);

  const fetch = () => {
    toggleLoading()
    setSelected(undefined)
    setErrors([])
    dispatch(getConnection(connectionId, includes, withs, portWiths, nniWiths)).then(result => {
      if(result){
        dispatch(setConnectionDetail(result));
        if(!isEmpty(logicalServiceTypes)){
          dispatch(getConnectionLogicalServices(connectionId, logicalServiceTypes)).then(() => toggleLoading())
        }else{
          toggleLoading()
        }
      }else{
        toggleLoading()
      }

    })
  }
  const loadEnds = () => {
    const promises = [];
    if (queryParams.side === 'aEnd') {
      let getAEndData;
      switch (queryParams.type) {
        case 'lnsCluster':
          dispatch(setConnectionField('aEnd', {type: 'lnsCluster', data: {id: 1, name: 'LNS Cluster'}}))
          dispatch(setConnectionField('aEndDevice', {id: 1, name: 'LNS Cluster', isCluster: true}))
          break;
        case 'port':
          getAEndData = getPortData;
          break;
        case 'nni':
        default:
          getAEndData = getNNIData;
      }
      if (getAEndData) {
        promises.push(
          getAEndData(queryParams.id).then(result => {
            if (result.data) {
              dispatch(setConnectionField('aEnd', { type: queryParams.type, data: result.data }));
              dispatch(setConnectionField('aEndDevice', result.data.deviceDeployment));
            }
          })
        );
      }
    }
    if (queryParams.side === 'bEnd') {
      const getBEndData = queryParams.type === 'port' ? getPortData : getNNIData;
      promises.push(
        getBEndData(queryParams.id).then(result => {
          if (result.data) {
            dispatch(setConnectionField('bEnd', { type: queryParams.type, data: result.data }));
            dispatch(setConnectionField('bEndDevice', result.data.deviceDeployment));
          }
        })
      );
    }
    if(queryParams.circuit){
      promises.push(CarrierCircuitService.getCarrierCircuit(queryParams.circuit).then(result => {
        if(result.status === 200){
          addNewComponent('carrierCircuit', 1, result.data )
        }
      }))

    }
    return Promise.all(promises);
  };

  const missingCarrierCircuit = (end) => {
    const relevantComponent = data.components[end === 'aEnd' ? 0 : (data.components.length - 1)];
    return !isNew && data[end] && ['nni', 'lnsCluster'].includes(data[end].type)
      && (relevantComponent?.type !== 'carrierCircuit' || isEmpty(relevantComponent?.data))
  };

  const onComponentDetach = (connectionComponentId) => {
    dispatch(removeConnectionComponent(connectionComponentId));
    setComponentsToDetach(prevState => [...prevState, connectionComponentId]);
    setSelected(undefined);
  };

  const getEndValuesForDevice = ({ deviceDeployment, end }) => {
    if (deviceDeployment?.isCluster) {
      return [{
        value: {
          type: 'lnsCluster',
          data: deviceDeployment
        },
        label: 'LNS Cluster'
      }]
    }
    const otherEnd = (end === 'aEnd') ? 'bEnd' : 'aEnd'
    return deviceDeployment?.ports?.map(port => {
      const inUseByOtherConnection = (
        !isEmpty(port.connection) && (
          (Number.isInteger(connectionId) && port.connection.id !== parseInt(connectionId, 10)) ||
          (!Number.isInteger(connectionId) && typeof connectionId === 'string' && port.connection.uuid !== connectionId)
        )
      );
      const inUseByOtherEnd = (data[otherEnd]?.type === 'port' && port.id === data[otherEnd]?.data?.id)
        || (data[otherEnd]?.type === 'nni' && port.nni && port.nni.id === data[otherEnd]?.data?.id);
      const inUse = inUseByOtherConnection || inUseByOtherEnd;
      return ({
        value: {
          type: getTypeFromPortData(port),
          data: getDataFromPortData(port, deviceDeployment)
        },
        label: `${port.name}${!isEmpty(port.nni) ? ` (NNI: ${port.nni.name})` : ''}${inUse ? ' (in use)' : ''}`,
        isDisabled: inUse
      })
    }) || []
  }

  const options = {
    aEnd: getEndValuesForDevice({ deviceDeployment: aEndDevice, end: 'aEnd' }),
    bEnd: getEndValuesForDevice({ deviceDeployment: bEndDevice, end: 'bEnd' })
  };

  const selectValue = {
    aEnd: () => options.aEnd.find(opt => data.aEnd?.type === opt.value.type && opt.value.data?.id === data.aEnd?.data?.id) || null,
    bEnd: () => options.bEnd.find(opt => data.bEnd?.type === opt.value.type && opt.value.data?.id === data.bEnd?.data?.id) || null
  };

  const getLnsClusters = async (search) => {
    const targets = [{ id: 1, name: 'LNS Cluster' }]
    return targets.filter(lnsCluster => {
      return lnsCluster.name.toLowerCase().includes(search.toLowerCase())
    })
  }

  const aSyncOptions = {
    deviceDeployment: (search) => {
      const promises = [];
      promises.push(DeviceDeploymentService.getDeviceDeployments(
        0,
        100,
        `hostname:${search};type:${DeviceDeploymentEnums.type.mux},${DeviceDeploymentEnums.type.nte}`,
        null,
        'items.ports,items.ports.connection,items.ports.nni,items.portConnectionDetails',
        'ports;ports.anyConnection;ports.nni;portsWithConnectionDetails',
        'type:notin',
        'and'
      ).then((result) => result.data.items))
      promises.push(getLnsClusters(search).then(items => items.map(cluster => ({ ...cluster, isCluster: true }))))
      return Promise.all(promises)
        .then(([result1, result2]) => {
          return [...result1, ...result2];
        })
    }
  };

  const requestStructure = {
    connection: (data) => ({
      name: data.name,
      description: data.description,
      aEndId: data.aEnd?.data?.id,
      aEndType: data.aEnd?.type,
      bEndId: data.bEnd?.data?.id,
      bEndType: data.bEnd?.type
    }),
    connectionComponent: (connection, { type, order, data }) => ({
      connectionId: connection.id,
      componentType: type,
      componentId: data.id,
      order
    }),
    crossConnect: ({ name, supplier, circuitId, type }) => ({
      name,
      supplierId: supplier?.accountnumber,
      circuitId,
      type
    }),
    carrierCircuit: ({ carrierCircuit }) => ({
      carrierCircuit: carrierCircuit?.id
    }),
    patchLead: ({ name, length, type, color }) => ({ name, length, type, color }),
    wavelength: ({ name, opticalSystem, channel }) => ({
      name,
      opticalSystemId: opticalSystem?.id,
      channel
    })
  };

  const addNewComponent = (componentType, order, componentData) => {
    const componentId = nanoid();
    dispatch(addConnectionComponent({
      id: componentId,
      type: componentType,
      order: order || data.components.length + 1,
      data: componentData ?? getComponentInitialState(componentType)
    }));
    setSelected({ type: 'component', id: componentId });
  };

  const getComponentInitialState = (type) => {
    return type === 'wavelength' ? {opticalSystem: {}} : {}
  }
  const setField = (field, value) => {
    if (field === 'aEndDevice') {
      if (value.isCluster) {
        dispatch(setConnectionField('aEnd', {type: 'lnsCluster', data: value}));
      } else {
        dispatch(setConnectionField('aEnd', {}));
      }
    } else if (field === 'bEndDevice') {
      dispatch(setConnectionField('bEnd', {}));
    }
    dispatch(setConnectionField(field, value));
  };

  const fullRequestStructure = (data) => {
    return {
      ...requestStructure.connection(data),
      components: data.components.map(component => {
        return {
          ...requestStructure.connectionComponent(data, component),
          data: requestStructure[component.type](component.data)
        };
      })
    };
  };

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        setSelected(undefined)
        create().then((result) => {
          if(result.errors){
            setErrors((prevState) => [...prevState, ...ApiErrorResolver(result.errors)]);
            toggleLoading()
          }else if(result){
            const promises = [];
            if (!isEmpty(data.components)) {
              data.components.forEach(dataComponent => {
                promises.push(dispatch(createConnectionComponent(
                  dataComponent.id,
                  requestStructure.connectionComponent(result, { ...dataComponent })
                )).then(result2 => {
                  if(result2.status === 422){
                    setErrors((prevState) => [...prevState, ...ApiErrorResolver(result2.data, `Component ${dataComponent.order}:`)]);
                  }
                  navigate(`/sdb/connections/${result.id}`)
                }));
                Promise.all(promises)
                  .then(() => toggleLoading());
              });
            }else{
              navigate(`/sdb/connections/${result.id}`);
              toggleLoading()
            }
          }
        })
      }else {
        toggleLoading()
        onUpdate().then(() => toggleLoading())
      }
    }
  }

  const validated = () => {

    let errorArr = formValidator(form, {...data, aEndDevice, bEndDevice, aEndProvided: !!aEnd, bEndProvided: !!bEnd })
    data.components.map((component, index) => {
      if (component.type === 'carrierCircuit') {
        if (!component.data.id) {
          errorArr.push(`Component ${component.order}: Carrier Circuit is required`)
        } else if (data.aEnd?.type === 'nni' && component.type === 'carrierCircuit' && component.data.id && !component.data.aSTag) {
          errorArr.push(`Component ${component.order}: Carrier Circuit A End S-Tag is required when A End type is NNI`)
        } else if (data.aEnd?.type === 'lnsCluster' && component.type === 'carrierCircuit' && component.data.aEnd.type !== 'l2tp') {
          errorArr.push(`Component ${component.order}: Carrier Circuit A End must be of type L2TP when A End type is LNS Cluster`)
        }
      } else if (component.type === 'crossConnect' && !component.data.id) {
        errorArr.push(`Component ${component.order}: Cross Connect is required`)
      } else if (component.type === 'patchLead') {
        errorArr = errorArr.concat(formValidator(PatchLeadForm, component.data).map(error => `Component ${component.order}: ${error}`))
      } else if (component.type === 'wavelength') {
        errorArr = errorArr.concat(formValidator(WavelengthForm, component.data).map(error => `Component ${component.order}: ${error}`))
      }
    })
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const create = () => {
    setErrors([]);
    return dispatch(createConnection(omitBy(requestStructure.connection(data), isNull), includes, withs, portWiths, nniWiths))
  };

  const onUpdate = () => {
    setErrors([]);
    setSelected(undefined)
    const promises = [];
    const deleted = [];
    const difference = detailedDiff(fullRequestStructure(original), fullRequestStructure(data));
    original.components.forEach(component => {
      if (!data.components.find(dataComponent => dataComponent.id === component.id)) {
        deleted.push(component);
        if (componentsToDetach.find(componentToDetach => componentToDetach === component.id)) {
          promises.push(dispatch(deleteConnectionComponent(component.id)));
        } else {
          promises.push(dispatch(deleteComponent(component)));
        }
      }
    });
    data.components.forEach(dataComponent => {
      if (!original.components.find(component => component.id === dataComponent.id)) {
        if (dataComponent.type === 'carrierCircuit' || dataComponent.type === 'crossConnect') {
          promises.push(dispatch(createConnectionComponent(
            dataComponent.id,
            requestStructure.connectionComponent(original, { ...dataComponent })
          )));
        } else {
          promises.push(dispatch(createComponent(dataComponent.type, requestStructure[dataComponent.type](dataComponent.data)))
            .then((result) => {
              if(result.errors){
                setErrors((prevState) => [...prevState, ...ApiErrorResolver(result.errors, `Component ${dataComponent.order}:`)]);
              }
              else if (result) {
                return dispatch(createConnectionComponent(
                  dataComponent.id,
                  requestStructure.connectionComponent(original, { ...dataComponent, data: result })
                ));
              }
              return false;
            }));
        }
      }
    });
    if (!isEmpty(difference.updated?.components)) {
      original.components.forEach(originalComponent => {
        const dataComponent = data.components.find(component => component.id === originalComponent.id);
        const inDeleted = deleted.find(deletedItem => deletedItem.id === originalComponent.id);
        if (!inDeleted) {
          const diffComponent = updatedDiff(
            requestStructure[originalComponent.type](originalComponent.data),
            requestStructure[originalComponent.type](dataComponent.data)
          );
          const diffConnectionComponent = updatedDiff(
            requestStructure.connectionComponent(original, originalComponent),
            requestStructure.connectionComponent(data, dataComponent)
          );
          if (!isEmpty(diffComponent)) {
            promises.push(dispatch(updateComponent(originalComponent, diffComponent))
              .then(result => {
                if(result.errors){
                  setErrors((prevState) => [...prevState, ...ApiErrorResolver(result.errors, `Component ${originalComponent.order}:`)]);
                }
                else if (result) {
                  if (!isEmpty(diffConnectionComponent)) {
                    return dispatch(updateConnectionComponent(
                      originalComponent.id,
                      requestStructure.connectionComponent(data, dataComponent)
                    ));
                  }
                }
                return result;
              }));
          } else {
            if (!isEmpty(diffConnectionComponent)) {
              promises.push(dispatch(updateConnectionComponent(
                originalComponent.id,
                requestStructure.connectionComponent(data, dataComponent)
              )));
            }
          }
        }
      });
    }
    return Promise.all(promises).then(results => {
      results.filter(result => result?.status === 422)
        .forEach(result => {
          setErrors((prevState) => [...prevState, ...new Set(ApiErrorResolver(result.data))]);
        });
      // check if all the components saved successfully
      if (results.filter(result => result?.status === 200).length !== promises.length) {
        return false
      }
      // check if there are any changes to the connection object itself
      const toUpdate = diff(requestStructure.connection(original), requestStructure.connection(data));
      if (!isEmpty(toUpdate)) {
        // type is a required field if id is supplied
        if (toUpdate.aEndId && !toUpdate.aEndType) {
          toUpdate.aEndType = data.aEnd.type;
        }
        if (toUpdate.bEndId && !toUpdate.bEndType) {
          toUpdate.bEndType = data.bEnd.type;
        }
        return dispatch(updateConnection(connectionId, toUpdate, includes, withs, portWiths, nniWiths)).then(result => {
          if(result.errors){
            setErrors((prevState) => [...prevState, ...ApiErrorResolver(result.errors)]);
          }
        });
      }
      return true;
    });
  };

  const handleSyncConnectionWithCrm = useCallback(() => {
    setLoading(true)
    ConnectionService.syncConnection(connectionId)
      .then((result) => {
        if (result.status === 204) {
          dispatch(successFeedback('The connection was successfully synced'));
          setErrors([])
        } else if (result.status === 422 && result.data) {
          setErrors(ApiErrorResolver(result.data))
        } else {
          dispatch(defaultErrorFeedback())
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [connectionId])

  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const componentIsSaved = (componentId) => {
    return original.components.find(component => component.id === componentId)
  }

  const canDelete = () => isSdbManager()

  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this connection!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteConnection(connectionId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/connections')
          }
        })
      }
    }))
  }

  const canEdit = () => canAccessServiceDb()

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }
  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getConnectionAuditHistory(connectionId)).then(() => toggleAuditsLoading())
  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-3'>
          <CardBody>
            <Row className='mb-2'>
              <Col className="d-flex justify-content-between">
                <CardTitleBold>
                  {original.name || 'New Connection'}
                </CardTitleBold>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {hasChanges() && (
                    <UnsavedChangesAlert save={save}/>
                  )}
                  {!isNew && (
                    <SyncButton onSubmit={handleSyncConnectionWithCrm}/>
                  )}

                  {!isNew && <ButtonIcon
                    disabled={!canDelete()}
                    icon={'fa fa-trash'}
                    tooltip={!canDelete() ? 'Only service database administrators may delete connections' : 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canEdit() || loading || !hasChanges() || missingCarrierCircuit('aEnd') || missingCarrierCircuit('bEnd')}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetch}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <DeactivatedEntityWarning deactivated={missingCarrierCircuit('aEnd') || missingCarrierCircuit('bEnd')} message={'Your connection is missing a Carrier Circuit!'}/>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={classnames('d-flex col-12 col-sm-12', {'col-md-8' : !closeModal})}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={{...data, aEndDevice, bEndDevice, aEndProvided: !!aEnd, bEndProvided: !!bEnd }}
                    form={form}
                    setField={setField}
                    options={options}
                    aSyncOptions={aSyncOptions}
                    selectValue={selectValue}
                  />
                </EntityMainFormCard>
              </Col>
              {
                selected !== undefined &&
                <Col lg={4} className={"d-flex"}>
                  <div className={"d-flex w-100"}>
                    <ConnectionComponent
                      component={selected}
                      componentData={selected}
                      onUpdate={(id, data) =>{
                        dispatch(setConnectionComponent(id, data))
                      }}
                      onMove={(id, direction) => dispatch(moveConnectionComponent(direction, id))}
                      onDetach={(id) => onComponentDetach(id)}
                      isSaved={componentIsSaved(selected?.id)}
                    />
                  </div>
                </Col>
              }
            </Row>
            {
              (!isNew  || original.components.length > 0 ) ?
              <>
                <Row>
                  <Col>
                    <EntitySubFormCard title={'Map'} headerExtraRight={<AddComponentButton onSubmit={addNewComponent}/>}>
                      <Row>
                        <Col>
                          <ConnectionMap connection={data} selected={selected} setSelected={setSelected}/>
                        </Col>
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>
               {/* <Row>
                  <Col>
                    <EntitySubFormCard title={'Logical Services'}>
                      <Row>
                        <Col>
                          <LoadingOverlay loading={loadingServices}>
                            <ConnectionServicesTable
                              services={logicalServices}
                              logicalServiceTypes={logicalServiceTypes}
                              fetchData={loadLogicalServices}
                              canAddAndDelete={canAccessServiceDb()}
                              connection={original}
                            />
                          </LoadingOverlay>

                        </Col>
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>*/}
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{
                          type: 'connection',
                          data
                        }}
                        onCreated={note => dispatch(addConnectionNote(note))}
                        onUpdated={note => dispatch(updateConnectionNote(note))}
                        onDeleted={note => dispatch(removeConnectionNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </> : ''
            }
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
};

function mapStateToProps({ connection, authenticationState, helpers }) {
  return {
    permissions: authenticationState.account.permissions,
    data: connection.data,
    original: connection.original,
    audits: connection.audits,
    logicalServiceTypes: helpers.serviceDb.logicalServiceTypes,
    circuitTypes: helpers.optionSets.carrierCircuit.type,
    serviceTypes:  helpers.optionSets.circuit.circuitType,
    circuitOptionSets: helpers.optionSets.carrierCircuit,
    logicalServices: connection.logicalServices,
    suppliers: helpers.suppliers,
  };
}

export default connect(mapStateToProps)(Connection);
