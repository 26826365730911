import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row, Badge, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import {
  addNewCircuitCase, addNewCircuitNote, CIRCUIT_SET_DATA,
  createCircuit,
  getCircuit,
  getCircuitEmails,
  getParentCircuit, removeCircuitNote, removeCircuitProduct,
  resetCircuit,
  searchAccounts,
  searchConfigItems,
  searchOrders,
  searchProducts, searchSites,
  setCircuitEmail,
  updateCircuit,
  updateCircuitCase,
  updateCircuitField, updateCircuitNote
} from '../../actions/circuit';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { warningFeedback } from '../../actions/feedback';
import CircuitForm from './form';
import generateFormFields from '../../helpers/FormFieldGenerator';
import resolveArgs from '../../helpers/ArgumentResolver';
import { getActivity } from '../../actions/activity';
import LoadingOverlay from '../../components/LoadingOverlay';
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../components/ButtonIcon';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import CollapsibleCard from '../../components/CollapsibleCard';
import { setConfirmDialog } from '../../actions/dialogs';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import { formValidator } from '../../helpers/FormValidator';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import Site from '../Site';
import Notes from '../../components/Notes';
import OrderProductsTable from '../../components/Tables/OrderProducts';
import { createCircuitAssociation, deleteCircuitAssociation, removeProductCircuit } from '../../actions/product';
import { removeCarrierCircuitProduct } from '../../actions/carrierCircuit';
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import CardTitleBold from '../../components/Cards/CardTitleBold'

const Circuit = ({
  id = null,
  circuit,
  onCreated = null,
  onUpdated = null,
  toggleModal = null,
  product = null,
  suppliers,
  optionSets,
}) => {
  // router
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showNewCase, setShowNewCase] = useState(false);
  const [newCase, setNewCase] = useState({});
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [emailsLoaded, setEmailsLoaded] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [site, setSite] = useState(null);

  const circuitId = useMemo(() => id || params.id, [id, params.id])
  const isNew = useMemo(() => (!circuitId || circuitId === 'new') && !circuit.original.id, [circuitId, circuit.original.id]);

  useEffect(() => {
    if (!isNew && circuit.original.id === undefined) {
      refreshData();
    }
    if (product?.order && isNew) {
      dispatch(updateCircuitField('order', { ...product?.order }));
    }
    return () => {
      dispatch(resetCircuit());
      if(!toggleModal){
        dispatch(resetBreadcrumbs())
      }
    };
  }, []);

  useEffect(() => {
    if(!toggleModal){
      dispatch(addBreadcrumbs([{name: circuit.original.name}]))
    }

  }, [circuit.original.name])

  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }
  const toggleProductsLoading = () => {
    setProductsLoading(prevState => !prevState)
  }
  const toggleLoading = () => setLoading(prevState => !prevState);
  const refreshData = () => {
    toggleLoading();
    dispatch(getCircuit(circuit.original?.id || circuitId)).then(() => toggleLoading());
  };
  const handleInput = (event) => {
    dispatch(updateCircuitField(event.target.id, event.target.value));
  };

  const handleSelectInput = (key, selected) => {
    dispatch(updateCircuitField(key, key === 'supplier' ? suppliers[selected?.value] : selected?.value));
  };

  const handleInputDate = (field, value) => {
    dispatch(updateCircuitField(field, value));
  };

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if (entity === 'supplier') {
      return new Promise(resolve => {
        resolve(filterSuppliers(search));
      });
    }
    if (entity === 'account') {
      return dispatch(searchAccounts(key, search)).then((result) => result);
    }
    if (entity === 'site') {
      return dispatch(searchSites(key, search)).then((result) => result);
    }
    if (entity === 'configItem') {
      return dispatch(searchConfigItems(key, search)).then((result) => result);
    }
    if (entity === 'order') {
      return dispatch(searchOrders(key, search)).then((result) => result);
    }
    if (entity === 'product') {
      return dispatch(searchProducts(key, search)).then((result) => result);
    }
    if (entity === 'circuit') {
      return dispatch(getParentCircuit(search)).then((result) => result);
    }
  };

  const filterSuppliers = (search) => {
    return Object.values(suppliers).filter((option) => {
      return option.name.toLowerCase().includes(search.toLowerCase());
    });
  };
  const toggleNewCase = () => {
    setShowNewCase(!showNewCase);
    setNewCase({});
  };

  const handleAsyncSelected = (key, selected) => {
    dispatch(updateCircuitField(key, selected || null));
  };

  const getSelectedOption = (field) => {
    let selected = [];
    if (field === 'supplier') {
      selected = Object.values(suppliers).filter((option) => {
        return circuit.form[field] && option.id === circuit.form[field].accountid;
      });
    } else if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === circuit.form[field]);
    }
    if (selected.length) {
      return selected[0];
    }

    return null;
  };
  const getSelectOptions = (field) => {
    if (field === 'supplier') {
      const result = Object.values(suppliers).map((option) => {
        return { value: option.id, label: option.name };
      });
      return result;
    }
    if (optionSets[field]) {
      const result = optionSets[field].options.map((option, index) => {
        return { value: option.value, label: option.label };
      });
      return result;
    }
    return [];
  };

  const validated = () => {
    const circuitForms = CircuitForm()
    let errors = formValidator({
      ...circuitForms.generalFields,
      ...circuitForms.supplierFields,
      ...circuitForms.aEndFields,
      ...circuitForms.bEndFields,
    }, circuit.form)

    if (circuit.form.bandwidth && !circuit.form.bandwidthUnit) {
      errors.push('You must provide a bandwidth unit.');
    }
    if (circuit.form.physical && !circuit.form.physicalUnit) {
      errors.push('You must provide a physical unit.');
    }
    setErrors(errors);
    return isEmpty(errors)
  };
  const saveCircuit = () => {
    if (validated()) {
      if (!isNew) {
        const toSave = diff(circuit.original, circuit.form);
        if (!isEmpty(toSave)) {
          toggleLoading()
          dispatch(updateCircuit(circuit.original.id, resolveArgs(toSave))).then((result) => {
            toggleLoading()
            if (result && onUpdated) {
              onUpdated(result);
            }
          });
        } else {
          dispatch(warningFeedback('nothing to update'));
        }
      } else {
        toggleLoading()
        dispatch(createCircuit(resolveArgs(circuit.form)))
          .then((result) => {
            if(result){
              dispatch({ type: CIRCUIT_SET_DATA, data: result });
              if(product){
                dispatch(createCircuitAssociation(product.id, result.id)).then(result2 => {
                  if(result2 && onCreated){
                    onCreated(result);
                  }
                  toggleLoading()
                })
              } else if (onCreated) {
                onCreated(result);
                toggleLoading()
              }else{
                toggleLoading()
              }

            }else{
              toggleLoading()
            }

          });
      }
    }
  };

  const onCreatedCase = (caseData) => {
    dispatch(addNewCircuitCase(caseData));
  };

  const onUpdatedCase = (caseData) => {
    dispatch(updateCircuitCase(caseData));
  };

  const getEmails = () => {
    setLoadingEmails(true);
    dispatch(getCircuitEmails(circuitId || circuit.original.id))
      .then(
        (result) => {
          if (result) {
            setEmailsLoaded(true);
          }
          setLoadingEmails(false);
        }
      );
  };

  const getEmailData = (id) => dispatch(getActivity(id))
    .then((result) => {
      if (result) {
        dispatch(setCircuitEmail(result));
      }
    });
  const hasChanges = () => {
    const changes = diff(circuit.original, circuit.form)
    return !isEmpty(changes)
  }

  const onClosing = () => {
    if(hasChanges() || isNew){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => toggleModal()
      }))
    }else{
      toggleModal()
    }

  }

  const handleClick = (type) => {
    if(type === 'siteA'){
      setSite({
        id: circuit.form.aEnd.accountid
      })
    }
    if(type === 'siteB'){
      setSite({
        id: circuit.form.bEnd.accountid
      })
    }
    toggleEntityModal()
  }

  const onRemoveProductAssociation = (productId) => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to remove the link between this product and the circuit!",
      proceed: () => {
        toggleProductsLoading()
        dispatch(deleteCircuitAssociation(productId, circuit.original.id)).then(result => {
          if(result){
            dispatch(removeCircuitProduct(productId))
          }
          toggleProductsLoading()
        })
      }
    }))
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='mb-0'>
          <CardBody className='bg-light'>
            <Row className='mb-2'>
              <Col className={'d-flex justify-content-between'}>
                <CardTitleBold>
                  {circuit.form.name || (isNew ? 'New Circuit' : '')}
                  <Badge className={'ms-2 p-2 faded-warning'}>CRM</Badge>
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn')}>
                  {
                    !loading && !isNew && hasChanges() && <UnsavedChangesAlert save={saveCircuit}/>
                  }
                  <ButtonIcon  disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={saveCircuit}/>
                  <ButtonIcon  disabled={loading || isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                  {toggleModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
              <FormValidationErrors errors={errors}/>
              <Row className="d-flex justify-content-between">
                <Col className={"d-flex"}>
                  <EntityMainFormCard>
                    <Row form>
                      {generateFormFields(
                        {
                          fields: CircuitForm(handleClick).generalFields,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleAsyncSelected,
                          handleAsyncInput,
                          handleInputDate,
                          data: circuit.form
                        }
                      )}
                    </Row>
                  </EntityMainFormCard>
                </Col>
                <Col className={"d-flex"} >
                  <EntitySubFormCard title={'Supplier'}>
                    <Row form>
                      {generateFormFields(
                        {
                          fields: CircuitForm(handleClick).supplierFields,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleAsyncSelected,
                          handleAsyncInput,
                          handleInputDate,
                          data: circuit.form
                        }
                      )}
                    </Row>
                  </EntitySubFormCard>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EntitySubFormCard title={'A End'}>
                    <Row form>
                      {generateFormFields(
                        {
                          fields: CircuitForm(handleClick).aEndFields,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleAsyncSelected,
                          handleAsyncInput,
                          handleInputDate,
                          data: circuit.form
                        }
                      )}
                    </Row>
                  </EntitySubFormCard>
                </Col>
                <Col>
                  <EntitySubFormCard title={'B End'}>
                    <Row form>
                      {generateFormFields(
                        {
                          fields: CircuitForm(handleClick).bEndFields,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleAsyncSelected,
                          handleAsyncInput,
                          handleInputDate,
                          data: circuit.form
                        }
                      )}
                    </Row>
                  </EntitySubFormCard>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CollapsibleCard
                    title={'Linked Products'}
                  >
                    <LoadingOverlay loading={productsLoading}>
                      <OrderProductsTable
                        excludeColumns={['term', 'install', 'rental']}
                        fetchData={refreshData}
                        remove={(event, productId) => {
                          event.stopPropagation()
                          onRemoveProductAssociation(productId)
                        }}
                        products={circuit.original.products ?? []}
                        //onUpdated={product => dispatch()}
                      />
                    </LoadingOverlay>

                  </CollapsibleCard>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CollapsibleCard
                    open={false}
                    title={'Notes'}
                  >
                    <Notes
                      withNew={!isNew}
                      notes={circuit.form.notes}
                      relatedTo={{type: 'circuit', data: circuit.original}}
                      onCreated={note => dispatch(addNewCircuitNote(note))}
                      onUpdated={note => dispatch(updateCircuitNote(note))}
                      onDeleted={note => dispatch(removeCircuitNote(note))}
                    />
                  </CollapsibleCard>
                </Col>
              </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setSite(null)}
        size={'xlg'}
        toggle={toggleEntityModal}
      >
        <Site id={site?.id} closeModal={toggleEntityModal}/>

      </HeadlessModal>
    </div>
  )

  /*return (
    <Loadable animate={false} active={circuit.loading} spinner spinnerSize={'60px'}
              color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
      <Nav tabs>
        <NavItem>
          <NavLink href="#"
                   className={classnames({ active: activeTab === 0 })}
                   onClick={() => {
                     setActiveTab(0);
                   }}>Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#"
                   className={classnames({ active: activeTab === 1 })}
                   onClick={() => {
                     setActiveTab(1);
                   }}>Notes
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink href="#"
                   className={classnames({ active: activeTab === 2 })}
                   onClick={() => {
                     if (!emailsLoaded) {
                       getEmails();
                     }
                     setActiveTab(2);
                   }}>Emails
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink href="#"
                   className={classnames({ active: activeTab === 3 })}
                   onClick={() => {
                     setActiveTab(3);
                   }}>Cases
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className={'col-md-12 d-flex flex-column'} activeTab={activeTab}>
        <TabPane tabId={0}>
          <Row className={'mb-3'}>
            <Col className={'d-flex justify-content-end'}>
              <Link to={`/accounts/${circuit.original.assignedTo?.id}`}>
                <Button className="ms-2" size={'sm'} color={'secondary'} disabled={!circuit.original.assignedTo?.id}>
                  Go to Account
                </Button>
              </Link>
              <Button className="ms-2" size={'sm'} color={'primary'} onClick={saveCircuit}>
                {savingCircuit ? <Spinner size="sm" color={'light'}/> : <i className='fa fa-save'/>}
              </Button>
              <Button disabled={isNew} className={'ms-2'} size={'sm'} color={'warning'} onClick={refreshData}><i
                className="fa fa-refresh"></i></Button>
            </Col>
          </Row>
          {errors.length > 0
          && <Row>
            <Col>
              <Alert color={'danger'}>
                {errors.map((error, index) => {
                  return <p key={'error' + index}>{error}</p>;
                })}
              </Alert>
            </Col>
          </Row>
          }
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader><strong>Circuit</strong></CardHeader>
                <CardBody>
                  <Row form>
                    {generateFormFields(
                      {
                        fields: CircuitForm.generalFields,
                        handleInput,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput,
                        handleAsyncSelected,
                        handleAsyncInput,
                        handleInputDate,
                        data: circuit.form
                      }
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardHeader><strong>Supplier</strong></CardHeader>
                <CardBody>
                  <Row form>
                    {generateFormFields(
                      {
                        fields: CircuitForm.supplierFields,
                        handleInput,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput,
                        handleAsyncSelected,
                        handleAsyncInput,
                        handleInputDate,
                        data: circuit.form
                      }
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader><strong>A End</strong></CardHeader>
                <CardBody>
                  <Row form>
                    {generateFormFields(
                      {
                        fields: CircuitForm.aEndFields,
                        handleInput,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput,
                        handleAsyncSelected,
                        handleAsyncInput,
                        handleInputDate,
                        data: circuit.form
                      }
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardHeader><strong>B End</strong></CardHeader>
                <CardBody>
                  <Row form>
                    {generateFormFields(
                      {
                        fields: CircuitForm.bEndFields,
                        handleInput,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput,
                        handleAsyncSelected,
                        handleAsyncInput,
                        handleInputDate,
                        data: circuit.form
                      }
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={1}>
          <Row>
            <Col>
              <Notes notes={circuit.form.notes || []} isNew={isNew} refreshData={refreshData} entity='circuit'/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          <Row>
            <Col style={{ minHeight: '60px' }}>
              <LoadingOverlay loading={loadingEmails}>
                <Emails emails={circuit.original.emails || []} loadMore={getEmailData}/>
                {emailsLoaded && isEmpty(circuit.original.emails) ? (
                  <Alert color={'warning'} className={'my-2'}>No emails found for this circuit</Alert>
                ) : ''}
              </LoadingOverlay>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={3}>
          <Row className={'mb-3'}>
            <Col className={'d-flex justify-content-end'}>
              <Button disabled={isNew} className={'me-2'} size={'sm'} color={'primary'} onClick={toggleNewCase}>
                <i className='fa fa-plus'></i>
              </Button>
              <Button disabled={isNew} className={'me-2'} size={'sm'} color={'warning'} onClick={refreshData}><i
                className="fa fa-refresh"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <CasesTable cases={circuit.form.cases || []}/>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal isOpen={showNewCase} toggle={toggleNewCase} className="modal-xlg" backdrop={'static'}>
        <ModalHeader toggle={toggleNewCase}>
          New Case - {circuit.original.name} - ({circuit.original.circuitRef})
        </ModalHeader>
        <ModalBody>
          <Loadable animate={false} active={loadingCase} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'}
                    background={'rgba(255,255,255, 0.8)'}>
            <Row>
              <Col>
                <Case circuit={circuit.original} onCreated={onCreatedCase} onUpdated={onUpdatedCase}/>
              </Col>
            </Row>
          </Loadable>

        </ModalBody>
      </Modal>
    </Loadable>

  );*/
};

function mapStateToProps({ helpers, wipItem, circuit }) {
  return {
    optionSets: helpers.optionSets.circuit,
    wipItem,
    suppliers: helpers.suppliers,
    circuit
  };
}

export default connect(mapStateToProps)(Circuit);
