
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import generateFormFields from '../../helpers/FormFieldGenerator';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs';
import {
  addSignatureFile,
  attachReorder, cancelSignature,
  createSignature,
  deleteFile,
  downloadCurrentDocument,
  downloadDocument,
  downloadFinalDocument,
  getSignature, resendSignature,
  resetSignature, sendSignature,
  setSignatureCompanyContacts, updateSignature,
  updateSignatureField,
  updateSignatureFileList
} from '../../actions/signatures';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../components/ButtonIcon';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import SignatureForm from './form';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import Dropzone from 'react-dropzone-uploader';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import fileDownload from 'js-file-download';
import arrayMove from 'array-move';
import { doMultiSearch } from '../../actions/multiSearch';
import { searchAccounts } from '../../actions/opportunity';
import { getAccountContacts } from '../../actions/account';
import { formValidator } from '../../helpers/FormValidator';
import resolveArgs from '../../helpers/ArgumentResolver';
import omit from 'lodash.omit';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import { getAPIToken } from '../../utils/Auth/AuthService';
import { setConfirmDialog } from '../../actions/dialogs';
import Moment from 'moment/moment';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Environment } from '../../utils/Helpers/Environment';
import { NavigationBlocker } from '../../components/NavigationBlocker';
import CardTitleBold from '../../components/Cards/CardTitleBold'

const getBadgeColor = (status) => {
  switch (status) {
    case 'draft':
      return 'secondary';
    case 'declined':
      return 'danger';
    case 'signed':
      return 'success';
    default:
      return 'warning';
  }
}
const Signature = ({
  signatures: {
    signature
  },
  users,
  auth
}) =>{

  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const { sigId: id } = useParams();

  const [localState, setLocalState] = useState({
    sending: false,
    deleting: {},
    cancelling: false,
    resending: false,
    uploadParams: {
      url: '',
      headers: {}
    }
  })
  const isNew = id === 'new' && !signature?.original.id
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  useEffect(() => {
    if(!isNew){
      refreshData()
      getAPIToken().then(token => {
        setLocalState({...localState, uploadParams: {
            url: process.env.REACT_APP_API_URL + '/signatures/' + id + '/documents',
            headers: {
              Authorization: 'bearer ' + token,
              Accept: 'application/json'
            }
          }})
      })
    }
    return () => {
      dispatch(resetSignature())
      dispatch(resetBreadcrumbs());
    }
  }, [])
  useEffect(() => {
    dispatch(addBreadcrumbs([{name: signature?.original?.title ?? (isNew ? 'New Signature' : '')}]))
  }, [signature])


  const hasChanges = () => {
    const changes = diff(signature.original, signature.form);
    return !isEmpty(changes)
  }

  const refreshData = () => {
    toggleLoading()
    dispatch(getSignature(id)).then(() => toggleLoading())
  }
  const save = () => {
    if(validated()){
      if(isNew){
        let payload = {
          ...resolveArgs(omit(signature.form, ['recipient', 'hSoSignatory'])),
          relatedToType: signature.form.relatedTo?.type || null,
          signatories: [
            {
              id: signature.form.recipient,
              type: 'contact',
            },
            {
              id: signature.form.hSoSignatory.id,
              type: 'user',
            }
          ]
        }
        if(!payload.relatedToType){
          payload = omit(payload, ['relatedToType', 'relatedTo'])
        }
        toggleLoading()
        dispatch(createSignature(payload)).then((result) => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result){
            navigate(`/signatures/${result.id}`);
          }
          toggleLoading()
        })
      }else{
        toggleLoading()
        const args = diff(signature.original, signature.form)
        if(!isEmpty(args.signatories)){
          args.signatories = signature.form.signatories.map(signatory => {
            return {
              id:  signatory.signatory.data.id,
              type: signatory.signatory.type,
              documentTag: signatory.documentTag
            }
          })
        }
        dispatch(updateSignature(signature.original.id, resolveArgs(args))).then((result) => {
          if(result && result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }

  const send = event => {
    toggleLoading()
    dispatch(sendSignature(id)).then(() => toggleLoading());
  };

  const handleResend = () => {
    toggleLoading()
    dispatch(resendSignature(id)).then(() => toggleLoading())
  }

  const cancel = () => {
    toggleLoading()
    dispatch(cancelSignature(id)).then(() => toggleLoading());
  };

  const validated = () => {
    let payload = {
      ...signature.form
    }
    if(!isNew){
      payload.recipient = payload.signatories.find(signatory => signatory.signatory.type === 'contact')?.signatory.data
      payload.hSoSignatory = payload.signatories.find(signatory => signatory.signatory.type === 'user')?.signatory.data
    }
    const errorArr = formValidator(SignatureForm(), payload)
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const getSignatory = (email) => {
    const signatory = signature.original.signatories.find(signatory => signatory.signatory.data.email.toLowerCase() === email.toLowerCase())?.signatory.data ?? null
    return signatory
  }

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if(key === 'company'){
      return dispatch(searchAccounts(search))
        .then((result) => result);
    }
    if(key === 'relatedTo'){
      return dispatch(doMultiSearch(search,signature.form.company.accountid)).then(
        (result) => {
          const newRes = result.map(account => {
            return {
            ...account,

          }}) ?? [];
          return newRes
        }
      );
    }
  }

  const handleAsyncSelected = (key, selected) => {
    dispatch(updateSignatureField({[key] : selected}))
    if(key === 'company'){
      dispatch(updateSignatureField({relatedTo: null, relatedToType: ''}))
      dispatch(getAccountContacts(selected.accountid)).then((result) => {
        dispatch(setSignatureCompanyContacts(result ?? []))
      })

    }
  }
  const handleSelectInput= (field, selected) => {
    if(!isEmpty(signature.form.signatories)){
      const signatories = signature.form.signatories.map(signatory => {
        if(
          (field === 'recipient' && signatory.signatory.type === 'contact') || (field === 'hSoSignatory' && signatory.signatory.type === 'user')){
          return {
            ...signatory,
            signatory: {
              ...signatory.signatory,
              data: selected
            }
          }
        }
        return signatory
      })
      dispatch(updateSignatureField({signatories}))
    }else{
      dispatch(updateSignatureField({[field] : selected}))
    }

  }
  const getSelectedOption = (field) => {
    if(!isEmpty(signature.form.signatories)){
      if(field === 'recipient'){
        const contact = signature.form.signatories.find(signatory => signatory.documentTag === 0)
        return contact?.signatory.data
      }
      if(field === 'hSoSignatory'){
        const user = signature.form.signatories.find(signatory => signatory.documentTag === 1)
        return user?.signatory.data
      }
    }
  }
  const getSelectOptions = (field) => {
    if(field === 'hSoSignatory'){
      return users
    }
    if(field === 'recipient'){
      return signature.original.relatedTo?.type === 'creditRequest' ?
        users :
        signature.form.company?.contacts?.filter(contact => contact.isActive)
    }
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(signature.form.documents, oldIndex, newIndex);
    dispatch(updateSignatureFileList(newOrder));
    let order = [];
    newOrder.forEach((value, index) => {
      order.push({id: value.id, order: index});
    });
    dispatch(attachReorder(signature.original.id, {
      data: order
    }));
  };
  const handleFileDownload = (docId) => {
    toggleLoading()
    downloadDocument(signature.original.id, docId).then((result) => {
      if (result.status === 200) {
        let splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1].replace(/^"|"$/g, ''));
      }
      toggleLoading()
    });
  };

  const handleCurrentDownload = (event) => {
    toggleLoading()
    downloadCurrentDocument(signature.original.id).then((result) => {
      if (result.status === 200) {
        let splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1]);
      }
      toggleLoading()
    }, () => toggleLoading());
  };

  const handleFinalDownload = (event) => {
    toggleLoading()
    downloadFinalDocument(signature.original.id).then((result) => {
      if (result.status === 200) {
        let splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1]);
      }
      toggleLoading()
    });
  };

  const handleFileDelete = file => {
    let old = localState.deleting;
    old[file] = true;
    setLocalState({...localState, deleting: old})
    dispatch(deleteFile(signature.original.id, file));
  };

  const handleChangeStatus = (file, status, allfiles) => {
    if (status === 'done') {
      file.remove();
      dispatch(addSignatureFile(JSON.parse(file.xhr.responseText)));
    }
  };

  const onSending = () => {
    dispatch(setConfirmDialog({
      text: "After sending you will no longer be able to amend this signature request.",
      proceed: () => {
        send()
      }
    }))
  }

  const onCancelling = () => {
    dispatch(setConfirmDialog({
      text: "This will cancel the active signature request.",
      proceed: () => {
        cancel()
      }
    }))
  }

  const cancellable = () => {
    return !isNew && signature.original.status !== 'signed' && signature.original.status !== 'declined'
  }

  const getDocumentDownloadButton = () => {
    if(isNew || signature.original.status === 'draft' || signature.original.status === 'declined'){
      return ''
    }
    if(signature.original.status === 'signed'){
      return <Button color="warning" size={'sm'} disabled={!signature.form.isUploaded} onClick={handleFinalDownload}>
        <i className="icon-cloud-download"/> Download Signed Document</Button>
    }
    return  <Button className={'faded-info'}  color={'info'} size={'sm'} onClick={handleCurrentDownload}>
      <i className="icon-cloud-download"/> Download Incomplete Version</Button>
  }
  const DragHandle = sortableHandle(() => <i className="icon-menu"/>);
  const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
  });
  const SortableItem = sortableElement(({ doc }) => (
    <Row className={'mb-2 d-flex justify-content-between'}>
      <Col className={'d-flex align-items-center'}>
        {signature.form.status === 'draft' && <DragHandle/>}
        <span className={'ms-2'}>{doc.filename}</span>
        <LaddaButton
          className="btn btn-sm btn-outline-secondary btn-ladda ms-auto"
          data-color="blue"
          data-style={ZOOM_OUT}
          onClick={(e) => handleFileDownload(doc.id)}
          style={{ lineHeight: '0.5', verticalAlign: 'top' }}
        >
          <i className="icon-cloud-download"/>
        </LaddaButton>
        {signature.form.status === 'draft'
          && <LaddaButton
            className="btn btn-sm btn-danger btn-ladda ms-2"
            loading={localState.deleting[doc.id]}
            data-color="blue"
            data-style={ZOOM_OUT}
            onClick={(e) => handleFileDelete(doc.id)}
            style={{ lineHeight: '0.5', verticalAlign: 'top', marginLeft: '4px' }}
          >
            <i className="icon-trash"/>
          </LaddaButton>
        }
        <div className={'ms-2'}>
          {doc.has_tags === 1 ? <i className="icon-check"/> : doc.has_tags === 0 ? <i className="icon-close"/> : <i className="icon-question"/>}
        </div>
      </Col>
    </Row>
  ));

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {signature?.original?.title || (isNew ? 'New Signature' : '')}
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  { cancellable() &&
                    <Button className={'ms-2 faded-danger'} size={'sm'} color={'danger'} onClick={onCancelling}>Cancel</Button>
                  }
                  {!isNew && signature.original.status === 'draft' &&
                    <Button disabled={hasChanges()} className={'ms-2'} size={'sm'} color={'primary'} onClick={onSending}>Send</Button>
                  }
                  <ButtonIcon disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-md-4 col-sm-12"}>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: SignatureForm(isNew, getBadgeColor),
                      handleInput : (event) => dispatch(updateSignatureField({[event.target.id] : event.target.value})),
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleAsyncInput,
                      handleAsyncSelected,
                      data: signature.form ?? {}
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
              <Col className={"d-flex flex-column"}>
                <Row className={'d-flex'}>
                  <Col className={"d-flex"} md={12}>
                    <EntitySubFormCard title={'Documents'} grow headerExtraRight={getDocumentDownloadButton()}>
                      <Row className={'d-flex'} style={{ borderBottom: '1px solid #eaeaea', marginBottom: '8px', paddingBottom: '4px' }}>
                        <Col className={'d-flex justify-content-between'}>
                          <CardTitleBold>Filename</CardTitleBold>
                          <CardTitleBold>Tags</CardTitleBold>
                        </Col>

                      </Row>
                      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                        {signature.form?.documents?.map((value, index) => (
                          <SortableItem key={`doc-${value.id}`} index={index} doc={value} />
                        ))}
                      </SortableContainer>
                      {signature.form?.status === 'draft'
                        && <div style={{ marginTop: '20px' }}>
                          <Dropzone
                            inputContent="Drop files or click to upload"
                            styles={{
                              dropzone: { border: '1px dashed #aeaeae', background: '#fafafa' },
                              inputLabel: { color: '#aeaeae' },
                              submitButton: { display: 'none' }
                            }}
                            getUploadParams={() => localState.uploadParams}
                            onChangeStatus={handleChangeStatus}
                            accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                          <p className="text-muted">Supported File Types: Word, Excel, PDF</p>
                        </div>
                      }
                    </EntitySubFormCard>
                  </Col>
                  <Col className={'d-flex'} md={12}>
                    <EntitySubFormCard title={'HelloSign'} grow>
                      {!isEmpty(signature.original.hellosign) &&
                        <>
                          <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Signature ID</div>
                          <div>{signature.original.hellosign?.signature_request_id}</div>
                          <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Complete</div>
                          <div><Badge color={signature.original.hellosign?.is_complete === true ? 'success' : 'warning'}>{signature.original.hellosign?.is_complete === true ? 'Complete' : 'Pending'}</Badge></div>
                          <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Declined</div>
                          <div><Badge color={signature.original.hellosign?.is_declined === true ? 'danger' : 'success'}>{signature.original.hellosign?.is_declined === true ? 'Yes' : 'No'}</Badge></div>
                          <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Has Error</div>
                          <div><Badge color={signature.original.hellosign?.has_error === false ? 'success' : 'danger'}>{signature.original.hellosign?.has_error === false ? 'No' : 'Yes'}</Badge></div>
                          <Row>
                            {signature.original.hellosign?.signatures?.map((sig) => {
                              return (
                                <Col key={'sig_' + sig.signature_id}>
                                  <div className="callout callout-default">
                                    <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">{sig.signer_name}</div>
                                    <div className="small text-muted"><i className="icon-envelope"/> {sig.signer_email_address}</div>
                                    <div className="small text-muted"><i className="icon-eye"/> {sig.last_viewed_at ? Moment(sig.last_viewed_at * 1000).format() : 'Never'}</div>
                                    <div className="small text-muted"><i className="icon-pencil"/> {sig.signed_at ? Moment(sig.signed_at * 1000).format() : 'Never'}</div>
                                    <Badge className={`fade-${getBadgeColor(sig.status_code)}`}>{sig.status_code}</Badge>
                                    {sig.status_code === 'awaiting_signature' && (sig.signer_email_address.toLowerCase().includes('hso.co.uk') === false && sig.signer_email_address.toLowerCase().includes('syntura.io') === false)
                                      && <div style={{ marginTop: '6px' }}>
                                        <LaddaButton
                                          className="btn btn-sm btn-primary btn-ladda px-4"
                                          loading={localState.resending}
                                          data-color="blue"
                                          data-style={ZOOM_OUT}
                                          onClick={handleResend}
                                        >
                                          <i className="icon-envelope"/> Re-Send Link
                                        </LaddaButton>
                                      </div>
                                    }

                                    {
                                      sig.status_code === 'awaiting_signature' &&
                                      (
                                        getSignatory(sig.signer_email_address.toLowerCase())?.email &&
                                        getSignatory(sig.signer_email_address.toLowerCase())?.email.toLowerCase() === sig.signer_email_address.toLowerCase() &&
                                        getSignatory(sig.signer_email_address.toLowerCase())?.id === auth.account.cui)
                                      && <div style={{ marginTop: '6px' }}>
                                        <Link target="_blank"
                                              to={`${signature.original.baseUrl}${sig.signature_id}`} className="btn btn-sm btn-primary px-4"><i className="icon-pencil"/> Click to Sign</Link>
                                      </div>
                                    }
                                    {sig.status_code === 'awaiting_signature' && Environment.isDev()
                                      && <div style={{ marginTop: '6px' }}>
                                        <Link target="_blank"
                                              to={`${signature.original.baseUrl}${sig.signature_id}`} className="btn btn-sm btn-danger px-4"><i className="icon-pencil"/> Click to Sign (DEV)</Link>
                                      </div>
                                    }
                                    {sig.status_code === 'declined'
                                      && <div>
                                        <b>Reason:</b>
                                        <pre>{sig.decline_reason}</pre>
                                      </div>
                                    }
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </>

                      }
                    </EntitySubFormCard>
                  </Col>
                </Row>
              </Col >
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>

  )


}

function mapStateToProps({ accounts, signatures, helpers, authenticationState, multiSearch }) {
  return {
    accounts,
    signatures,
    users: helpers.systemUsers,
    auth: authenticationState,
    multiSearch
  };
}
export default connect(mapStateToProps)(Signature)
