import React, { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle
} from 'reactstrap'
import Callout from '../../components/Callout/Callout'
import moment from 'moment'
import Loadable from 'react-loading-overlay'
import numeral from 'numeral'
import { BillingRunBills } from './BillingRunBills'
import { BillingItems } from './BillingItems'
import { FailedJobs } from './FailedJobs'
import {
  BILLING_RUN_SET_TAX_DATE,
  deleteBillingRun,
  getBillingRun,
  retryFailedBillingJob,
  BILLING_RUN_TOGGLE_COMPLETE_MODAL,
  BILLING_RUN_TOGGLE_BILL_MODAL,
  retryAllFailedBillingJobs,
  toggleRunLoading,
  resetBillingRunData, BILLING_RUN_TOGGLE_ERROR_MODAL, updateBillingRun
} from '../../actions/billingRun'
import { setConfirmDialog } from '../../actions/dialogs'
import { Reports } from './Reports'
import { api_triggerEmailRun, getBillingRunReport } from '../../utils/Billing/BillingService'
import fileDownload from 'js-file-download'
import { DateTimePicker } from 'react-widgets'
import BillingRunBill from './BillingRunBill'
import { BILLING_RUN_BILL_SET_BILL } from '../../actions/billingRunBill'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CardTitleBold from '../../components/Cards/CardTitleBold'

const columnsCeasePending = [
  {
    Header: 'Account',
    accessor: 'account',
    minWidth: 300,
    Cell: props => (
      <a href={props.original.accountUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
    )
  },
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 300,
    Cell: props => (
      <a href={props.original.crmUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
    )
  },
  {
    Header: 'Valid To',
    accessor: 'validTo',
    sortMethod: (a, b) => {
      a = a ? moment(a, 'YYYY-MM-DD') : moment().add(10, 'years')
      b = b ? moment(b, 'YYYY-MM-DD') : moment().add(10, 'years')
      return b.isBefore(a) ? 1 : -1
    }
  },
  {
    Header: 'Billing Start',
    accessor: 'billStart'
  },
  {
    Header: 'Billing End',
    accessor: 'billEnd'
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: props => (
      '£' + numeral(props.value).format('0,0.00')
    )
  },
  {
    Header: 'In Credit',
    accessor: 'inCredit',
    width: 100,
    Cell: props => (
      props.value ? 'Yes' : 'No'
    )
  }
]

const columnsRetiring = [
  {
    Header: 'Account',
    accessor: 'account',
    minWidth: 300,
    Cell: props => (
      <a href={props.original.accountUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
    )
  },
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 300,
    Cell: props => (
      <a href={props.original.crmUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
    )
  },
  {
    Header: 'Valid To',
    accessor: 'validTo',
    sortMethod: (a, b) => {
      a = a ? moment(a, 'YYYY-MM-DD') : moment().add(10, 'years')
      b = b ? moment(b, 'YYYY-MM-DD') : moment().add(10, 'years')
      return b.isBefore(a) ? 1 : -1
    }
  },
  {
    Header: 'Billed To',
    accessor: 'billedTo'
  },
  {
    Header: 'In Credit',
    accessor: 'inCredit',
    width: 100,
    Cell: props => (
      props.value ? 'Yes' : 'No'
    )
  }]

const BillingRun = ({ billingRun, billingRunBill }) => {

  // router
  const navigate = useNavigate();
  const { runId } = useParams();

  // redux
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    let taxDate = moment().hour(0).minutes(0).toDate().toISOString();
    getRunData();
    dispatch({ type: BILLING_RUN_SET_TAX_DATE, date: taxDate });
  }, [dispatch]);

  const toggleCompleteModal = () => {
    dispatch({ type: BILLING_RUN_TOGGLE_COMPLETE_MODAL });
  };

  const toggleBillModal = () => {
    dispatch({ type: BILLING_RUN_TOGGLE_BILL_MODAL });
  };

  const toggleErrorModal = (errorText) => {
    dispatch({ type: BILLING_RUN_TOGGLE_ERROR_MODAL, errorText });
  };

  const getRunData = () => {
    const { taxDate } = billingRun;
    if (!taxDate) {
      let taxDate = moment().hour(0).minutes(0).toDate().toISOString();
      dispatch({ type: BILLING_RUN_SET_TAX_DATE, date: taxDate });
    }
    dispatch(resetBillingRunData());
    dispatch(toggleRunLoading());
    dispatch(getBillingRun(runId)).then(() => dispatch(toggleRunLoading()));
  };

  const deleteRun = () => {
    const { data } = billingRun;
    if (data.jobs.waiting > 0) {
      toggleErrorModal('This bill run has ' + data.jobs.waiting + ' jobs waiting to be processed. You must wait for these to clear first.');
    } else {
      dispatch(toggleRunLoading());
      dispatch(setConfirmDialog({
        text: 'This will delete all bills, items and logs related to this run.',
        proceed: () => {
          dispatch(deleteBillingRun(data.id)).then((result) => {
            if (result) {
              navigate('/billing');
            }
            dispatch(toggleRunLoading());
          });
        },
        cancel: () => dispatch(toggleRunLoading())
      }));
    }
  };

  const commitRun = () => {
    const { data, taxDate } = billingRun;
    if (data.jobs.waiting > 0) {
      toggleErrorModal('This bill run has ' + data.jobs.waiting + ' jobs waiting to be processed. You must wait for these to clear first.');
    } else {
      dispatch({ type: BILLING_RUN_TOGGLE_COMPLETE_MODAL });
      dispatch(toggleRunLoading());
      dispatch(updateBillingRun(runId, {
        taxDate: moment(taxDate).format('YYYY-MM-DD'),
        status: 'commit'
      })).then(() => dispatch(toggleRunLoading()));
    }
  };

  const notify = () => {
    const { data } = billingRun;
    if (data.jobs.waiting > 0) {
      toggleErrorModal('This bill run has ' + data.jobs.waiting + ' jobs waiting to be processed. You must wait for these to clear first.');
    } else {
      dispatch(toggleRunLoading());
      dispatch(setConfirmDialog({
        color: 'warning',
        text: 'This action will trigger a mail run and send all bills out to its recipients.',
        proceed: () => dispatch(updateBillingRun(runId, { status: 'notify' })).then(() => dispatch(toggleRunLoading())),
        cancel: () => dispatch(toggleRunLoading())
      }));
    }
  };

  const complete = () => {
    const { data } = billingRun;
    if (data.jobs.waiting > 0) {
      toggleErrorModal('This bill run has ' + data.jobs.waiting + ' jobs waiting to be processed. You must wait for these to clear first.');
    } else {
      dispatch(toggleRunLoading());
      dispatch(updateBillingRun(data.id, { status: 'completed' })).then(() => dispatch(toggleRunLoading()));
    }
  };

  const retryJob = (id) => {
    const { data } = billingRun;
    dispatch(toggleRunLoading());
    if (id === 'all') {
      dispatch(retryAllFailedBillingJobs(data.id)).then(() => dispatch(toggleRunLoading()));
    } else {
      dispatch(retryFailedBillingJob(data.id, id)).then(() => dispatch(toggleRunLoading()));
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'staging':
      case 'commit':
      case 'notify':
        return 'warning';
      case 'completed':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const setBillToPreview = (bill) => {
    dispatch({ type: BILLING_RUN_BILL_SET_BILL, bill });
    dispatch({ type: BILLING_RUN_TOGGLE_BILL_MODAL });
  };

  const setTaxDate = (date) => {
    dispatch({ type: BILLING_RUN_SET_TAX_DATE, date });
  };

  const triggerReport = (type) => {
    dispatch(toggleRunLoading());
    getBillingRunReport(billingRun.data.id, type).then((result) => {
      if (result.status === 200) {
        let splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1]);
      }
      dispatch(toggleRunLoading());
    });
  };

  const {
    loading,
    data,
    showCompleteModal,
    showBillModal,
    showErrorModal,
    errorModalText
  } = billingRun;
  const activeBills = data.bills ? data.bills.filter((bill) => bill.active === true) : [];
  const excludedBills = data.bills ? data.bills.filter((bill) => bill.active === false) : [];
  const totalValue = activeBills.reduce((carry, bill) => carry += bill.totalValue, 0);

  return (
    <Loadable animate={false} active={loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
      <Card className="h-100">
        <CardBody>
          <Row>
            <Col className={'d-flex flex-row align-items-start'}>
              <Callout header={'Billing Run Id'} text={runId}/>
              <Fragment>
                <Callout header={'Bills'} text={Object.keys(activeBills).length}/>
                <Callout header={'Total Value (Excl. VAT)'} text={'£' + numeral(totalValue).format('0,0.00')}/>
                <Callout header={'Started At'} text={data.startedAt ? moment(data.startedAt).format('DD/MM/YYYY') : '-'}/>
                <Callout header={'Tax Date'} text={data.taxDate ? moment(data.taxDate).format('DD/MM/YYYY') : '-'}/>
                <Callout header={'Started By'} text={data.startedBy}/>
                <Callout textColour={getStatusColor(data.status)} header={'Status'} text={data.status}/>
                <div className="ms-auto d-flex flex-row">
                  {data.status === 'staging' &&
                    <Fragment>
                      <div className={'align-self-center me-2'}>
                        <Button color={'danger'} onClick={deleteRun}>
                          <h4 className="mb-0">Delete</h4>
                        </Button>
                      </div>
                      <div className={'align-self-center me-2'}>
                        <Button color={'warning'} onClick={toggleCompleteModal}>
                          <h4 className="mb-0">Commit</h4>
                        </Button>
                      </div>
                    </Fragment>
                  }
                  {data.status === 'commit' && activeBills.length > 0 &&
                    <div className={'align-self-center me-2'}>
                      <Button color={'warning'} onClick={notify}>
                        <h4 className="mb-0">Notify</h4>
                      </Button>
                    </div>
                  }
                  {data.status === 'notify' && data.jobs.waiting === 0 && data.jobs.failed === 0 &&
                    <div className={'align-self-center me-2'}>
                      <Button color={'warning'} onClick={complete}>
                        <h4 className="mb-0">Complete</h4>
                      </Button>
                    </div>
                  }
                  <div className={'align-self-center'}>
                    <Button color={'primary'} onClick={getRunData}>
                      <h4 className="mb-0"><i className="icon-reload"></i></h4>
                    </Button>
                  </div>
                </div>
              </Fragment>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs>
                <NavItem>
                  <NavLink href="#" className={classnames({ active: activeTab === 0 })} onClick={() => toggle(0)}>
                    Bills <Badge color="info">{Object.keys(activeBills).length}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className={classnames({ active: activeTab === 1 })} onClick={() => toggle(1)}>
                    Excluded <Badge color="danger">{Object.keys(excludedBills).length}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className={classnames({ active: activeTab === 2 })} onClick={() => toggle(2)}>
                    Cease Pending <Badge color="warning">{data.ceasePending ? data.ceasePending.length : 0}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className={classnames({ active: activeTab === 3 })} onClick={() => toggle(3)}>
                    Ceasing <Badge color="warning">{data.ceasing ? data.ceasing.length : 0}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className={classnames({ active: activeTab === 4 })} onClick={() => toggle(4)}>
                    Failed Jobs <Badge color="danger">{data.failedJobs ? data.failedJobs.length : 0}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" className={classnames({ active: activeTab === 5 })} onClick={() => toggle(5)}>
                    Reports
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className={'col-md-12 d-flex flex-column'} activeTab={activeTab}>
                <TabPane tabId={0}>
                  <Row>
                    <Col className="mt-3">
                      <BillingRunBills bills={activeBills} showBill={setBillToPreview}/>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1}>
                  <Row>
                    <Col className="mt-3">
                      <BillingRunBills bills={excludedBills} showBill={setBillToPreview}/>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row>
                    <Col className="mt-3">
                      <BillingItems items={data.ceasePending ? data.ceasePending : []} columns={columnsCeasePending}/>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row>
                    <Col className="mt-3">
                      <BillingItems items={data.ceasing ? data.ceasing : []} columns={columnsRetiring}/>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={4}>
                  <Row>
                    <Col className="mt-3">
                      <FailedJobs jobs={data.failedJobs ? data.failedJobs : []} retry={retryJob}/>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={5}>
                  <Row>
                    <Col className="mt-3">
                      <Reports triggerReport={triggerReport}/>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CompleteModal
        failedJobs={data.failedJobs ? data.failedJobs.length : 0}
        isOpen={showCompleteModal}
        taxDate={billingRun.taxDate}
        toggle={toggleCompleteModal}
        setTaxDate={setTaxDate}
        proceed={commitRun}
      />
      <BillModal
        isOpen={showBillModal}
        toggle={toggleBillModal}
        bill={billingRunBill.bill}
      />
      <ErrorModal isOpen={showErrorModal} toggle={toggleErrorModal.bind(null, null)} error={errorModalText}/>
    </Loadable>
  );
};

const CompleteModal = ({ isOpen, toggle, taxDate, setTaxDate, proceed, failedJobs }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={'modal-warning'}>
      <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
      <ModalBody>
        <p>After this you won't be able to amend this bill run anymore, invoices and reports are going to be
          generated.</p>
        {failedJobs > 0
          && <CardTitleBold className={'text-danger me-1'}>Warning! This run has {failedJobs} failed jobs!</CardTitleBold>

        }
        <FormGroup row className={'d-flex justify-content-center'}>
          <Col md="4">
            <Label htmlFor={'taxDate'}><strong>Tax Date</strong></Label>
          </Col>
          <Col md="8">
            <DateTimePicker
              id={'taxDate'}
              format="DD-MM-YYYY"
              time={false}
              defaultValue={new Date()}
              value={taxDate ? new Date(taxDate) : new Date()}
              onChange={setTaxDate}
              min={moment().startOf('month').toDate()}
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={proceed}>Yes</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

const BillModal = ({ isOpen, toggle, bill }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xlg'}>
      <ModalHeader toggle={toggle}>{bill ? bill.name : ''}</ModalHeader>
      <ModalBody>
        <BillingRunBill/>
      </ModalBody>
    </Modal>
  )
}

const ErrorModal = ({ isOpen, toggle, error }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={'modal-danger'}>
      <ModalHeader toggle={toggle}>Error</ModalHeader>
      <ModalBody>
        <p>{error}</p>
      </ModalBody>
    </Modal>
  )
}

function mapStateToProps({ billingRun, billingRunBill }) {
  return {
    billingRun,
    billingRunBill
  };
}
export default connect(mapStateToProps)(BillingRun);
