import {
  Badge, Button,
  Card,
  CardBody, CardHeader, Col, Input, InputGroup, Row
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import LoadingOverlay from '../../components/LoadingOverlay';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import {
  activatePriceList,
  addPriceListItem,
  clonePriceList,
  createPriceList,
  createPriceListItem,
  deletePriceList,
  deletePriceListItem,
  disablePriceList,
  getPriceList,
  removePriceListItem,
  resetPriceList,
  searchAccounts,
  updatePriceList,
  updatePriceListField,
  updatePriceListItem,
  updatePriceListItemField
} from '../../actions/priceList';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { PriceListForms } from './form';
import { ButtonIcon } from '../../components/ButtonIcon';
import { getProductTemplates } from '../../actions/productTemplates';
import { SelectMod } from '../../components/Selects/SelectMod';
import { nanoid } from 'nanoid';
import isEmpty from 'lodash.isempty';
import { detailedDiff, updatedDiff } from 'deep-object-diff';
import { getAllPriceLists } from '../../actions/priceLists';
import { setConfirmDialog } from '../../actions/dialogs';
import { getAllPricingTypes } from '../../actions/pricingTypes';
import resolveArgs from '../../helpers/ArgumentResolver';
import { GenericModal } from '../../components/Modals/GenericModal';
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs';
import { formValidator } from '../../helpers/FormValidator';
import { NavigationBlocker } from '../../components/NavigationBlocker';
import PriceListItemsTable from '../../components/Tables/PriceListItems';
import CollapsibleCard from '../../components/CollapsibleCard';
import { PriceListEnums } from '../../utils/Constants/PriceList';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import classnames from 'classnames';
import { useInActiveStatus, useInDisabledStatus, useInDraftStatus, useIsPortalType } from '../../hooks/PriceList';
import CardTitleBold from '../../components/Cards/CardTitleBold'


const PriceList = ({
  id = null,
  priceList,
  priceLists,
  templates,
  optionSets,
  pricingTypes
}) => {

  // router
  const navigate = useNavigate()
  const params = useParams()

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [descriptionItem, setDescriptionItem] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [errors, setErrors] = useState([])

  const priceListId = useMemo(() => id || params.id, [id, params.id])
  const isNew = useMemo(() => (priceListId === 'new' && !priceList.original.id), [priceList.original.id,  priceListId]);

  const editableKeyToFocus = useRef('');

  const isPortalType = useIsPortalType(priceList);
  const inDraftStatus = useInDraftStatus(priceList, isNew);
  const inDisabledStatus = useInDisabledStatus(priceList);
  const inActiveStatus = useInActiveStatus(priceList);

  useEffect(() => {
    let promises = [];
    setLoading(true)
    if(isEmpty(templates.list)){
      promises.push(dispatch(getProductTemplates()))
    }
    if(isEmpty(pricingTypes.list)){
      promises.push(dispatch(getAllPricingTypes()))
    }
    if(isEmpty(priceLists.list)){
      promises.push(dispatch(getAllPriceLists()))
    }
    Promise.all(promises).then(() => {
      if(!isNew){
        getData(priceListId)
      }else{
        setLoading(false)
      }
    })
    return () => {
      dispatch(resetPriceList())
    }
  }, [])

  useEffect(() =>{
    if(isNew && !isPortalType() && !isEmpty(priceList.form.company)){
      dispatch(updatePriceListField('company', {}));
    }
  },[priceList.form.type])

  useEffect(() => {
    if (priceList.original.name) {
      dispatch(addBreadcrumbs([{ name: priceList.original.name }]))
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [priceList.original.name]);

  const getData = (priceListId) => {
    setLoading(true)
    dispatch(getPriceList(priceListId || priceList.original.id)).then(() => {
      setLoading(false)
    })
  }

  const handleInput = (event) => {
    editableKeyToFocus.current = ''
    dispatch(updatePriceListField(event.target.id, event.target.value));
  }

  const updateItemField = (id, field) => {
    dispatch(updatePriceListItemField(id, field))
  }

  const getSelectOptions = (field) => {
    return optionSets[field]?.options.map((set) => {
      return {...set}
    });
  }

  const getSelectedOption = (field) => {
    return optionSets[field]?.options.find(option => option.value === (priceList.form[field]?.id ?? priceList.form[field])) || null;
  }

  const handleSelectInput = (field, selected) => {
    dispatch(updatePriceListField(field, { id: selected?.value, name: selected?.label}))
  }

  const handleAsyncSelected = (key, selected) => {
    dispatch(updatePriceListField(key, selected));
  };

  const handleAsyncInput = (entity, key, length, search) => {
    editableKeyToFocus.current = ''
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    return dispatch(searchAccounts(search)).then((result) => result);
  }

  const resolveStatusColour = (status) => {
    switch (status){
      case 'Active':
        return 'success'
      case 'Draft':
        return 'warning'
      default:
        return 'danger'
    }
  }

  const resolveForm = () => {
    if(inDraftStatus() && !isNew){
      return PriceListForms.draft
    }
    return inActiveStatus() || inDisabledStatus() ? PriceListForms.readOnly : PriceListForms.new
  }


  const addItem = (templateId) => {
    const template = templates.list.find(template => template.id === templateId)
    if(template && !priceList.form.items.find(item => item.template.id === templateId)){
      dispatch(addPriceListItem({
        id: nanoid(),
        name: template.name,
        install: 0,
        rental: 0,
        template: {...template},
        pricing: {...template.pricingType}
      }))
    }
  }

  const removeItem = (id) => {
    dispatch(removePriceListItem(id))
  }
  const getTemplateOptions = () => {
    return templates.list.map(template => { return {label: `${template.name} (${template.productCode})`, value: template.id}})
  }

  const save = () => {
    if(validated()){
      if(!isNew && inDraftStatus()){
        setLoading(true);
        const promises = [];
        const added = [];
        const deleted = [];
        const diff = detailedDiff(priceList.original, priceList.form);
        priceList.original.items.forEach(item => {
          if( !priceList.form.items.find(formItem => formItem.id === item.id)){
            deleted.push(item)
            promises.push(dispatch(deletePriceListItem(priceList.original.id, item.id)))
          }
        });
        priceList.form.items.forEach(item => {
          if(!priceList.original.items.find(originalItem => originalItem.id === item.id)){
            added.push(item)
            promises.push(dispatch(createPriceListItem(priceList.original.id, resolveArgs(item))))
          }
        });
        if(!isEmpty(diff.updated?.items)){
          Object.keys(diff.updated.items).forEach(key => {
            const original = priceList.original.items[key]
            const diffItem = updatedDiff(original, diff.updated.items[key]);
            const inAdded = added.find(newItem => newItem.id === original.id)
            const inDeleted = deleted.find(deletedItem => deletedItem.id === original.id)
            if(!inAdded && !inDeleted && !isEmpty(diffItem)){
              promises.push(dispatch(updatePriceListItem(priceList.original.id, original.id, resolveArgs(diffItem))))
            }
          })
        }
        if(!isEmpty(diff.updated?.name) || !isEmpty(diff.updated?.currency)){
          let payload = {}
          if(diff.updated?.name){
            payload['name'] = diff.updated.name
          }
          if(diff.updated?.currency){
            payload['currency'] = diff.updated.currency.id
          }
          promises.push(dispatch(updatePriceList(priceList.original.id, payload)))
        }
      Promise.all(promises).then(() => {
        setLoading(false);
      })

      }else if(!inDraftStatus() && hasChanges()){
        setLoading(true);
        const promises = [];
        const diff = detailedDiff(priceList.original, priceList.form);
        if(!isEmpty(diff.updated?.items)){
          Object.keys(diff.updated.items).forEach(key => {
            const original = priceList.original.items[key]
            const diffItem = updatedDiff(original, diff.updated.items[key]);
            promises.push(dispatch(updatePriceListItem(priceList.original.id, original.id, resolveArgs(diffItem))))
          })
        }
        Promise.all(promises).then(() => {
          setLoading(false);
        })
      } else if(isNew){
        let payload = {
          name: priceList.form.name,
          type: priceList.form.type.id,
          currency: priceList.form.currency.id ?? priceList.form.currency,
          items: priceList.form.items.map((item) => {
            return {
              name: item.name,
              install: item.install,
              rental: item.rental,
              template: item.template.id,
              pricing: item.pricing.id,
              description: item.description,
            }
          }),
        }
        if(isPortalType()){
          payload.company = priceList.form.company.companyId
        }
        setLoading(true);
        dispatch(createPriceList(payload)).then((result) => {
          if(result.id){
            navigate(`/products/pricelists/${result.id}`)
          }else if(result.errors){
            setErrors(Object.values(result.errors))
          }
          setLoading(false)
        })
      }

    }
  }

  const activate = () => {
    setLoading(true);
    const related = getRelatedPriceList();
    if(related){
      dispatch(setConfirmDialog({
        text: `You are about to deactivate "${related.name}" and make "${priceList.form.name}" as the active ${priceList.form.type.name} ${isPortalType() ? `for ${priceList.form.company.name}` : 'price list'}.`,
        proceed: () => {
          dispatch(activatePriceList(priceList.original.id))
            .then(() => setLoading(false))
        },
        cancel: () =>  setLoading(false)
      }))
    }else{
      dispatch(activatePriceList(priceList.original.id))
        .then(() => setLoading(false))
    }

  }

  const disable = () => {
    setLoading(true);
    if(inDraftStatus()){
      dispatch(deletePriceList(priceList.original.id)).then( () => {
        setLoading(false)
        navigate(`/products/pricelists`)
      })
    }else{
      dispatch(disablePriceList(priceList.original.id)).then( () => setLoading(false))
    }

  }

  const clone = () => {
    dispatch(clonePriceList())
    navigate(`/products/pricelists/new`)
  }

  const getRelatedPriceList = () => {
    return isPortalType() ?
      priceLists.list.find(list => list.type.id === priceList.form.type.id && list.company.companyId === priceList.form.company.companyId && list.status.id === PriceListEnums.status.ACTIVE)
      : priceLists.list.find(list => list.type.id === priceList.form.type.id && list.status.id === PriceListEnums.status.ACTIV)
  }

  const validated = () => {
    let errors = formValidator(resolveForm(), priceList.form)
    if (priceList.form.items.length === 0) {
      errors.push(`You must provide at least one product`);
    }
    if(isNew && priceLists.list.find(item => item.name.toLowerCase() === priceList.form.name.toLowerCase())){
      errors.push(`A Price List with the name "${priceList.form.name}" already exists`);
    }
    if(isPortalType() && isEmpty(priceList.form.company)){
      errors.push(`You must select a company for type "Portal"`);
    }
    setErrors(errors);
    return isEmpty(errors);
  }

  const hasChanges = () => {
    const diff = detailedDiff(priceList.original, priceList.form);
    return !isEmpty(diff.added) || !isEmpty(diff.deleted) || !isEmpty(diff.updated)
  }

  const canActivate = () => {
    return isNew || (!isNew && !inDraftStatus()) || hasChanges()
  }

  const canDisable = () => {
    return !isNew && !inDisabledStatus() && (inDraftStatus() || (!inDraftStatus() && isPortalType()))
  }

  const getItems = useCallback(() => {
    return priceList.form.items.map(item => ({
      ...item,
      editable: isNew || inDraftStatus()
    }))
  }, [isNew, priceList.form.status, priceList.form.items])

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Row>
          <Col>
            <Card>
              <CardHeader className={'d-flex'}>
                <div>
                  <strong>{priceList.original.name}</strong>
                  <Badge className={priceList.original.name ? "ms-2" : ''} color={resolveStatusColour(priceList.form.status?.name || 'draft')}>{priceList.form.status?.name || 'draft'}</Badge>
                </div>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {hasChanges() && <UnsavedChangesAlert/>}
                  <ButtonIcon
                    icon={'fa fa-copy'}
                    onClick={clone}
                    colour={'secondary'}
                    disabled={isNew}
                    tooltip={'clone'}
                  />
                  <ButtonIcon
                    icon={'fa fa-trash'}
                    onClick={disable}
                    colour={'secondary'}
                    disabled={!canDisable()}
                    tooltip={inDraftStatus() ? 'delete' : 'disable'}
                  />

                  <ButtonIcon
                    icon={'fa fa-bullhorn'}
                    onClick={activate}
                    colour={'secondary'}
                    disabled={canActivate()}
                    tooltip={'activate'}
                  />
                  <ButtonIcon
                    icon={'fa fa-save'}
                    onClick={save}
                    colour={'secondary'}
                    disabled={!hasChanges()}
                    tooltip={'save'}
                  />

                  <ButtonIcon
                    icon={'fa fa-refresh'}
                    onClick={() => getData()}
                    colour={'secondary'}
                    disabled={isNew}
                    tooltip={'reload'}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <FormValidationErrors errors={errors}/>
                <Row>
                  <Col md={6} m={12}>
                    <Row>
                      {generateFormFields(
                        {
                          fields: resolveForm(),
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleAsyncSelected,
                          handleAsyncInput,
                          data: priceList.form,
                          optionSets
                        })
                      }
                    </Row>
                  </Col>
                  <Col>
                    {(isNew || inDraftStatus()) &&
                      <div className="d-flex flex-column justify-content-start p-3 bg-light rounded">
                        <CardTitleBold>Templates</CardTitleBold>
                        <InputGroup className={'mb-5'}>
                          <SelectMod
                            getOptionLabel={(opt) => opt.label || opt.name}
                            getOptionValue={(opt) => opt.value || opt.id}
                            options={getTemplateOptions()}
                            isClearable
                            isSearchable
                            placeholder="Select a template"
                            onChange={(selected) => {setSelectedTemplate(selected)}}
                          />
                          <Button disabled={!selectedTemplate} color='primary' onClick={() => addItem(selectedTemplate?.value)}>Add</Button>
                        </InputGroup>
                      </div>
                    }
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <CollapsibleCard open title={'Products'}>
              <PriceListItemsTable
                priceListItems={getItems() ?? []}
                editable={isNew || inDraftStatus()}
                editableKeyToFocus={editableKeyToFocus}
                handleInput={updateItemField}
                pricingOptions={pricingTypes.list}
                setDescriptionItem={setDescriptionItem}
                removeItem={removeItem}
                currency={priceList.form.currency}
              />
            </CollapsibleCard>
          </Col>
        </Row>
      </LoadingOverlay>
      <GenericModal
        show={!isEmpty(descriptionItem)}
        title={"Description"}
        className={"modal-lg"}
        toggle={() => setDescriptionItem({})}
      >
        <Input
          type={"textarea"}
          rows={10}
          value={priceList.form.items.find(item => descriptionItem.id === item.id)?.description || ''}
          onChange={(event) => updateItemField(descriptionItem.id, {description: event.target.value})}/>
      </GenericModal>
    </div>
  )
}

function mapStateToProps({
  priceList,
  priceLists,
  productTemplates,
  authenticationState,
  helpers,
  pricingTypes
}) {
  return {
    priceList,
    priceLists,
    user: authenticationState.account,
    optionSets: {...helpers.optionSets.priceList, currency: helpers.optionSets.currency.currency },
    pricingTypes,
    templates: productTemplates
  };
}

export default connect(mapStateToProps)(PriceList);

