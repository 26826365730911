import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loading-overlay';
import ReactTable from 'react-table-v6';
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  deleteCompareQueueFailedJob,
  deleteQueueFailedJob, getCompareQueueFailedJob, getCompareQueueFailedJobs, getCompareQueueSummary, getQueueFailedJob,
  getQueueFailedJobs,
  getQueueSummary, resetQueueSummary, retryCompareQueueFailedJob, retryQueueFailedJob, setQueue, toggleFailedJobModal,
  toggleQueueFailedJobsLoading,
  toggleQueueSummaryLoading
} from '../../../actions/queues';
import phpUnserialize from 'phpunserialize';
import JSONPretty from 'react-json-pretty';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FilteredTableV2 from '../../../components/FilteredTableV2';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const Queues = (props) => {
  const {dispatch, queueState} = props;
  const { queues, queue, loading, loadingFailedJobs, failedJobs, failedJob, failedJobsTable } = queueState;
  const [activeQueue, setActiveQueue] = useState('');
  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})


  useEffect(() => {
    getSummary();
  }, []);

  const isCompareQueue = (queue) => {
    return  /compare/.test(queue)
  }
  const columns = [
    { Header: 'Name', accessor: 'name', filterable: false, sortable: false },
    { Header: 'Size', accessor: 'size', filterable: false, sortable: true },
    { Header: 'Failed Jobs', accessor: 'failed', filterable: false, sortable: true }
  ];
  const failedJobColumns = [
    { header: 'Id', accessorKey: 'id', filterable: false, sortable: false, maxSize: 50},
    { header: 'Queue', accessorKey: 'queue', filterable: false, sortable: false, maxSize: 10 },
    { header: 'Connection', accessorKey: 'connection', sortable: false, maxSize: 150},
    { header: 'Failed At', accessorKey: 'failedAt', maxSize: 20 },
    { header: 'Exception', accessorKey: 'exception' },
    { header: '',
      accessorKey: 'unknown',
      maxSize: 200,
      cell: props => props.row?.original
        ? <div className={'d-flex justify-content-center'}>
            <div className="text-danger me-3" style={{ cursor: 'pointer' }} onClick={() => deleteJob(props.row.original.id, props.row.original.queue)}><i className="fa fa-trash-o"></i></div>
            <div className="text-primary me-3" style={{ cursor: 'pointer' }} onClick={() => retryJob(props.row.original.id, props.row.original.queue)}><i className="fa fa-refresh"></i></div>
            <div className="text-success" style={{ cursor: 'pointer' }} onClick={() => viewJob(props.row.original.id, props.row.original.queue)}><i className="fa fa-eye"></i></div>
          </div>
        : '' }
  ];

  const getSummary = () => {
    let promises = [];
    dispatch(resetQueueSummary())
    dispatch(toggleQueueSummaryLoading())
    promises.push(dispatch(getQueueSummary()))
    promises.push(dispatch(getCompareQueueSummary()))
    Promise.all(promises).then(() => dispatch(toggleQueueSummaryLoading()))

  };
  const deleteJob = (id, queue) => {
    dispatch(toggleQueueFailedJobsLoading());
    isCompareQueue(queue) ?
      dispatch(deleteCompareQueueFailedJob(id)).then(() => dispatch(toggleQueueFailedJobsLoading())) :
      dispatch(deleteQueueFailedJob(id)).then(() => dispatch(toggleQueueFailedJobsLoading()));

  };
  const retryJob = (id, queue) => {
    dispatch(toggleQueueFailedJobsLoading());
    isCompareQueue(queue) ?
      dispatch(retryCompareQueueFailedJob(id)).then(() => dispatch(toggleQueueFailedJobsLoading())) :
      dispatch(retryQueueFailedJob(id)).then(() => dispatch(toggleQueueFailedJobsLoading()));
  };

  const viewJob = (id, queue) => {
    dispatch(toggleQueueFailedJobsLoading());
    isCompareQueue(queue) ?
      dispatch(getCompareQueueFailedJob(id)).then(() => dispatch(toggleQueueFailedJobsLoading())) :
      dispatch(getQueueFailedJob(id)).then(() => dispatch(toggleQueueFailedJobsLoading()));
  };
  const getFailedJobs = (queue, page, size, resetPagination) => {
    setActiveQueue(queue);
    if(resetPagination){
      setPagination({pageIndex : 0, pageSize: 50})
    }

    dispatch(setQueue(queue));
    dispatch(toggleQueueFailedJobsLoading());
    isCompareQueue(queue) ?
    dispatch(getCompareQueueFailedJobs(queue, page, size)).then(() => dispatch(toggleQueueFailedJobsLoading())) :
    dispatch(getQueueFailedJobs(queue, page, size)).then(() => dispatch(toggleQueueFailedJobsLoading()));
  };
  const toggleFailedJob = () => {
    dispatch(toggleFailedJobModal());
  };
  return (
            <div className="mainCard animated fadeIn">
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="d-flex justify-content-between">
                                        <CardTitleBold className={'mb-0'}>Queues</CardTitleBold>
                                        <div className="h4 text-primary mb-0" style={{ cursor: 'pointer' }} onClick={getSummary} ><i className="icon-refresh"></i></div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Loadable active={loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                                <ReactTable
                                    getTheadProps={() =>{
                                      return {
                                        style: { border: 'none' }
                                      };
                                    }}
                                    getTheadTrProps={() =>{
                                      return {
                                        style: { border: 'none' }
                                      };
                                    }}
                                    getTheadThProps={() =>{
                                      return {
                                        style: { border: 'none' }
                                      };
                                    }}
                                    getTrProps={(state, rowInfo, column) =>{
                                      return {
                                        style: { cursor: rowInfo ? 'pointer' : 'auto' },
                                        onClick: (event) => {
                                          return rowInfo.original.name ? getFailedJobs(rowInfo.original.name, 1, 50, true) : () =>{};
                                        }
                                      };
                                    }}
                                    className="animated fadeIn -highlight"
                                    data={queues}
                                    defaultPageSize={10}
                                    minRows={4}
                                    columns={columns}
                                    filterable={false}
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                                    defaultSorted={[{ id: 'size', desc: true }, { id: 'failed', desc: true }]}
                                    showPagination={queues.length > 20}
                                    multiSort={true}
                                />
                                </Loadable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
              {activeQueue &&
                <>
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col className="d-flex justify-content-between">
                              <CardTitleBold className={'mb-0'}>Failed Jobs {activeQueue ? ` - ${activeQueue}` : ''}</CardTitleBold>
                              <div className="h4 text-primary mb-0 ms-2" style={{ cursor: 'pointer' }} onClick={() => getFailedJobs(queue, failedJobsTable.currentPage, failedJobsTable.perPage)} ><i className="icon-refresh"></i></div>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <LoadingOverlay loading={loadingFailedJobs}>
                            <FilteredTableV2
                              hideFilter={true}
                              minRows={10}
                              data={failedJobs}
                              columns={failedJobColumns}
                              onFetchData={(page, size) => {
                                if (activeQueue) {
                                  getFailedJobs(activeQueue, page, size);
                                }
                              }}
                              defaultSorted={[{ id: 'id', desc: false }]}
                              pages={failedJobsTable.lastPage}
                              pageSize={failedJobsTable.perPage}
                              onPaginationChange={setPagination}
                              pagination={pagination}
                              showPagination
                              defaultPageSize={50}
                            />
                          </LoadingOverlay>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Modal isOpen={failedJob !== null} toggle={toggleFailedJob} size={'xlg'} static>
                    <ModalHeader toggle={toggleFailedJob}>
                      {failedJob ? failedJob.id : ''}
                    </ModalHeader>
                    <ModalBody>
                      {failedJob
                        && <>
                          <Row>
                            <Col>
                              <CardTitleBold>
                                Exception
                              </CardTitleBold>
                              <div>
                                        <pre>
                                            {failedJob.exception}
                                        </pre>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardTitleBold>
                                Payload
                              </CardTitleBold>
                              <div>
                                            <pre>
                                                <JSONPretty id="json-pretty" data={phpUnserialize(failedJob.payload)} ></JSONPretty>
                                            </pre>
                              </div>
                            </Col>
                          </Row>
                        </>

                      }
                    </ModalBody>
                  </Modal>
                </>
              }
            </div>

  );
};

const mapStateToProps = ({ queues }) => {
  return {
    queueState: queues
  };
};
export default connect(mapStateToProps)(Queues);
