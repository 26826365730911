import React, {useState } from 'react';
import {
  Button,
  Col,
  Row,

  Table,
  Alert,
  Input
} from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import QuoteProducts from './QuoteProducts';
import { SelectMod } from '../../components/Selects/SelectMod';
import { serviceLocationTemplate } from './index';
import { Link } from 'react-router-dom';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import CardTitleBold from '../../components/Cards/CardTitleBold'

const OrderFormWizard = ({
  quote,
  serviceOrderChangeHandler,
  serviceInputHandler,
  handleOrderFormInput,
  addServiceLocation,
  removeServiceLocation,
  handleServiceLocationInput,
  generateOrderForm,
  contacts,
  priceList
}) => {
  const [currentStep, setCurrentStep] = useState(quote.form.customer.companyRegNum ? 1 : 0);
  const [errors, setErrors] = useState([]);
  const [locationSite, setLocationSite] = useState(null);
  const [locationContact, setLocationContact] = useState(null);
  const getSiteOptions = () => {
    return quote.sites.map((site) => {
      return {
        label: site.name,
        value: site.accountnumber,
        isDisabled: !site.isActive
      };
    });
  };
  const getContactOptions = () => {
    return contacts.filter(contact => contact.isActive).map((contact) => {
      return {
        label: `${contact.name} (${contact.email})`,
        value: contact.id
      };
    });
  };
  const getSelectedSiteOption = (id) => {
    let selected = quote.sites.find((site) => {
      return site.accountnumber === id;
    });
    if (selected) {
      return {
        label: selected.name,
        value: selected.id
      };
    }
    return null;
  };
  const getSelectedContactOption = (id) => {
    let selected = contacts.filter((contact) => {
      return contact.id === id;
    });
    if (selected.length) {
      return {
        label: selected[0].name,
        value: selected[0].id
      };
    }
    return null;
  };

  const getAddress = (id, withName = false) => {
    const siteFound = quote.sites.find(site => site.accountnumber === id);
    return `${withName ? `${siteFound.name}, ` : ''}${siteFound.fullAddress}`;
  };
  const getContact = (id) => {
    const contact = contacts.find(contact => contact.id === id);
    const result = [];
    if (contact) {
      ['name', 'email', 'phone'].forEach((field, index) => {
        if(contact[field]){
          result.push(contact[field]);
        }
      })
    }
    return result.join(', ');
  };

  const advance = (step) => {
    setErrors([]);
    if (validateStep(step - 1)) {
      if (step === 4 && !quote.orderForm.serviceLocations.find(location => location.siteId === quote.orderForm.supplySite)) {
        addServiceLocation({
          ...serviceLocationTemplate,
          siteId: quote.orderForm.supplySite,
          contactId: quote.orderForm.supplyContact
        });
      }
      setCurrentStep(step);
    }
  };
  const validateStep = (step) => {
    const errors = [];
    if (step === 0 && !quote.form.customer.companyRegNum && !quote.orderForm.companyRegSub) {
      errors.push('You must set a replacement text!');
    }
    if (step === 3) {
      if (!quote.orderForm.supplySite || !quote.orderForm.supplyContact) {
        errors.push('Must set primary site and contact!');
      } else {
        let supplyContact = contacts.find((contact) => contact.id === quote.orderForm.supplyContact);
        if (!supplyContact.email && !supplyContact.phone) {
          errors.push('Contacts must have a phone or email address!');
        }
      }
      let billingContact = contacts.find((contact) => contact.id === quote.orderForm.billingContact);
      if(billingContact && !billingContact.email && !billingContact.phone){
        errors.push('Contacts must have a phone or email address!');
      }

    }
    if(step === 4 && quote.orderForm.serviceLocations.length === 0){
      errors.push('Must set at least one location!');
    }
    if (errors.length) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const setLocation = (selected) => {
    setLocationSite(selected);
  };
  const setContact = (selected) => {
    setLocationContact(selected);
  };
  const addNewLocation = () => {
    const errors = [];
    if (quote.orderForm.serviceLocations.find(location => location.siteId === locationSite.value)) {
      errors.push(`The site ${locationSite.label} has already been added.`);
    } else {
      addServiceLocation({
        ...serviceLocationTemplate,
        siteId: locationSite.value,
        contactId: locationContact ? locationContact.value : null
      });
    }
    setErrors(errors);
  };
  return (
    <LoadingOverlay loading={quote.orderForm.loading}>
      <FormValidationErrors errors={errors}/>
      <Row>
        <Col>
          {currentStep === 0 &&
          <div>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center'>
                <p className='h3 text-danger'>Missing Company Registration Number</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>There is no company registration number on the account. Please either:</p>
                <ul>
                  <li>Go back to the
                    <Link to={`/accounts/${quote.form.customer ? quote.form.customer.accountid : ''}`}>
                      <span className='text-info'> account </span>
                    </Link>
                    and update the registration number.
                  </li>
                  <li>Enter below a new text to replace the company registration section.</li>
                </ul>
                <Input
                  type='textarea'
                  rows={3}
                  value={quote.orderForm.companyRegSub}
                  placeholder='[  a company registration no.  ]'
                  onChange={(event) => handleOrderFormInput({ companyRegSub: event.target.value })}
                />
              </Col>
            </Row>
            <Row className={'mt-3'}>
              <Col className='d-flex justify-content-end'>
                <Button color='primary' onClick={() => advance(1)}>Next </Button>
              </Col>
            </Row>
          </div>

          }
          {currentStep === 1 &&
          <div>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center'>
                <p className='h3 text-info'>Network Services</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <QuoteProducts
                  noDataText={'This Quote has no Network Services'}
                  products={quote.orderForm.networkServices}
                  onOrderChange={(current, next) => serviceOrderChangeHandler('network', current, next)}
                  currency={priceList.currency.id}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='d-flex justify-content-end'>
                <Button color={'primary'} onClick={() => advance(2)}>Next</Button>
              </Col>
            </Row>
          </div>
          }
          {currentStep === 2 &&
          <div>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center'>
                <p className='h3 text-info'>Other Services</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <QuoteProducts
                  noDataText={'This Quote has no Other Services'}
                  products={quote.orderForm.otherServices}
                  inputHandler={serviceInputHandler}
                  onOrderChange={(current, next) => serviceOrderChangeHandler('other', current, next)}
                  currency={priceList.currency.id}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='d-flex justify-content-between'>
                <Button color={'primary'} onClick={() => setCurrentStep(1)}>Back</Button>
                <Button color={'primary'} onClick={() => advance(3)}>Next</Button>
              </Col>
            </Row>
          </div>
          }
          {currentStep === 3 &&
          <div>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center'>
                <p className='h3 text-info'>Addresses</p>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <CardTitleBold>Primary Premises (Service Supply Address)</CardTitleBold>
                <Row>
                  <Col md={6}>
                    <SelectMod
                      options={getSiteOptions()}
                      isSearchable
                      placeholder='Select site...'
                      value={getSelectedSiteOption(quote.orderForm.supplySite)}
                      onChange={(selected) => handleOrderFormInput({ supplySite: selected.value })}
                    />
                    {quote.orderForm.supplySite ?
                      <div className='mt-2'>
                        <p>{getAddress(quote.orderForm.supplySite)}</p>
                      </div>
                      :
                      ''
                    }
                  </Col>
                  <Col md={6}>
                    <SelectMod
                      options={getContactOptions()}
                      isSearchable
                      placeholder='Select contact...'
                      value={getSelectedContactOption(quote.orderForm.supplyContact)}
                      onChange={(selected) => handleOrderFormInput({ supplyContact: selected.value })}

                    />
                    {quote.orderForm.supplyContact ?
                      <div className='mt-2'>
                        <p>{(getContact(quote.orderForm.supplyContact))}</p>
                      </div>
                      :
                      ''
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <CardTitleBold>Billing Address (if different)</CardTitleBold>
                <Row>
                  <Col md={6}>
                    <SelectMod
                      options={getSiteOptions()}
                      isSearchable
                      isClearable
                      placeholder='Select site...'
                      value={getSelectedSiteOption(quote.orderForm.billingSite)}
                      onChange={(selected) => handleOrderFormInput({ billingSite: selected ? selected.value : null })}
                    />
                    {quote.orderForm.billingSite ?
                      <div className='mt-2'>
                        <p>{getAddress(quote.orderForm.billingSite)}</p>
                      </div>
                      :
                      ''
                    }
                  </Col>
                  <Col md={6}>
                    <SelectMod
                      options={getContactOptions()}
                      isSearchable
                      isClearable
                      placeholder='Select contact...'
                      value={getSelectedContactOption(quote.orderForm.billingContact)}
                      onChange={(selected) => handleOrderFormInput({ billingContact: selected ? selected.value : null })}
                    />
                    {quote.orderForm.billingContact ?
                      <div className='mt-2'>
                        <p>{(getContact(quote.orderForm.billingContact))}</p>
                      </div>
                      :
                      ''
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='d-flex justify-content-between'>
                <Button color={'primary'} onClick={() => setCurrentStep(2)}>Back</Button>
                <Button color={'primary'} onClick={() => advance(4)}>Next</Button>
              </Col>
            </Row>
          </div>
          }
          {currentStep === 4 &&
          <div>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center'>
                <p className='h3 text-info'>Service Locations and Onsite Contacts</p>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={5}>
                <SelectMod
                  options={getSiteOptions()}
                  isSearchable
                  isClearable
                  placeholder='Select site...'
                  value={locationSite}
                  onChange={setLocation}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={5}>
                <SelectMod
                  options={getContactOptions()}
                  isSearchable
                  isClearable
                  placeholder='Select contact...'
                  value={locationContact}
                  onChange={setContact}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col>
                <Button
                  onClick={addNewLocation}
                  color='primary'
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Table striped bordered>
                  <thead>
                  <tr>
                    <th>Site</th>
                    <th>Address</th>
                    <th>Floor</th>
                    <th>Room</th>
                    <th>Rack Number</th>
                    <th>Building Owner</th>
                    <th>Onsite Contact</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {quote.orderForm.serviceLocations.map((location, index) => {
                    return <tr key={location.siteId + index}>
                      <td scope='row'>{index + 1}</td>
                      <td>{getAddress(location.siteId, true)}</td>
                      <td>
                        <Input
                          type='text'
                          value={location.floor}
                          onChange={(e) => handleServiceLocationInput(location.siteId, { floor: e.target.value })}
                        />
                      </td>
                      <td>
                        <Input
                          type='text'
                          value={location.room}
                          onChange={(e) => handleServiceLocationInput(location.siteId, { room: e.target.value })}
                        />
                      </td>
                      <td>
                        <Input
                          type='text'
                          value={location.rackNumber}
                          onChange={(e) => handleServiceLocationInput(location.siteId, { rackNumber: e.target.value })}
                        />
                      </td>
                      <td className='text-center'>
                        <Input
                          type='checkbox'
                          checked={location.buildingOwner}
                          onChange={(e) => handleServiceLocationInput(location.siteId, { buildingOwner: e.target.checked })}
                        />
                      </td>
                      <td>{getContact(location.contactId)}</td>
                      <td className='text-center'>
                        <div
                          onClick={() => removeServiceLocation(location.siteId)}
                          style={{ cursor: 'pointer' }}
                          className={'text-danger'}>
                          <i className='fa fa-close'/>
                        </div>
                      </td>
                    </tr>;
                  })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='d-flex justify-content-between'>
                <Button color={'primary'} onClick={() => setCurrentStep(3)}>Back</Button>
                <Button color={'primary'} onClick={() => advance(5)}>Next</Button>
              </Col>
            </Row>
          </div>

          }
          {currentStep === 5 &&
          <div>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center'>
                <p className='h3 text-info'>Comments</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type='textarea'
                  rows={20}
                  value={quote.orderForm.comments}
                  onChange={(e) => handleOrderFormInput({ comments: e.target.value })}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='d-flex justify-content-between'>
                <Button color={'primary'} onClick={() => setCurrentStep(4)}>Back</Button>
                <Button color={'primary'} onClick={generateOrderForm}>Generate Form</Button>
              </Col>
            </Row>
          </div>
          }
        </Col>
      </Row>
    </LoadingOverlay>

  );
};

export default OrderFormWizard;
