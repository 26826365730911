import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import LoadingOverlay from '../../../components/LoadingOverlay';
import {
  createVendor,
  getVendor,
  resetVendor,
  setVendorField,
  updateVendor,
  deleteVendor, addVendorNote, updateVendorNote, removeVendorNote, getVendorAuditHistory
} from '../../../actions/vendor';
import form from './form';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { isSdbManager } from '../../../utils/Auth/AuthService';
import { Card, CardBody, Col, Row } from 'reactstrap';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import isEmpty from 'lodash.isempty';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import { setConfirmDialog } from '../../../actions/dialogs';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CollapsibleCard from '../../../components/CollapsibleCard';
import Notes from '../../../components/Notes';
import DeviceModelsTable from '../../../components/Tables/DeviceModels';
import { formValidator } from '../../../helpers/FormValidator';
import resolveArgs from '../../../helpers/ArgumentResolver';
import { diff } from 'deep-object-diff';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import AuditHistory from '../../../components/AuditHistory';
import queryString from 'query-string';
import { useNavigate, useParams } from 'react-router-dom'
import {addVendorToVendors, updateVendorToVendors} from "../../../actions/vendors";
import { NavigationBlocker } from '../../../components/NavigationBlocker';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const includes = ['deviceModels', 'notes', 'notes.createdBy']
const Vendor = ({
  audits,
  id = null,
  data,
  original
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams()

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const vendorId = useMemo(() => (id || params.vendorId), [id, params.vendorId])
  const isNew = useMemo(() => vendorId === 'new' && !original.id, [vendorId, original.id])

  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    if(!isNew){
      fetchData()
    }
    return () => dispatch(resetVendor())
  }, [])

  useEffect(() => {
    if (original.name || isNew) {
      dispatch(addBreadcrumbs([{ name: original.name ?? 'New' }]))
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.name]);

  const fetchData = () => {
    toggleLoading()
    dispatch(getVendor(vendorId, ['deviceModels', 'notes'])).then(() => toggleLoading())
  }

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getVendorAuditHistory(vendorId)).then(() => toggleAuditsLoading())
  }
  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createVendor(resolveArgs(data), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          }else if(result){
            dispatch(addVendorToVendors(result))
            navigate(`/sdb/vendors/${result.id}`)
            toggleLoading()
          }
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updateVendor(vendorId, resolveArgs(toSave), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          } else if(result){
            dispatch(updateVendorToVendors(result))
          }
          toggleLoading()
        })
      }
    }
  }

  const validated = () => {
    let errorArr = formValidator(form, data);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this vendor!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteVendor(vendorId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/vendors')
          }
        })
      }
    }))
  }
  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const canEdit = () =>  isSdbManager()

  const canDelete = () => canEdit() && original.deviceModelCount === 0

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-3'>
          <CardBody>
            <Row className='mb-2'>
              <Col className="d-flex justify-content-between">
                <CardTitleBold>
                  {original.name || 'New Vendor'}
                </CardTitleBold>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {!isNew && <ButtonIcon
                    disabled={!canDelete()}
                    icon={'fa fa-trash'}
                    tooltip={!canDelete() ? 'Vendor has existing models' : !canEdit() ?  'You have no permissions to delete this item!' : 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canEdit() || loading || !hasChanges()}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6"}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={form}
                    setField={(field, value) => dispatch(setVendorField(field, value))}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Device Models'}
                      open
                    >
                      <DeviceModelsTable
                        excludeColumns={'Vendor'}
                        deviceModels={data.deviceModels}
                        fetchData={fetchData}
                        vendor={original}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{type: 'vendor', data}}
                        onCreated={note => dispatch(addVendorNote(note))}
                        onUpdated={note => dispatch(updateVendorNote(note))}
                        onDeleted={note => dispatch(removeVendorNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>

            }

          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  );
};

function mapStateToProps({ vendor, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    data: vendor.data,
    original: vendor.original,
    audits: vendor.audits,
  };
}

export default connect(mapStateToProps)(Vendor);
