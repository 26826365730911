import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import LoadingOverlay from '../../components/LoadingOverlay';
import { searchIndex } from '../../actions/globalSearch';
import isEmpty from 'lodash.isempty';
import {

  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
  Col
} from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom'
import CardTitleBold from '../../components/Cards/CardTitleBold'


const GlobalSearch = ({ globalSearch }) => {

  // router
  const navigate = useNavigate();
  const location = useLocation();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [circuits, setCircuits] = useState([]);
  const [carrierCircuits, setCarrierCircuits] = useState([]);
  const [crossConnects, setCrossConnects] = useState([]);
  const [connections, setConnections] = useState([]);


  useEffect(() => {
    search()
  }, [location.key])

  useEffect(() => {
    setAccounts(getEntity('account'))
    setContacts(getEntity('contact'))
    setOpportunities(getEntity('opportunity'))
    setCircuits(getEntity('circuit'))
    setOrders(getEntity('order'))
    setCarrierCircuits(getEntity('carrierCircuit'))
    setCrossConnects(getEntity('crossConnect'))
    setConnections(getEntity('connection'))
  }, [globalSearch.list])

  const getEntity = (type) => {
    return globalSearch.list.filter(item => item.type === type)
  }
  const search = () =>{
    if(globalSearch.pattern){
      setLoading(true)
      dispatch(searchIndex(globalSearch.pattern)).then(() => setLoading(false))
    }
  }

  const resolveUrl = (e, document) => {
    e.preventDefault();
    let url = ''
    switch (document.type) {
      case 'account':
        url = `/accounts/${document.id}`
        break
      case 'contact':
        url = `/accounts/${document.related.id}/contacts?showContact=${document.id}`
        break
      case 'opportunity':
        url = `/sales/opportunities/${document.id}`
        break
      case 'order':
        url = `/sales/orders/${document.id}`
        break
      case 'circuit':
        url = `/circuits/${document.id}`
        break
      case 'carrierCircuit':
        url = `/sdb/carrier-circuits/${document.id}`
        break
      case 'crossConnect':
        url = `/sdb/cross-connects/${document.id}`
        break
      case 'connection':
        url = `/sdb/connections/${document.id}`
        break
    }

    navigate(url);

  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Row>
          {!isEmpty(accounts) &&
          <Col md={3} className={'h-75'}>
              <CardTitleBold>Accounts</CardTitleBold>
              <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
                {accounts.map(account => {
                  return (
                    <ListGroupItem key={account.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, account)}>
                      <ListGroupItemHeading>{account.name}</ListGroupItemHeading>
                      <ListGroupItemText className="mb-0">{account.related.accountNumber || '---'}</ListGroupItemText>
                      <ListGroupItemText className="mb-0">{account.related?.phone || '---'}</ListGroupItemText>
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </Col>
          }
          {!isEmpty(contacts) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Contacts</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {contacts.map(contact =>  {
                return (
                  <ListGroupItem key={contact.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, contact)}>
                    <ListGroupItemHeading>{contact.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{contact.related?.name || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">{contact.related?.email || '---'}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
          {!isEmpty(orders) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Orders</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {orders.map(order =>  {
                return (
                  <ListGroupItem key={order.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, order)}>
                    <ListGroupItemHeading>{order.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{order.related.name || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">{'---'}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
          {!isEmpty(opportunities) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Opportunities</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {opportunities.map(opportunity =>  {
                return (
                  <ListGroupItem key={opportunity.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, opportunity)}>
                    <ListGroupItemHeading>{opportunity.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{opportunity.related.name || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">{opportunity.related.opportunityType || '---'}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
          {!isEmpty(circuits) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Circuits</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {circuits.map(circuit =>  {
                return (
                  <ListGroupItem key={circuit.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, circuit)}>
                    <ListGroupItemHeading>{circuit.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{circuit.related.reference || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">{circuit.related.account || '---'}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
          {!isEmpty(connections) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Connections</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {connections.map(connection =>  {
                return (
                  <ListGroupItem key={connection.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, connection)}>
                    <ListGroupItemHeading>{connection.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">A-End: {connection.related.aEnd || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">B-End: {connection.related.bEnd || (connection.related.aEnd ? 'Wires Only' : '')}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
          {!isEmpty(carrierCircuits) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Carrier Circuits</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {carrierCircuits.map(carrierCircuit =>  {
                return (
                  <ListGroupItem key={carrierCircuit.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, carrierCircuit)}>
                    <ListGroupItemHeading>{carrierCircuit.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{carrierCircuit.related.reference || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">{carrierCircuit.related.supplier || '---'}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
          {!isEmpty(crossConnects) &&
          <Col md={3} className={'h-75'}>
            <CardTitleBold>Cross Connects</CardTitleBold>
            <ListGroup style={{maxHeight: '900px'}} className="overflow-auto">
              {crossConnects.map(crossConnect =>  {
                return (
                  <ListGroupItem key={crossConnect.id} tag="a" href="#" action onClick={(e) => resolveUrl(e, crossConnect)}>
                    <ListGroupItemHeading>{crossConnect.name}</ListGroupItemHeading>
                    <ListGroupItemText className="mb-0">{crossConnect.related.reference || '---'}</ListGroupItemText>
                    <ListGroupItemText className="mb-0">{crossConnect.related.supplier || '---'}</ListGroupItemText>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </Col>
          }
        </Row>
      </LoadingOverlay>
    </div>
  )

}




function mapStateToProps({
  globalSearch
}) {
  return {
    globalSearch
  };
}

export default connect(mapStateToProps)(GlobalSearch);
