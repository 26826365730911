import { Button, Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { ReportService } from '../../../utils/Reports';
import fileDownload from 'js-file-download';
import { defaultErrorFeedback } from '../../../actions/feedback';
import { connect } from 'react-redux';
import { useState } from 'react';
import LoadingOverlay from '../../../components/LoadingOverlay';
import CardTitleBold from '../../../components/Cards/CardTitleBold'
const SalesReports = ({
  dispatch
}) => {

  const [loading, setLoading] = useState({})
  const toggleLoading = (name) => setLoading(prevState => {
    return {
      ...prevState,
      [name]: !prevState[name]
    }
  })
  const getLoading = name => {
    return loading[name] ?? false
  }
  const getReport = (name) =>{
    toggleLoading(name)
    ReportService.generate({template: name, format: 'csv'}).then(result =>{
      if (result.status === 200) {
        const splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1].replaceAll('"', ''), result.headers['content-type']);
      } else {
        dispatch(defaultErrorFeedback());
      }
      toggleLoading(name)
    })
  }
  return (
    <div className="animated fadeIn">
      <Row>
        <Col md={3}>
          <LoadingOverlay loading={getLoading('RecurringRevenue')}>
            <Card body>
              <CardTitleBold>Recurring Revenue Report</CardTitleBold>
              <CardText>A breakdown of Recurring Revenue by Product Line Items</CardText>
              <Button color={'primary'} onClick={() => getReport('RecurringRevenue')}>Generate</Button>
            </Card>
          </LoadingOverlay>

        </Col>
        <Col md={3}>
          <LoadingOverlay loading={getLoading('ExtendedSales')}>
            <Card body>
              <CardTitleBold>Extended Sales Pipeline</CardTitleBold>
              <CardText>A breakdown of Open Opportunities by Quote Products</CardText>
              <Button color={'primary'} onClick={() => getReport('ExtendedSales')}>Generate</Button>
            </Card>
          </LoadingOverlay>

        </Col>
      </Row>
    </div>
  )
}

export default connect()(SalesReports);
