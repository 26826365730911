import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import {
  Card,
  CardBody,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import FilteredTable from '../../components/FilteredTable';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import moment from 'moment';
import { checkPermission, isSalesManager } from '../../utils/Auth/AuthService';
import {
  getPricingToolQuotes, getPricingToolUsers,
  setPricingToolQuotes,
  setPricingToolQuoteSearchString,
  setPricingToolQuoteUserFilter
} from '../../actions/pricingTool';
import { SelectMod } from '../../components/Selects/SelectMod';
import numeral from 'numeral';
import { getPricingToolQuote } from '../../actions/pricingToolQuote';
import QuoteRequest from '../PricingToolQuote/QuoteRequest';
import { useNavigate } from 'react-router-dom'

const PricingTool = ({
  pricingTool,
  user
}) => {

  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const [quoteSearchValue, setQuoteSearchValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showNewQuote, setShowNewQuote] = useState(false);
  const [redirectToQuote, setRedirectToQuote] = useState(null);
  const userFilter = useRef({
    value: 'all',
    label: 'All Owners'
  });
  const getData = (postcode, page = 1, size = 50, owner = 'all') => {
    return dispatch(getPricingToolQuotes(postcode, page, size, owner));
  };

  useEffect(() => {
    userFilter.current = { ...pricingTool.userFilter };
    if (!pricingTool.users.length) {
      setLoadingQuotes(true);
      dispatch(getPricingToolUsers())
        .then((result) => {
          if (result) {
            getData('', 1, pricingTool.table.perPage, pricingTool.userFilter.value)
              .then(() => {
                setLoadingQuotes(false);
              });
          }

        });
    }
  }, []);

  useEffect(() => {
    if (pricingTool.userFilter.value && userFilter.current.value !== pricingTool.userFilter.value) {
      setLoadingQuotes(true);
      getData('', 1, pricingTool.table.perPage, pricingTool.userFilter.value)
        .then(() => {
          setLoadingQuotes(false);
        });
    }
    userFilter.current = { ...pricingTool.userFilter };
  }, [pricingTool.userFilter]);

  useEffect(() => {
    if (redirectToQuote) {
      navigate(`/sales/pricing-tool/${redirectToQuote.uuid}`)
    }
  }, [redirectToQuote])

  const findQuote = (id) => {
    setLoadingQuotes(true);
    dispatch(getPricingToolQuote(id))
      .then((result) => {
        if (result) {
          dispatch(setPricingToolQuotes([result], { lastPage: 1 }));
        }
        setLoadingQuotes(false);
      });
  };
  const getByPostcode = () => {
    setLoadingQuotes(true);
    getData(quoteSearchValue)
      .then(() => {
        setLoadingQuotes(false);
      });

  };
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const isFiltered = () => {
    return Boolean(pricingTool.searchString);
  };
  const onChangeSearch = (e) => {
    dispatch(setPricingToolQuoteSearchString(e.target.value));
  };

  const onUserFilterChange = (selected) => {
    dispatch(setPricingToolQuoteUserFilter(selected));
  };

  const handleRowClick = (quote) => {
    setRedirectToQuote(quote);
  };

  const getOwnersOptions = () => {
    const owners = [{
      value: 'all',
      label: 'All Owners'
    }];
    return pricingTool.users.reduce((result, user) => {
      result.push({
        value: user,
        label: user
      });
      return result;
    }, owners);
  };
  const getExtras = () => {
    const result = [];
    result.push(
      <div className={'me-2'}>
        <InputGroup>
          <Input bsSize={'sm'} onChange={(event) => setQuoteSearchValue(event.target.value)}/>
          <Dropdown size={'sm'} addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle caret color={'info'} size={'sm'}>
              Search By
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => {findQuote(quoteSearchValue);}}>Quote Id</DropdownItem>
              <DropdownItem onClick={getByPostcode}>Postcode</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </InputGroup>
      </div>
    );
    if (isSalesManager(user.permissions)) {
      result.push(
        <div style={{ width: '200px' }}>
          <SelectMod
            options={getOwnersOptions()}
            isSearchable
            value={pricingTool.userFilter}
            onChange={onUserFilterChange}
            size={'sm'}
          />
        </div>
      );
    }

    return result;
  };
  let columns = [
    {
      filterable: false,
      Header: 'Id',
      accessor: 'id',
      width: 100
    },
    {
      filterable: false,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: false,
      Header: 'TCV',
      accessor: 'tcv',
      Cell: (props) => {
        return `£${numeral(props.value)
          .format('0,0.00')}`;
      }
    },
    {
      filterable: false,
      Header: 'Status',
      accessor: 'status'
    },
    {
      filterable: false,
      Header: 'Created By',
      accessor: 'created_by'
    },
    {
      filterable: false,
      Header: 'Assigned To',
      accessor: 'assigned_to'
    },
    {
      filterable: false,
      Header: 'Date',
      accessor: 'times.created',
      Cell: (props) => {
        return moment(props.value)
          .format('DD/MM/YYYY HH:mm');
      }
    }
  ];

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loadingQuotes}>
            <FilteredTable
              filterable={false}
              extraButtons={ExtraButtons({
                addNew: () => setShowNewQuote(true),
                refresh: () => {
                  setLoadingQuotes(true);
                  getData('', pricingTool.table.currentPage, pricingTool.table.perPage, pricingTool.userFilter.value)
                    .then(() => {
                      setLoadingQuotes(false);
                    });
                },
                others: getExtras()
              })}
              onChange={onChangeSearch}
              value={pricingTool.searchString}
              columns={columns}
              data={isFiltered() ? pricingTool.filteredList : pricingTool.list}
              defaultSorted={{
                id: 'modifiedOn',
                desc: true
              }}
              onRowClick={handleRowClick}
              showPagination={pricingTool.table.lastPage > 1}
              pages={pricingTool.table.lastPage}
              pageSizeOptions={[50, 100, 200, 500]}
              defaultPageSize={50}
              onFetchData={(page, size) => {
                if (page !== pricingTool.table.currentPage || pricingTool.table.perPage !== size) {
                  setLoadingQuotes(true);
                  getData(quoteSearchValue, page, size, pricingTool.userFilter.value)
                    .then(() => {
                      setLoadingQuotes(false);
                    });
                }
              }}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>

      <Modal
        isOpen={showNewQuote}
        toggle={() => setShowNewQuote(!showNewQuote)}
        backdrop={'static'}
        keyboard={false}
        size="xlg"
      >
        <ModalHeader toggle={() => setShowNewQuote(!showNewQuote)}>Pricing Tool Quote</ModalHeader>
        <ModalBody>
          <QuoteRequest/>
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps({
  pricingTool,
  authenticationState,
  helpers
}) {
  return {
    pricingTool,
    user: authenticationState.account,
    users: helpers.systemusers
  };
}

export default connect(mapStateToProps)(PricingTool);
