import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,

  Row,
  Col,
  FormGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import { connect } from 'react-redux';
import Loadable from 'react-loading-overlay';
import { AsyncSelectMod } from '../../components/Selects/AsyncSelectMod';
import BillingQueue from './BillingQueue';
import BillingRunsTable from './BillingRunsTable';
import { getBillingQueueStatus, toggleBillingQueueLoading } from '../../actions/billingQueue';
import { searchActiveBillingCustomers, setAccountToRun, startBillingRun } from '../../actions/billing';
import { getBillingRuns, toggleBillingRunsLoading } from '../../actions/billingRuns';
import CardTitleBold from '../../components/Cards/CardTitleBold'
Moment.locale('en');
momentLocalizer();

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBillingRuns: [],
      redirectToRun: null,
      timerId: null
    };
  }

  componentDidMount() {
    this.getRuns();
    this.getQueueStatus();
  }

    getRuns = () => {
      this.props.dispatch(toggleBillingRunsLoading());
      this.props.dispatch(getBillingRuns()).then(() => this.props.dispatch(toggleBillingRunsLoading()));
    }

    componentWillUnmount() {
      clearInterval(this.state.timerId);
    }

    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    getActiveCustomers = (searchString) =>{
      if (!searchString || searchString.length <= 2) {
        return [];
      }
      return this.props.dispatch(searchActiveBillingCustomers(searchString)).then(
        () => {
          return this.props.billing.searchOptions;
        }
      );
    }

    setAccount = (selectedOption) => {
      this.props.dispatch(setAccountToRun(selectedOption));
    }

    startBatchRun = () => {
      this.props.dispatch(startBillingRun());
    }

    startAccountRun = () => {
      this.props.dispatch(startBillingRun(this.props.billing.account.accountnumber));
    }

    getQueueStatus = () => {
      this.props.dispatch(toggleBillingQueueLoading());
      this.props.dispatch(getBillingQueueStatus()).then(() => this.props.dispatch(toggleBillingQueueLoading()));
    }

    render() {
      const { account, batchRunLoading, accountRunLoading } = this.props.billing;
      return (
            <div className="mainCard animated fadeIn">
                <Row className="d-flex justify-content-between">
                    <Col className={'d-flex'}>
                        <Card className="flex-1">
                            <CardBody className={'d-flex flex-column justify-content-between'}>
                                <CardTitleBold className="display-5">Preview</CardTitleBold>
                                <p>View customers' billable products, view projected bills.</p>
                                <Link className="align-self-end" to={'/billing/accounts'}><Button color={'primary'}>Enter</Button></Link>
                            </CardBody>
                        </Card>

                    </Col>
                    <Col className={'d-flex'}>
                        <Loadable className="flex-1" animate={false} active={batchRunLoading || accountRunLoading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                            <Card >
                                <CardBody className={'d-flex flex-column justify-content-between'}>
                                    <CardTitleBold className="display-5 d-flex">Start a Billing Run</CardTitleBold>
                                    <FormGroup>
                                        <AsyncSelectMod
                                            isClearable={true}
                                            value={account}
                                            onChange={this.setAccount}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ accountid }) => accountid}
                                            loadOptions={this.getActiveCustomers} />
                                    </FormGroup>
                                    <FormGroup className={'d-flex justify-content-between mb-0'}>
                                        <Button color="primary" className="text-white" onClick={this.startAccountRun} disabled={account === null}>Account Run</Button>
                                        <Button color="info" className="text-white" onClick={this.startBatchRun} disabled={account !== null}>Batch Run</Button>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Loadable>
                    </Col>
                    <Col className={'d-flex'}>
                        <Card className="flex-1">
                            <CardBody className={'d-flex flex-column justify-content-between'}>
                                <CardTitleBold className="display-5">Admin</CardTitleBold>
                                <p>Manage bills, Direct Debit mandates, payees, contacts.</p>
                                <Link className="align-self-end" to={'/billing/admin'}><Button color={'primary'}>Enter</Button></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row >
                    <Col md={6} sm={12} className={'d-flex'}>
                        <Card className={'w-100'}>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col className="d-flex justify-content-between">
                                        <CardTitleBold>Billing Runs</CardTitleBold>
                                        <div className="h4 text-primary mb-0 ms-2" style={{ cursor: 'pointer' }} onClick={this.getRuns}><i className="icon-refresh"></i></div>
                                    </Col>
                                </Row>
                                <Loadable animate={false} active={this.props.billingRuns.loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                                    <BillingRunsTable/>
                                </Loadable>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card className={'w-100'}>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col className="d-flex justify-content-between">
                                        <CardTitleBold>Billing Queue</CardTitleBold>
                                        <div className="h4 text-primary mb-0 ms-2" style={{ cursor: 'pointer' }} onClick={this.getQueueStatus}><i className="icon-refresh"></i></div>
                                    </Col>
                                </Row>
                                <Loadable animate={false} active={this.props.billingQueue.loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                                    <BillingQueue/>
                                </Loadable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
      );
    }
}
function mapStateToProps({ billing, billingRuns, billingQueue }) {
  return {
    billingRuns,
    billing,
    billingQueue
  };
}
export default connect(mapStateToProps)(Billing);
