import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  createStockItem,
  getStockItem,
  resetStockItem,
  setStockItemField,
  updateStockItem,
  deleteStockItem,
  attachStockItemLicense,
  detachStockItemLicense,
  addStockItemNote,
  updateStockItemNote,
  removeStockItemNote,
  getStockItemAuditHistory
} from '../../../actions/stockItem';
import form from './form';
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,

  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from 'reactstrap';
import isEmpty from 'lodash.isempty';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import OrderService from '../../../utils/Order/OrderService';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';
import LoadingOverlay from '../../../components/LoadingOverlay';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CollapsibleCard from '../../../components/CollapsibleCard';
import Notes from '../../../components/Notes';
import AuditHistory from '../../../components/AuditHistory';
import queryString from 'query-string';
import { formValidator } from '../../../helpers/FormValidator';
import { setConfirmDialog } from '../../../actions/dialogs';
import { diff } from 'deep-object-diff';
import resolveArgs from '../../../helpers/ArgumentResolver';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import LicencesTable from '../../../components/Tables/Licenses';
import HeadlessModal from '../../../components/Modals/HeadlessModal';
import License from '../License';
import DeactivatedEntityWarning from '../../../components/Alerts/DeactivatedEntityWarning';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import pick from 'lodash.pick';
import { LicenseService } from '../../../utils/ServiceDB/License';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const StockItem = ({
  id = null,
  data,
  original,
  optionSets,
  audits,
  deviceModels,
  users,
  closeModal = null
}) => {

  // router
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const includes = useMemo(() => ['deviceModel', 'deviceModel.vendor', 'notes', 'deviceDeployment', 'licenses', 'licenses.deviceModel', 'reservedFor', 'toBeRecoveredFrom'].join(','), []);
  const withs = useMemo(() => ['deviceModel', 'deviceModel.vendor','deviceDeployment', 'licenses', 'licenses.deviceModel', 'reservedFor', 'toBeRecoveredFrom'].join(';'), []);

  const [licenses, setLicenses] = useState([]);
  const [errors, setErrors] = useState([]);
  const [licenseErrors, setLicenseErrors] = useState([]);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [showLicensesModal, setShowLicensesModal] = useState(false);
  const [licenseDropdownOpen, setLicenseDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [licensesLoading, setLicensesLoading] = useState(false);

  const stockItemId = useMemo(() => (id || params.stockItemId), [id, params.stockItemId])
  const isNew = useMemo(() => stockItemId === 'new' && !original.id, [stockItemId, original.id])
  const queryParams = useMemo(() => isNew ? queryString.parse(location?.search) : null, [isNew, location?.search]);

  const toggleLoading = useCallback(() => setLoading(prevState => !prevState), [])
  const toggleLicensesLoading = useCallback(() => setLicensesLoading(prevState => !prevState), [])
  const toggleAuditsLoading = useCallback(() => setAuditsLoading(prevState => !prevState), []);
  const toggleLicenseModal = useCallback(() => setShowLicenseModal(prevState => !prevState), []);
  const toggleLicensesModal = useCallback(() => setShowLicensesModal(prevState => !prevState), []);
  const toggleLicenseDropdown = useCallback(() => setLicenseDropdownOpen(prevState => !prevState), []);

  useEffect(() => {
    if (original.serialNumber || isNew) {
      dispatch(addBreadcrumbs([{ name: !isNew ? `${original.assetTag ? `${original.assetTag} ` : ''}${original.serialNumber}`  : 'New'}]))
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.serialNumber]);

  useEffect(() => {
    if(!isNew){
      fetchData(stockItemId)
    }else if(queryParams.deviceModel){
      dispatch(setStockItemField('deviceModel', {id: queryParams.deviceModel}))
    }
    return () => dispatch(resetStockItem())
  }, [])

  const fetchData = () => {
    toggleLoading()
    dispatch(getStockItem(stockItemId, {includes, withs})).then(() => toggleLoading())
  }

  const fetchAvailableLicenses = () => {
    setLicenses([])
    toggleLicensesLoading()
    LicenseService.listAvailable(['deviceModel']).then(result => {
      toggleLicensesLoading()
      setLicenses(result.data)
    })
  }
  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getStockItemAuditHistory(stockItemId)).then(() => toggleAuditsLoading())
  }

  const requestStructure = useCallback(({ assetTag, serialNumber, deviceModel, disposalStatus, owner, reservedFor }) => ({
    assetTag,
    serialNumber,
    deviceModel: deviceModel?.id ?? deviceModel,
    reservedForType: reservedFor?.type || null,
    reservedFor: reservedFor?.data?.id || null,
    disposalStatus: disposalStatus || null,
    owner: owner,
  }), [])

  const getDeviceModelOptions = () => deviceModels.map(model => ({
    value: model.id,
    label: `${model.vendor?.name} ${model.name}`,
  }))

  const options = {
    disposalStatus: optionSets.disposalStatus.options,
    owner: optionSets.owner.options,
    deviceModel: getDeviceModelOptions()
  };

  const selectValue = {
    deviceModel: () => getDeviceModelOptions().find(model => model.value === data.deviceModel?.id)
  }
  const aSyncOptions = {
    reservedFor: (search) => Promise.all([
      OrderService.list({ search: `orderNumber:${search}`, searchFields: `orderNumber:like` })
        .then((result) => result.data || []),
      users?.filter((user) => user.name.toLowerCase().includes(search.toLowerCase())) || []
    ]).then(([result1, result2]) => {
      return [
        ...result1.map(order => ({
          type: 'order',
          data: {...order}
        })),
        ...result2.map(user => ({
          type: 'user',
          data: {...user}
        }))
      ]
    })
  };

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createStockItem(omitBy(requestStructure(data), isNull), {includes, withs})).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          }else if(result){
            navigate(`/sdb/stock/${result.id}`)
            toggleLoading()
          }
        })
      }else{
        const toSave = diff(original, data);
        if(!isEmpty(toSave.reservedFor)){
          toSave.reservedForType = true
        }
        toggleLoading()
        dispatch(updateStockItem(stockItemId, resolveArgs(pick(requestStructure(data), Object.keys(toSave))), {includes, withs})).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }

  const attachLicense = (id) => {
    setLicenseErrors([])
    if(original.licenses.find(item => item.id === id)){
      setLicenseErrors(['This license is already in use.'])
    }else{
      toggleLicensesModal()
      toggleLoading()
      dispatch(attachStockItemLicense(data.id, id)).then(() => {
        toggleLoading()
      })
    }
  }
  const detachLicense = (id) => {
      toggleLoading()
      dispatch(detachStockItemLicense(data.id, id)).then(() => {
        toggleLoading()
      })
  }

  const validated = () => {
    let errorArr = formValidator(form, data);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this Stock Item!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteStockItem(stockItemId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/stock')
          }
        })
      }
    }))
  }

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const canEdit = () => canAccessServiceDb()

  const canDelete = () => canEdit() && isEmpty(original.deviceDeployment) && isEmpty(original.licenses)

  const canDeploy = () => !isNew && !original.disposalStatus && isEmpty(original.deviceDeployment)

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-3'>
          <CardBody>
            <Row className='mb-2'>
              <Col className="d-flex justify-content-between">
                <CardTitleBold>
                  {original.id ? `${data.deviceModel.vendor.name} ${data.deviceModel.name} (${original.assetTag || `ID ${original.id}`})` : 'New Stock Item'}
                </CardTitleBold>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {canDeploy() &&
                    <Button className={'ms-2'} size={'sm'} color={'primary'} onClick={() => {
                      navigate(`/sdb/device-deployments/new?stock=${original.id}`)
                    }}>Deploy</Button>
                  }
                  {!isNew && <ButtonIcon
                    disabled={!canDelete()}
                    icon={'fa fa-trash'}
                    tooltip={!canDelete() && !isEmpty(original.deviceDeployment) ? 'Stock is currently deployed' : !canDelete() ? 'Stock has existing licenses' : 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canEdit() || loading || !hasChanges()}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <DeactivatedEntityWarning deactivated={!isEmpty(original.reservedFor)} message={`This item has been reserved for ${original.reservedFor?.data?.orderNumber ?? original.reservedFor?.data?.name}.`}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6"}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={form}
                    setField={(field, value) => dispatch(setStockItemField(field, value))}
                    options={options}
                    aSyncOptions={aSyncOptions}
                    selectValue={selectValue}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Licenses'}
                      open
                    >
                        <LicencesTable
                          licenses={original.licenses ?? []}
                          minRows={5}
                          canEdit={canAccessServiceDb()}
                          withoutNew
                          withoutFetch
                          onDetach={detachLicense}
                          extraButtons={[
                            <ButtonDropdown
                              isOpen={licenseDropdownOpen}
                              toggle={toggleLicenseDropdown}
                              direction={'left'}
                            >
                              <DropdownToggle color="secondary" size={'sm'} className={'rounded'}>
                                <i className="fa fa-plus"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => setShowLicenseModal({id: 'new'})}>Create New</DropdownItem>
                                <DropdownItem onClick={toggleLicensesModal}>Add Existing</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          ]}
                        />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{
                          type: 'stockItem',
                          data
                        }}
                        onCreated={note => dispatch(addStockItemNote(note))}
                        onUpdated={note => dispatch(updateStockItemNote(note))}
                        onDeleted={note => dispatch(removeStockItemNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>

            }

          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showLicensesModal}
        toggle={toggleLicensesModal}
        onOpened={fetchAvailableLicenses}
        onClosed={() => setLicenseErrors([])}
        size={'xxlg'}
      >
        <div className="animated fadeIn">
          <Card className={'mb-0'}>
            <CardBody >
              <LoadingOverlay loading={licensesLoading}>
                <FormValidationErrors errors={licenseErrors}/>
                <LicencesTable
                  canEdit={canAccessServiceDb()}
                  licenses={licenses}
                  fetchData={fetchAvailableLicenses}
                  withoutNew
                  extraButtons={[ <ButtonIcon onClick={toggleLicensesModal} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>]}
                  onAttach={attachLicense}
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </div>

      </HeadlessModal>
      <HeadlessModal
        open={showLicenseModal}
        size="lg"
        toggle={toggleLicenseModal}
      >
        <License id={'new'} closeModal={toggleLicenseModal} onCreated={(license) => {
            toggleLicenseModal()
            toggleLoading()
            dispatch(attachStockItemLicense(data.id, license.id)).then(() => toggleLoading())
          }
        }/>
      </HeadlessModal>
    </div>
  );
};

function mapStateToProps({
  stockItem,
  authenticationState,
  deviceModels,
  helpers
}) {
  return {
    permissions: authenticationState.account.permissions,
    data: stockItem.data,
    original: stockItem.original,
    audits: stockItem.audits,
    optionSets: helpers.optionSets.stockItem,
    users: helpers.systemUsers,
    deviceModels: deviceModels.deviceModels
  };
}

export default connect(mapStateToProps)(StockItem);
