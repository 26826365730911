import numeral from 'numeral';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import HeadlessModal from '../../Modals/HeadlessModal';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { ButtonIcon } from '../../ButtonIcon';
import omit from 'lodash.omit';
import { SelectMod } from '../../Selects/SelectMod';
import { ProductEnums } from '../../../utils/Constants/Product';
import OrderProduct from '../../../views/OrderProduct';
import LifecycleStageBadge from '../../Badges/LifecycleStageBadge';
import Currency from '../../Currency';

const stateOptions = [
  {label: 'All Services', value: 0},
  {label: 'Active Recurring Services', value: 1},
  {label: 'Active Non Recurring Services', value: 2},
  {label: 'Ceased Services', value: 3},
  {label: 'Cease Pending Services', value: 4}
]

const OrderProductsTable = ({
  includeColumns,
  excludeColumns,
  toggleAddProductWizard,
  products,
  productState,
  sites,
  contacts,
  optionSets,
  onUpdated,
  fetchData,
  withStateFilter,
  withDeactivatedRow,
  remove,
  currency
}) => {
  const [autoResetPageIndex, setAutoResetPageIndex] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showProductModal, setShowProductModal] = useState(false)
  const [stateFilter, setStateFilter] = useState({label: 'Active Recurring Services', value: 1});
  const toggleModal = () => setShowProductModal(prevState => !prevState);

  const showProduct = useCallback((product) => {
    setSelectedProduct(product)
    toggleModal()
  }, [productState.original?.id])

  const filterLifecycleStage = (product, state) => {
    if(!withStateFilter) {
      return true
    }
    if(state === 1){
      return [
        ProductEnums.billingCycle.QUARTERLY,
        ProductEnums.billingCycle.MONTHLY,
        ProductEnums.billingCycle.ANNUALLY,
      ].includes(product.billingCycle) && product.isActive
    }else if(state === 2){
      return ![
          ProductEnums.billingCycle.QUARTERLY,
          ProductEnums.billingCycle.MONTHLY,
          ProductEnums.billingCycle.ANNUALLY,
        ].includes(product.billingCycle) && product.isActive
    }else if(state === 3){
      return product.lifecycleStage === ProductEnums.lifecycleStage.CEASED
    }
    else if(state === 4){
      return product.lifecycleStage === ProductEnums.lifecycleStage.CEASE_PENDING
    }
    else {
      return true
    }

  }

  const getCarrierCircuitRefs = (circuits) => {
    return circuits.reduce((carry, circuit) => {
      return carry.concat(' ', circuit.carrierCircuitNumber ?? '');
    }, '');
  }

  let columns = [
    {
      header: 'Product',
      accessorKey: 'productNumber',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 200,
    },
    {
      header: 'Site',
      accessorKey: 'site.name',
      minSize: 200,
      accessorFn: (row => !isEmpty(row.site) ? row.site.name : '')
    },
    {
      header: 'Order Type',
      accessorKey: 'orderType',
      maxSize: 100,
      cell: props => {
        return optionSets.orderType.options.find(type => type.value === props.getValue())?.label ?? ''
      }
    },
    {
      header: 'Carrier Circuit',
      accessorKey: 'circuits',
      cell: props => {
        const circuits = props.getValue() || [];
        return getCarrierCircuitRefs(circuits)
      },
      sortingFn: (a, b) => {
        const circuitRefA = a.original.circuits?.at(0)?.carrierCircuitNumber || '';
        const circuitRefB = b.original.circuits?.at(0)?.carrierCircuitNumber || '';
        return circuitRefA.localeCompare(circuitRefB)
      }
    },
    {
      header: 'Term (m)',
      accessorKey: 'term',
      cell: props => (numeral(props.getValue()).format('0')),
      maxSize: 60
    },
    {
      header: 'Install',
      accessorKey: 'install',
      cell: props => <Currency currency={currency?.id ?? props.row.original.order?.priceList?.currency.id} value={props.getValue()}/>,
      maxSize: 100
    },
    {
      header: 'Rental',
      accessorKey: 'rental',
      cell: props => <Currency currency={currency?.id ?? props.row.original.order?.priceList?.currency.id} value={props.getValue()}/>,
      maxSize: 100
    },
    {
      header: 'Internal RFS',
      accessorKey: 'internalRfsDate',
      cell: props => props.getValue() ? (moment(props.getValue()).format('DD/MM/YYYY')) : '',
      maxSize: 100
    },
    {
      header: 'Go Live',
      accessorKey: 'goLiveDate',
      cell: props => props.getValue() ? (moment(props.getValue()).format('DD/MM/YYYY')) : '',
      maxSize: 100
    },
    {
      header: 'Valid To',
      accessorKey: 'validTo',
      cell: props => props.getValue() ? (moment(props.getValue()).format('DD/MM/YYYY')) : '',
      maxSize: 100
    },
    {
      header: 'Lifecycle Stage',
      accessorKey: 'lifecycleStage',
      maxSize: 100,
      cell: props =>
      {
        const lifecycleStage = optionSets.lifecycleStage.options.find(type => type.value === props.getValue())?.label ?? ''
        return props.getValue() ? <LifecycleStageBadge lifecycleStage={lifecycleStage}/> : ''
      },
      filterFn: (row, columnId, value) => {
        return filterLifecycleStage(row.original, value)
      },
    }
  ]

  const getColumns = () => {
    if(remove){
      columns = [
        ...columns,
        {
          header: '',
          accessorKey: 'id',
          maxSize: 20,
          cell: (props) =>  <ButtonIcon
            size={'sm'}
            icon="fa fa-close"
            tooltip={"remove association"}
            onClick={event => remove(event,props.row.original.id )}
            className={'hover-content'}
          />
        }
      ]
    }
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }
  const tableFilter = (row, columnId, value) => {
    const orderType = optionSets.orderType.options.find(x => x.value === row.original.orderType)?.label
    const lifecycleStage = optionSets.lifecycleStage.options.find(x => x.value === row.original.lifecycleStage)?.label
    const circuitRef = row.original.circuits?.at(0)?.carrierCircuitNumber || '';
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.customer)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.productNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.site?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(orderType)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(circuitRef)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(lifecycleStage)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }
  const getExportData = () => {
    return products.map(product => {
      return {
        ...omit(product, ['id']),
        billingCycle: optionSets.billingCycle.options.find(option => option.value === product.billingCycle)?.label ?? '',
        isDelivered: optionSets.isDelivered.options.find(option => option.value === product.isDelivered)?.label ?? '',
        orderType: optionSets.orderType.options.find(option => option.value === product.orderType)?.label ?? '',
        lifecycleStage: optionSets.lifecycleStage.options.find(option => option.value === product.lifecycleStage)?.label ?? '',
        company: product.company?.name ?? '',
        site: product.site?.name ?? '',
        currency: currency?.name ?? '',
        carrierCircuit: getCarrierCircuitRefs(product.circuits ?? []),
      }
    })
  }

  const getButtons = () => {
    const buttons = [
      <div data-for={'export'} data-tip={'export'} >
        <CSVLink id={'export'}
                 data={getExportData()} filename={`services-${moment().format('DD-MM-YYYY')}.csv`}
                 style={{ textDecoration: 'none' }}>

          <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
        </CSVLink>
      </div>,
    ]
    if(fetchData){
      buttons.unshift(<ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)
    }
    if(toggleAddProductWizard){
      buttons.unshift(<ButtonIcon icon={'fa fa-plus'} tooltip={'add product'} onClick={toggleAddProductWizard}/>)
    }

    if(withStateFilter){
      buttons.unshift(<div style={{minWidth: `300px`}}>
        <SelectMod
          options={stateOptions}
          isSearchable
          value={stateFilter}
          onChange={(selected) => {
            setAutoResetPageIndex(true)
            setStateFilter(selected)
          }}
          size={'sm'}
        /></div>)
    }

    return buttons;
  }

  return (
    <>
      <FilteredTableV2
        autoResetPageIndex={autoResetPageIndex}
        columnFilters={['lifecycleStage']}
        columnFilterValue={stateFilter.value}
        withDeactivatedRow={withDeactivatedRow}
        minRows={5}
        withFilter={tableFilter}
        columns={getColumns()}
        data={products}
        showPagination={products.length > 10}
        pageSizeOptions={[10, 20, 50, 100, 200]}
        noDataText={'No Products'}
        loading={false}
        defaultPageSize={10}
        onRowClick={showProduct}
        extraButtons={getButtons()}
        defaultSorted={[{id: 'goLiveDate', desc: true}]}
      />
      <HeadlessModal
        open={showProductModal}
        size={'xxlg'}
        toggle={toggleModal}
        onClosed={() => setSelectedProduct(null)}
      >
        <OrderProduct
          sites={sites}
          contacts={contacts}
          id={selectedProduct?.id}
          productNumber={selectedProduct?.productNumber}
          closeModal={toggleModal}
          onUpdated={onUpdated}
          currency={currency?.id}
        />
      </HeadlessModal>
    </>

  )
}
const mapStateToProps = ({
  helpers,
  product
}) => ({
  optionSets: helpers.optionSets.product,
  productState: product
})

export default connect(mapStateToProps)(OrderProductsTable)
