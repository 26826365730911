import React, { Component } from 'react';
import { Alert, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { getJobStatus } from '../../utils/DeviceCheck/DeviceCheckService';

class CoreCheckResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 0,
      res: {},
      tfails: 0
    };
  }

  componentDidMount() {
    getJobStatus(this.props.uuid).then((r2) => {
      let fails = 0;
      Object.entries(r2.data.meta).map(([group, results], index)=> {
        Object.entries(results).map(([key, value], index2) => {
          if (value.result != true) {
            fails += 1;
          }
        });
      });
      this.setState({ res: r2.data.meta, state: 2, tfails: fails });
    }).catch((error) => {
      this.setState({
        state: 0,
        processing: false,
        errors: {
          errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
        }
      });
    });
  }

  render() {
    if (this.state.state != 2 || this.state.res == null) {
      return (<Alert color="danger">Invalid Request</Alert>);
    }

    return (
            <div>
            {Object.entries(this.state.res).map(([group, results], index)=> {
              return (<Card>
                        <CardHeader>{group}</CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                  <Card>
                                    <CardHeader>Configured Traceoptions</CardHeader>
                                    <CardBody>
                                      <ul>
                                        {results.traceoptions.map((res, idx) => {
                                          return (<li key={"topt_"+idx}>{res}</li>);
                                        })}
                                      </ul>
                                    </CardBody>
                                  </Card>
                                  <Card>
                                    <CardHeader>Missing Sampling</CardHeader>
                                    <CardBody>
                                      <ul>
                                        {results.sampling.map((res, idx) => {
                                          return (<li key={"samp_"+idx}>{res.interface} ({res.desc})</li>);
                                        })}
                                      </ul>
                                    </CardBody>
                                  </Card>
                                    <Card>
                                        <CardHeader>OSPF Metrics</CardHeader>
                                        <CardBody>
                                    <table>
                                    {results.ospf.map((res) => {
                                      return (<tr><td>{res.neighbor_id}</td><td>{res.neighbor_name}</td><td>{res.interface}</td><td>{res.state}</td></tr>);
                                    })}
                                    </table>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardHeader>Core Interfaces</CardHeader>
                                        <CardBody>
                                    <ul>
                                        {Object.entries(results.core).map(([ifn, issues], idx) => {
                                          return (
                                                <li key={"cint_"+idx}>{ifn}<ul>
                                                    {issues.map((issue, iddx) => {
                                                      return (<li key={"imp_"+iddx}>{issue}</li>);
                                                    })}
                                                </ul></li>
                                          );
                                        })}
                                    </ul>
                                        </CardBody>
                                    </Card>
                                  <Card>
                                    <CardHeader>Un-Used Config Items</CardHeader>
                                    <CardBody>
                                      <ul>
                                        {Object.entries(results.unused).map(([ifn, issues], idx) => {
                                          return (
                                            <li key={"unused_"+idx}>{ifn}<ul>
                                              {issues.map((issue, iddx) => {
                                                return (<li key={"uuci_"+iddx}>{issue}</li>);
                                              })}
                                            </ul></li>
                                          );
                                        })}
                                      </ul>
                                    </CardBody>
                                  </Card>
                                  <Card>
                                    <CardHeader>Policies Without apply-groups</CardHeader>
                                    <CardBody>
                                      <ul>
                                        {results.policy_no_apply_groups.map((res, idx) => {
                                          return (<li key={"noap_"+idx}>{res}</li>);
                                        })}
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>Interface Issues</CardHeader>
                                        <CardBody>
                                    <ul>
                                        {Object.entries(results.interfaces).map(([ifn, issues], idx) => {
                                          return (
                                            <li key={"ifn_"+idx}>{ifn}<ul>
                                                {issues.map((issue, iddx) => {
                                                  return (<li key={"ifni_"+iddx}>{issue}</li>);
                                                })}
                                            </ul></li>
                                          );
                                        })}
                                    </ul>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>);
            })}
            </div>
    );
  }
}

export default CoreCheckResult;
