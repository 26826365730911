import React, { Fragment } from 'react';
import { Button, Col, Label, Row, FormGroup } from 'reactstrap';
import { AsyncSelectMod } from '../../../components/Selects/AsyncSelectMod';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { searchMandates } from '../../../actions/directDebits';
import { updateBillTemplateFormField } from '../../../actions/billTemplate';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const DirectDebit = ({billTemplate, directDebits, dispatch }) => {

  const handleMandateSearch = (mandate) => {
    dispatch(updateBillTemplateFormField({directDebit: mandate} ));
  }

  const getMandates = (searchString) =>{
    if (!searchString || searchString.length <= 2) {
      return [];
    }
    return dispatch(searchMandates({ accountName: searchString })).then(
      (result) => {
        return result
      }
    );
  }

  const deactivate = () =>{
    dispatch(updateBillTemplateFormField({directDebit: null} ));
  }

  if (!isEmpty(billTemplate.directDebit)) {
    return (
      <Fragment>
        <FormGroup row className="mb-0 mt-3">
          <Col md="2" className="mb-0">
            <Label htmlFor={'accountName'}><strong>Account</strong></Label>
          </Col>
          <Col xs="12" md="3">
            {billTemplate.directDebit.accountName}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Col md="2" className="mb-0">
            <Label htmlFor={'sortcode'}><strong>Sort code</strong></Label>
          </Col>
          <Col xs="12" md="3">
            {billTemplate.directDebit.accountSortCode}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Col md="2" className="mb-0">
            <Label htmlFor={'accountNumber'}><strong>Account No</strong></Label>
          </Col>
          <Col xs="12" md="3">
            {billTemplate.directDebit.accountNumber}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Col md="2" className="mb-0">
            <Label htmlFor={'mandateRef'}><strong>Mandate Ref</strong></Label>
          </Col>
          <Col xs="12" md="3">
            {billTemplate.directDebit.mandateRef}
          </Col>
        </FormGroup>
        <FormGroup row className={'mt-3'}>
          <Col>
            <Button color={'danger'} onClick={deactivate}>Remove</Button>
          </Col>
        </FormGroup>
      </Fragment>
    );
  }

  return (
    <>
      <Row className={'d-flex flex-column justify-content-center align-items-start'}>
        <Col md={6} className={'d-flex flex-column'} >
          <CardTitleBold className="text-info mb-3">Select mandate</CardTitleBold>
          <FormGroup row >
            <Col>
              <AsyncSelectMod
                isClearable={true}
                value={directDebits.selected}
                onChange={handleMandateSearch}
                getOptionLabel={({ accountName }) => accountName}
                getOptionValue={({ id }) => id}
                loadOptions={getMandates} />
            </Col>
          </FormGroup>
        </Col>
      </Row>

    </>
  );
};

function mapStateToProps({ directDebits }) {
  return {
    directDebits
  };
}
export default connect(mapStateToProps)(DirectDebit);
