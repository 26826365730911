import React, { useState } from 'react';
import { api_getAccountCircuits, api_searchCircuits } from '../../utils/Circuits/CircuitService';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import FormValidationErrors from '../Errors/FormValidationErrors';
import { AsyncSelectMod } from '../Selects/AsyncSelectMod';
import debounce from 'debounce-promise';
import isEmpty from 'lodash.isempty';
import HeadlessModal from './HeadlessModal';
import { StockItemService } from '../../utils/ServiceDB/Stock';
import CardTitleBold from '../Cards/CardTitleBold'

const StockItemLookupModal = ({
  toggle,
  open,
  stockItems,
  addStock
}) => {

  const [stockItem, setStockItem] = useState(null)
  const [errors, setErrors] = useState([])

  const search = (text) => {
    if (!text || text.length <= 2) {
      return [];
    }
    return StockItemService.list({search: text, includes: 'deviceModel', withs: 'deviceModel' }).then(result => result.data)
  }

  const add = () => {
    const errors = [];
    setErrors([])
    const isDuplicate = stockItems.find(item =>item.id === stockItem.id )
    if(isDuplicate){
      errors.push( "This stock is already in use with this license.")
    }
    setErrors(errors)
    if(isEmpty(errors)){
      addStock(stockItem.id)
    }

  }
  return (
    <HeadlessModal
      open={open}
      toggle={toggle}
      onClosed={() => {
        setStockItem(null)
        setErrors([])
      }}
    >
      <Card className='bg-light border-0 mb-0'>
        <CardHeader className={'d-flex justify-content-between'}>
          <CardTitleBold className={'mb-0 fw-bolder'}>Stock Item Lookup</CardTitleBold>
          <Button close onClick={toggle}/>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormValidationErrors errors={errors}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <AsyncSelectMod
                  isClearable={true}
                  value={stockItem}
                  onChange={selected => setStockItem(selected)}
                  getOptionLabel={({ assetTag, serialNumber, deviceModel }) => `${assetTag} - ${deviceModel?.name} - (${serialNumber})`}
                  getOptionValue={({ id }) => id}
                  loadOptions={debounce((text) => search(text), 500)}
                  placeholder={'Asset Tag, Device Model or Serial Number'}
                />
              </FormGroup>
              <FormGroup>
                <Button disabled={isEmpty(stockItem)} block onClick={add}>Add</Button>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

    </HeadlessModal>
  )
}

export default StockItemLookupModal
