import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row, Card, CardBody, Col
} from 'reactstrap';
import CaseFormFields from './form';
import generateFormFields from '../../helpers/FormFieldGenerator';
import resolveArgs from '../../helpers/ArgumentResolver';
import {
  createCase,
  getCaseData,
  getCaseEmails,
  getCaseNotes,
  resetCase,
  searchAccounts,
  setCaseEmail,
  updateCaseField,
  updateCase,
  addNewCaseNote,
  updateCaseNote
} from '../../actions/case'
import { successFeedback, warningFeedback } from '../../actions/feedback';
import isEmpty from 'lodash.isempty';
import { getActivity } from '../../actions/activity';
import { getSites } from '../../actions/accounts';
import { updatedDiff } from 'deep-object-diff';
import LoadingOverlay from '../../components/LoadingOverlay';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../components/ButtonIcon';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning';
import startsWith from 'lodash.startswith';
import CollapsibleCard from '../../components/CollapsibleCard'
import Notes from '../../components/Notes'
import Emails from '../../components/Emails'
import CardTitleBold from '../../components/Cards/CardTitleBold'

const Case = (props) => {
  const { theCase, dispatch } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState([]);
  const [showNewNote, setShowNewNote] = useState(false);
  const [newNote, setNewNote] = useState({});
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [notesLoaded, setNotesLoaded] = useState(false);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [emailsLoaded, setEmailsLoaded] = useState(false);
  const [loadingCase, setLoadingCase] = useState(false);
  const [loadingSites, setLoadingSites] = useState(false);
  const [savingCase, setSavingCase] = useState(false);
  const isNew = !props.id && !theCase.original.caseId;
  const customer = props.account || theCase.original.customer;

  useEffect(() => {
    if (props.circuit && isNew) {
      props.dispatch(updateCaseField('circuit', { ...props.circuit }));
      if (!isEmpty(props.circuit.assignedTo)) {
        props.dispatch(updateCaseField('customer', { ...props.circuit.assignedTo }));
      }
    } else if (props.order && isNew) {
      props.dispatch(updateCaseField('order', { ...props.order }));
      if (props.order.company) {
        props.dispatch(updateCaseField('customer', {
          id: props.order.company.accountid,
          name: props.order.company.name
        }));
      }
    } else if (props.product && isNew) {
      props.dispatch(updateCaseField('product', { ...props.product }));
      if (!isEmpty(props.product.assignedTo)) {
        props.dispatch(updateCaseField('customer', { ...props.product.assignedTo }));
      }
    }

   /* if (props.account && isEmpty(props.account.sites)) {
      setLoadingCase(true);
      props.dispatch(getSites(props.account.accountid))
        .then(() => {
          if (!isNew) {
            getCase();
          } else {
            props.dispatch(updateCaseField('customer', { ...props.account }));
            setLoadingCase(false);
          }
        });
    } else if (isNew && customer && !isEmpty(customer.sites)) {
      props.dispatch(updateCaseField('customer', { ...customer }));
    } else */
    if (!isNew) {
      getCase();
    }

    return () => {
      props.dispatch(resetCase());
    };
  }, []);
 /* useEffect(() => {
    if (customer && isEmpty(customer.sites)) {
      setLoadingSites(true);
      props.dispatch(getRelatedSites(customer.accountid))
        .then(result => {
          if (result) {
            setLoadingSites(false);
          }
        });
    }
  }, [customer]);*/

  useEffect(() => {
    if(isNew){
      props.dispatch(updateCaseField('customer', {
        accountid: 'c7b9fec2-6daf-e811-80f6-0050568a1912',
        name: 'hSo Service Delivery'
      }));
      props.dispatch(updateCaseField('origin', props.optionSets.origin.options.find(option => option.label === 'Internal').value));
      props.dispatch(updateCaseField('subject', props.subjects.find(option => option.name === 'Service Delivery').id));
    }
  }, [props.serviceDelivery])

  const getCase = () => {
    setLoadingCase(true);
    return props.dispatch(getCaseData(props.id || theCase.original.caseId))
      .then((result) => {
        setLoadingCase(false);
        return result;
      });
  };

  const toggleTabs = (tab) => {
    setActiveTab(tab);
    if (tab === 1 && !notesLoaded) {
      getNotes();
    }
    if (tab === 2 && !emailsLoaded) {
      getEmails();
    }
  };

  const handleInput = (event) => {
    props.dispatch(updateCaseField(event.target.id, event.target.value));
  };

  const handleInputDate = (field, value) => {
    props.dispatch(updateCaseField(field, value));
  };

  const handleSelectInput = (key, selected) => {
    props.dispatch(updateCaseField(key, selected.value));
  };
  const handleAsyncSelected = (key, selected) => {
    props.dispatch(updateCaseField(key, selected));
  };
  const getSelectOptions = (field) => {
    if (field === 'subject') {
      return Object.values(props.subjects)
        .map((option) => {
          return {
            value: option.id,
            label: option.name
          };
        });
    }
    if (field === 'status') {
      return props.optionSets.status.options
        .filter(options => options.state === theCase.original.state);
    }
    if(field === 'type' && props.serviceDelivery){
      return props.optionSets.type.options.filter(option => startsWith(option.label, 'SD '));
    }
    if (props.optionSets[field]) {
      return props.optionSets[field].options.map((option, index) => {
        return {
          value: option.value,
          label: option.label
        };
      });
    }
    return [];
  };
  const getAsyncSelectDefaultOptions = (field) => {
    if (field === 'customer' && customer) {
      return customer.sites;
    }
    return [];
  };
  const getSelectedOption = (field) => {
    let selected = [];
    if (field === 'owner') {
      selected = props.users.filter((option) => {
        return theCase.data[field] && option.id === theCase.data[field].owner;
      });
    } else if (field === 'subject') {
      selected = props.subjects.filter((option) => {
        return theCase.data[field] && option.id === theCase.data[field];
      });
    } else if (props.optionSets[field]) {
      selected = props.optionSets[field].options.filter(option => option.value === theCase.data[field]);
    }
    if (selected.length) {
      return selected[0];
    }

    return null;
  };

  const filterUsers = (search) => {
    return props.users.filter((option) => {
      return option.name.toLowerCase()
        .includes(search.toLowerCase());
    });
  };
  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if (entity === 'user') {
      return new Promise(resolve => {
        resolve(filterUsers(search));
      });
    }
    if (entity === 'account') {
      if (customer) {
        return customer.sites.filter(site => String(site.name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1);
      }
      return props.dispatch(searchAccounts(key, search))
        .then((result) => result);
    }
  };

  const validated = () => {
    let errorArr = [];
    for (const [key, value] of Object.entries(CaseFormFields)) {
      if (value.mandatory && (theCase.data[key] === undefined || theCase.data[key] === null || theCase.data[key].length === 0)) {
        errorArr.push(`You must provide a valid ${value.label}`);
      }
    }
    setErrors(errorArr);
    if (errorArr.length === 0) {
      return true;
    }
    return false;
  };

  const saveCase = () => {
    if (validated()) {
      if (!isNew) {
        const toSave = updatedDiff(theCase.original, theCase.data);
        if (isEmpty(toSave)) {
          props.dispatch(warningFeedback('nothing to update'));
          return;
        }
        setLoadingCase(true);
        props.dispatch(updateCase(theCase.original.caseId, resolveArgs(toSave)))
          .then((result) => {
            if (result) {
              props.dispatch(successFeedback('Case updated!'));
              if (props.onUpdated) {
                props.onUpdated(result);
              }
            }
            setLoadingCase(false);
          });
      } else {
        setLoadingCase(true);
        props.dispatch(createCase(resolveArgs(theCase.data)))
          .then(
            (result) => {
              if (result) {
                props.dispatch(successFeedback('Case created!'));
                if (props.onCreated) {
                  props.onCreated(result);
                }
              }
              setLoadingCase(false);
            }
          );
      }
    }
  };

  const getNotes = () => {
    setLoadingNotes(true);
    props.dispatch(getCaseNotes(props.id || theCase.original.caseId))
      .then(
        (result) => {
          if (result) {
            setNotesLoaded(true);
          }
          setLoadingNotes(false);
        }
      );
  };

  const getEmails = () => {
    setLoadingEmails(true);
    props.dispatch(getCaseEmails(props.id || theCase.original.caseId))
      .then(
        (result) => {
          if (result) {
            setEmailsLoaded(true);
          }
          setLoadingEmails(false);
        }
      );
  };

  const getEmailData = (id) => props.dispatch(getActivity(id))
    .then((result) => {
      if (result) {
        props.dispatch(setCaseEmail(result));
      }
    });

  const isActive = () => theCase.data.state === 0 || isNew
  const getDeactivatedState = () => theCase.data.state === 2 ? 'Canceled' : 'Resolved'

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loadingCase}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {theCase.original.caseId || 'New Case'}
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  <ButtonIcon disabled={loadingCase || !isActive() } icon={'fa fa-save'} tooltip={'Save'}  onClick={saveCase}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={getCase}/>
                  <ButtonIcon onClick={props.toggleModal} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                </div>
              </Col>
            </Row>
            <DeactivatedEntityWarning deactivated={!loadingCase && !isActive()} message={`This case has been ${getDeactivatedState()}.`}/>
            <FormValidationErrors errors={errors}/>
            <Row>
              <Col>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: CaseFormFields,
                      data: theCase.data,
                      handleInput,
                      getSelectOptions,
                      getAsyncSelectDefaultOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleAsyncSelected,
                      handleAsyncInput,
                      handleInputDate,
                      account: customer
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Notes'}
                  onEntering={getNotes}
                >
                  <LoadingOverlay loading={loadingNotes}>
                    <Notes
                      withNew
                      notes={theCase.original.notes}
                      relatedTo={{type: 'case', data: theCase.original}}
                      onCreated={note => dispatch(addNewCaseNote(note))}
                      onUpdated={note => dispatch(updateCaseNote(note))}
                      // onDeleted={note => dispatch(removeCaseNote(note))}
                    />
                  </LoadingOverlay>
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Emails'}
                  onEntering={getEmails}
                >
                  <LoadingOverlay loading={loadingEmails}>
                    <Emails emails={theCase.original.emails || []} loadMore={getEmailData}/>
                  </LoadingOverlay>
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )

};

function mapStateToProps({
  helpers,
  theCase
}) {
  return {
    optionSets: helpers.optionSets.case,
    subjects: helpers.subjects,
    users: helpers.systemUsers,
    theCase
  };
}

export default connect(mapStateToProps)(Case);
