import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardBody,

  Col,
  Row
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import { DeviceDeploymentService } from '../../../utils/ServiceDB/DeviceDeployment';
import {
  addDeviceDeploymentNote, addNewDeviceDeploymentPort,
  createDeviceDeployment,
  deleteDeviceDeployment,
  getDeviceDeployment,
  getDeviceDeploymentAuditHistory,
  removeDeviceDeploymentNote, removeDeviceDeploymentPort,
  resetDeviceDeployment,
  setDeviceDeploymentField,
  updateDeviceDeployment,
  updateDeviceDeploymentNote, updateDeviceDeploymentPort
} from '../../../actions/deviceDeployment';
import SiteService from '../../../utils/Site/SiteService';
import { RackService } from '../../../utils/ServiceDB/Rack';
import { StockItemService } from '../../../utils/ServiceDB/Stock';
import isEmpty from 'lodash.isempty';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';
import queryString from 'query-string';
import { diff } from 'deep-object-diff';
import LoadingOverlay from '../../../components/LoadingOverlay';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CollapsibleCard from '../../../components/CollapsibleCard';
import Notes from '../../../components/Notes';
import AuditHistory from '../../../components/AuditHistory';
import { formValidator } from '../../../helpers/FormValidator';
import { setConfirmDialog } from '../../../actions/dialogs';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import resolveArgs from '../../../helpers/ArgumentResolver';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';
import DeviceDeploymentForm from './form';
import HeadlessModal from '../../../components/Modals/HeadlessModal';
import Rack from '../Rack';
import Site from '../../Site';
import StockItem from '../StockItem';
import PortsTable from '../../../components/Tables/Ports';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const entityInitialState = {type: '', id: ''}
const DeviceDeployment = ({
  id = null,
  closeModal = null,
  data,
  original,
  rack = null,
  onCreated = null,
  simple = false,
  deploymentTypes,
  audits
}) => {

  // router
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  // state
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);
  const [showEntityModal, setShowEntityModal] = useState(false);

  const deviceDeploymentId = useMemo(() => (id || params.deviceDeploymentId), [id, params.deviceDeploymentId])
  const isNew = useMemo(() => deviceDeploymentId === 'new', [deviceDeploymentId]);

  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }

  const queryParams = isNew ? queryString.parse(location?.search) : null;

  let includes = 'stockItem,stockItem.deviceModel,rack,rack.deviceDeployments,rack.deviceDeployments.stockItem,zabbix,notes,notes.createdBy';
  let withs = 'stockItem;stockItem.deviceModel;rack;rack.deviceDeployments;rack.deviceDeployments.stockItem;notes;notes.createdBy';
  if (!simple) {
    includes += ',portConnectionDetails';
    withs += ';portsWithConnectionDetails';
  }

  useEffect(() => {
    if(!isNew){
      fetchData(deviceDeploymentId)
    }else if(!isEmpty(queryParams.stock)){
      fetchStockItem(queryParams.stock)
    }
    return () => dispatch(resetDeviceDeployment())
  }, [])

  useEffect(() => {
    if (original.hostname && !closeModal) {
      dispatch(addBreadcrumbs([{ name: original.hostname }]));
    }

    return () => {
      if(!closeModal){
        dispatch(resetBreadcrumbs());
      }

    };
  }, [original.hostname]);

  useEffect(() => {
    if (!isEmpty(data.stockItem)) {
      if (data.stockItem.deviceModel.rackWidth === 'full') {
        dispatch(setDeviceDeploymentField('rackSide', null));
      }
    }
  }, [data.stockItem]);

  useEffect(() => {
    if (!isEmpty(rack)) {
      dispatch(setDeviceDeploymentField('rack', rack));
    }
  }, [rack]);

  useEffect(() => {
    if(entity.id){
      toggleEntityModal()
    }
  }, [entity]);


  const fetchData = () => {
    toggleLoading()
    dispatch(getDeviceDeployment(deviceDeploymentId, includes, withs)).then(() => toggleLoading())
  }

  const fetchStockItem = (stockItemId) => {
    toggleLoading()
    StockItemService.get(stockItemId, {includes: 'deviceModel', withs: 'deviceModel'}).then(result => {
      if(result.status === 200){
        dispatch(setDeviceDeploymentField('stockItem', result.data));
      }
      toggleLoading()
    })
  }

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getDeviceDeploymentAuditHistory(deviceDeploymentId)).then(() => toggleAuditsLoading())
  }

  const syncWithZabbix = () => {
    toggleLoading()
    DeviceDeploymentService.syncZabbixInterfaces(deviceDeploymentId).then(
      () => {
        dispatch(getDeviceDeployment(deviceDeploymentId, includes, withs))
          .then(() => toggleLoading());
      }
    );
  }

  const getEntityComponent = () => {
    if(entity.type === 'rack'){
      return <Rack
        id={entity.id}
        closeModal={() => {
          toggleEntityModal()
          setEntity(entityInitialState)
        }}
      />
    }
    if(entity.type === 'site'){
      return <Site id={entity.id} closeModal={() => {
        toggleEntityModal()
        setEntity(entityInitialState)
      }}/>
    }
    if(entity.type === 'stockItem'){
      return <StockItem id={entity.id} closeModal={() => {
        toggleEntityModal()
        setEntity(entityInitialState)
      }}/>
    }
  }

  const options = {
    rackOrientation: [{ value: 'front', label: 'Front' }, { value: 'back', label: 'Back' }],
    rackSide: [{ value: 'L', label: 'Left' }, { value: 'R', label: 'Right' }],
    owner: [
      { value: 'hso', label: 'hSo' },
      { value: 'customer', label: 'Customer' },
      { value: 'supplier', label: 'Supplier' }
    ],
    type: deploymentTypes
  };

  const aSyncOptions = {
    site: (search) => SiteService.list([], [
      { id: 'id', value: search },
      { id: 'name', value: search }
    ], [], 'or', [], true)
      .then((result) => result?.data || []),
    rack: (search) => RackService.list(1, 100, search)
      .then((result) => result?.data?.items || []),
    stockItem: (search) => StockItemService.list({
      search: `assetTag:${search};serialNumber:${search}`,
      includes: 'deviceDeployment,deviceModel',
      withs: 'deviceDeployment;deviceModel'
    })
      .then((result) => result?.data.map((stockItem) => ({
        ...stockItem,
        isDisabled: (!isEmpty(stockItem.deviceDeployment) && (stockItem.deviceDeployment?.id !== original.id))
          || (!isEmpty(original.stockItem) && stockItem.deviceModel.id !== original.stockItem.deviceModel.id)
          || (!!stockItem.disposedStatus)
      })) || [])
  };

  const handleClick = (entityType) => {
    if(entityType === 'stockItem'){
      setEntity({type: 'stockItem', id: data.stockItem.id})
    }
    if(entityType === 'site'){
      setEntity({type: 'site', id: data.site.accountid})
    }
    if(entityType === 'rack'){
      setEntity({type: 'rack', id: data.rack.id})
    }
  }

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createDeviceDeployment(resolveArgs(data), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          }else if(result && onCreated){
            onCreated()
            toggleLoading()
          }else if(result){
            navigate(`/sdb/device-deployments/${result.id}`)
            toggleLoading()
          }
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updateDeviceDeployment(deviceDeploymentId, resolveArgs(toSave), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }

  const validated = () => {
    let errorArr = formValidator({
      ...DeviceDeploymentForm().main,
      ...DeviceDeploymentForm().rack,
    }, data, extraFieldValidation);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const extraFieldValidation = (key, value) => {
    const errorArr = [];
    if (key === 'hostname' && /^([a-z0-9-.])$/.test(value)) {
      errorArr.push(`${value.label} must contain only a-z, 0-9, - and .`);
    }
    if (key === 'psuCount' && (value < 0 || value > 8)) {
      errorArr.push(`${value.label} must be between 0 and 8.`);
    }
    if (key === 'rackStartPosition' && data.rack.id && data.stockItem.deviceModel?.id) {
      let rus = {};
      for (let i = 0; i <= data.rack.size; i++) {
        rus[i] = { L: false, R: false };
      }
      data.rack.deviceDeployments.forEach((deviceDeployment) => {
        if (deviceDeployment.rackOrientation === data.rackOrientation) {
          if (deviceDeployment.rackStartPosition === 0) {
            rus[0][deviceDeployment.rackSide] = true;
          } else {
            for (let r = deviceDeployment.rackStartPosition; r < deviceDeployment.rackStartPosition + deviceDeployment.stockItem?.deviceModel?.rackUnits; r++) {
              if (deviceDeployment.stockItem?.deviceModel?.rackWidth === 'full') {
                rus[r] = { L: true, R: true };
              } else {
                rus[r][deviceDeployment.rackSide] = true;
              }
            }
          }
        }
      });
      if (parseInt(data.rackStartPosition) === 0) {
        if (rus[0][data.rackSide] === true) {
          errorArr.push(`${value.label} is already occupied.`);
        }
      } else {
        for (let t = parseInt(data.rackStartPosition); t < (parseInt(data.rackStartPosition) + data.stockItem?.deviceModel?.rackUnits); t++) {
          if (data.stockItem?.deviceModel?.rackWidth === 'full') {
            if (!rus[t]) {
              errorArr.push(`${value.label} extends over the rack size.`);
            }
            else if (rus[t]['L'] === true || rus[t]['R'] === true) {
              errorArr.push(`${value.label} is already occupied.`);
            }
          } else {
            if (!rus[t]) {
              errorArr.push(`${value.label} extends over the rack size.`);
            } else if (rus[t][data.rackSide] === true) {
              errorArr.push(`${value.label} is already occupied.`);
            }
          }
        }
      }
    }
    return errorArr;
  };
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this Stock Item!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteDeviceDeployment(deviceDeploymentId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/device-deployments');
          }
        })
      }
    }))
  }

  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const canEdit = () => canAccessServiceDb()

  const canDelete = () => canEdit() && original.portsCount === 0

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-3'>
          <CardBody>
            <Row className='mb-2'>
              <Col className="d-flex justify-content-between">
                <CardTitleBold>
                  {original.hostname || 'New Device Deployment'}
                </CardTitleBold>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {
                    !isEmpty(data.zabbix) &&
                    <Button
                      size={'sm'}
                      color={'primary'}
                      onClick={syncWithZabbix}> Sync with Zabbix
                    </Button>
                  }
                  {!isNew && <ButtonIcon
                    disabled={!canDelete()}
                    icon={'fa fa-trash'}
                    tooltip={!canDelete() ? 'Deployment has existing ports' : 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canEdit() || loading || !hasChanges()}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6"}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={DeviceDeploymentForm(handleClick, closeModal).main}
                    setField={(field, value) => dispatch(setDeviceDeploymentField(field, value))}
                    options={options}
                    aSyncOptions={aSyncOptions}
                  />
                </EntityMainFormCard>
              </Col>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6"}>
                <EntitySubFormCard title={'Location'} grow>
                  <GenericForm
                    data={data}
                    form={DeviceDeploymentForm(handleClick, closeModal).rack}
                    setField={(field, value) => dispatch(setDeviceDeploymentField(field, value))}
                    options={options}
                    aSyncOptions={aSyncOptions}
                  />
                </EntitySubFormCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Ports'}
                      open
                    >
                      <PortsTable
                        fetchData={fetchData}
                        ports={data.portConnectionDetails}
                        deviceDeployment={original}
                        onUpdated={(data) => dispatch(updateDeviceDeploymentPort(data))}
                        onDeleted={(id) => dispatch(removeDeviceDeploymentPort(id))}
                        onCreated={(port) => dispatch(addNewDeviceDeploymentPort(port))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{
                          type: 'deviceDeployment',
                          data
                        }}
                        onCreated={note => dispatch(addDeviceDeploymentNote(note))}
                        onUpdated={note => dispatch(updateDeviceDeploymentNote(note))}
                        onDeleted={note => dispatch(removeDeviceDeploymentNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>

            }

          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity(entityInitialState)}
        size={entity.size ?? 'xxlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
    </div>
  )
};

function mapStateToProps({ accounts, deviceDeployment, deviceModels, authenticationState, helpers }) {
  return {
    permissions: authenticationState.account.permissions,
    accounts,
    data: deviceDeployment.data,
    original: deviceDeployment.original,
    audits: deviceDeployment.audits,
    deviceModels: deviceModels.deviceModels,
    deploymentTypes: helpers.optionSets.deviceDeployment.type.options,
  };
}

export default connect(mapStateToProps)(DeviceDeployment);
