import React, { useEffect, useMemo, useState } from 'react'
import {
  Alert, Badge, Button,
  Card, CardBody, CardHeader,
  Col,
  Row
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import {
  addNewOrderCase,
  addOrderNote, addOrderProduct, addOrderTask, getOrderAuditHistory, getOrderCases,
  getOrderData, getOrderEmails, removeOrderNote,
  resetOrder, setOrderEmail,
  updateOrder,
  updateOrderField, updateOrderNote, updateOrderProduct
} from '../../actions/order';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { defaultErrorFeedback, successFeedback, warningFeedback } from '../../actions/feedback';
import OrderForm from './form';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import resolveArgs from '../../helpers/ArgumentResolver';
import classnames from 'classnames';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useParams } from 'react-router-dom'
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs';
import RedFileSignOff from './RedFileSignOff';
import { getAccountContacts, getAccountSites } from '../../actions/quote';
import { ButtonIcon } from '../../components/ButtonIcon';
import TasksTable from '../../components/Tables/Tasks';
import { TaskEnums } from '../../utils/Constants/Task';
import CollapsibleCard from '../../components/CollapsibleCard';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import Contact from '../Contact';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import { setConfirmDialog } from '../../actions/dialogs';
import OrderProductsTable from '../../components/Tables/OrderProducts';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import CasesTable from '../../components/Tables/Cases';
import Emails from '../../components/Emails';
import { getActivity } from '../../actions/activity';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import AuditHistory from '../../components/AuditHistory';
import Notes from '../../components/Notes';
import { canAmendOrder, canSyncWithCRM } from '../../utils/Auth/AuthService';
import CRMSyncService from '../../utils/CRMSync';
import { formValidator } from '../../helpers/FormValidator';
import { OrderEnums } from '../../utils/Constants/Order';
import NewOrderProductRequest from '../../components/NewOrderProductRequest';
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning';
import { resolveCurrencySign } from '../../utils/Helpers/Currency';
import { NavigationBlocker } from '../../components/NavigationBlocker';
import CardTitleBold from '../../components/Cards/CardTitleBold'

const entityInitialState = {type: '', id: ''}

const Order = ({
  id = null,
  productId = null,
  order: {
    form,
    original,
    emails,
    audits
  },
  onUpdated = null,
  optionSets,
  productOptions,
  taskOptions,
  users,
  closeModal = null,
  user
}) => {

  const params = useParams();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailsLoading, setEmailsLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [casesLoading, setCasesLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [sites, setSites] = useState([]);
  const [activeTasksCount, setActiveTasksCount] = useState(0);

  const [showEntityModal, setShowEntityModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);

  const orderId = useMemo(() => id || params.id, [id, params.id])

  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }

  const toggleAddProductModal = () => {
    setShowAddProductModal(prevState => !prevState)
  }

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  const getEntityComponent = () => {
    if(entity.type === 'contact'){
      return <Contact id={entity.id} accountId={form.company.accountid} toggleModal={toggleEntityModal}/>
    }
    return ''
  }
  const toggleEmailsLoading = () => setEmailsLoading(prevState => !prevState);
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const toggleCasesLoading = () => setCasesLoading(prevState => !prevState);

  const isNew = useMemo(() => !orderId && !original.id && !productId, [orderId, original.id, productId]);

  const isProvisionalOrder = () => {
    return original.status === OrderEnums.status.SUBMITTED
  }

  useEffect(() => {
    refreshData();
    return () => {
      dispatch(resetOrder());
      if(!closeModal){
        dispatch(resetBreadcrumbs());
      }
    };
  }, []);

  useEffect(() => {
    if(!closeModal){
      dispatch(addBreadcrumbs([{name: original.orderNumber}]))
    }

  }, [original.orderNumber])

  useEffect(() => {
    setActiveTasksCount(original.tasks.filter(task => task.status === TaskEnums.status.OPEN).length)
  }, [original.tasks])

  const getEmailData = (id) => dispatch(getActivity(id))
    .then((result) => {
      if (result) {
        dispatch(setOrderEmail(result));
      }
    });

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getOrderAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }
  const refreshData = () => {
    toggleLoading()
    dispatch(getOrderData(orderId || original.id))
      .then((result) => {
        if(result){
          let promises = []
          promises.push(dispatch(getAccountContacts(result.company.accountid)).then((result) => {
            if(result){
              setContacts(result)
            }
          }))
          promises.push(dispatch(getAccountSites(result.company.accountid)).then((result) => {
            if(result){
              setSites(result)
            }
          }))
          Promise.all(promises).then(() => {
            toggleLoading()
          })
        }else{
          toggleLoading()
        }

      });
  };

  const getEmails = () => {
    if(isEmpty(emails)){
      toggleEmailsLoading()
      dispatch(getOrderEmails(original.id)).then(() => toggleEmailsLoading())
    }
  }

  const getCases = () => {
    toggleCasesLoading()
    dispatch(getOrderCases(original.id)).then(() => toggleCasesLoading())
  }
  const validated = () => {
    const orderForm = OrderForm()
    let errorArr = formValidator({
      ...orderForm.generalFields,
      ...orderForm.cease,
      ...orderForm.serviceDelivery,
      ...orderForm.documents,
    }, form);
    setErrors(errorArr);
    return isEmpty(errorArr);
  };

  const handleInput = (event) => {
    dispatch(updateOrderField(event.target.id, event.target.value));
  };

  const handleSelectInput = (key, selected) => {
    if(key === 'contact') {
      const contact = contacts.find(contact => contact.id === selected?.value)
      dispatch(updateOrderField(key, contact || null));
    }else if(key === 'owner' || key === 'engineer') {
      const user = users.find(user => user.id === selected?.value)
      dispatch(updateOrderField(key, user || null));
    }
    else{
      dispatch(updateOrderField(key, selected?.value));
    }

  };

  const handleInputDate = (field, value) => {
    dispatch(updateOrderField(field, value));
  };

  const getSelectOptions = (field) => {
    if (field === 'contact') {
      return contacts.map((contact) => {
        return {
          value: contact.id,
          label: contact.name,
          isDisabled: !contact.isActive
        };
      });
    }
    if (field === 'owner' || field === 'engineer') {
      return users.map((user) => {
        return {
          value: user.id,
          label: user.name
        };
      });
    }
    if (field === 'statusReason') {
      const statusCodes = optionSets.statusReason.options.filter(option => option.status === original.status);
      return statusCodes.map((option) => {
        return {
          value: option.value,
          label: option.label
        };
      });

    }
    if (optionSets[field]) {
      return optionSets[field].options.map((option) => {
        return {
          value: option.value,
          label: option.label
        };
      });
    }

    return [];
  };

  const getSelectedOption = (field) => {
    let selected = [];
    if (field === 'contact') {
      const contactId = form[field]?.id || form[field]
      selected = contacts.filter((contact) => {
        return form[field] && contact.id === contactId
      });
    }
    else if (field === 'owner') {
      const ownerId = form.owner?.id || form.owner
      return users.find(user => user.id === ownerId);
    }
    else if (field === 'engineer') {
      const engineerId = form.engineer?.id || form.engineer
      return users.find(user => user.id === engineerId);
    }
    else if (optionSets[field]) {
      selected = optionSets[field].options.filter(
        (option) => option.value === form[field]
      );
    }
    if (selected.length) {
      return selected[0];
    }

    return null;
  };


  const save = () => {
    if (validated()) {
      if (!isNew) {
        const toSave = diff(original, form);
        if (!isEmpty(toSave)) {
          toggleLoading()
          dispatch(updateOrder(original.id, resolveArgs(toSave)))
            .then((result) => {
              if(result.errors){
                setErrors(ApiErrorResolver(result.errors))
              }else if (result && onUpdated) {
                onUpdated(result);
              }
              toggleLoading()
            })
        } else {
          dispatch(warningFeedback('nothing to update'));
        }
      }
    }
  };

  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes)
  }

  const handleEntityClick = (key) => {
    if(key === 'contact'){
      setEntity({type: 'contact', id: form.contact?.id || form.contact})
    }
    toggleEntityModal()
  }

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }

  }

  const sync = () => {
    toggleLoading()
    CRMSyncService.orders(original.id).then((result) => {
      if(result.status === 200){
        dispatch(successFeedback('Order sync initiated!'))
      }else{
        dispatch(defaultErrorFeedback())
      }
      toggleLoading()
    })
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <NavigationBlocker shouldBlock={hasChanges()}/>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {original.orderNumber}
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {
                    !hasChanges() &&
                    canSyncWithCRM() &&
                    <Button size={'sm'} onClick={sync} color={'primary'}>Sync</Button>
                  }
                  <ButtonIcon disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <DeactivatedEntityWarning deactivated={original.company && !original.company.isActive} message={'Inactive Account Warning!'}/>
            <DeactivatedEntityWarning deactivated={!isEmpty(original.contact) && !original.contact.isActive} message={'Inactive Prime Delivery Contact Warning!'}/>
            {isProvisionalOrder() && (
              <Row>
                <Col>
                  <Alert color={'warning'}>
                    <p className="mb-0" >This is a provisional order to be used with public sector frameworks tender responses.</p>
                  </Alert>
                </Col>
              </Row>
            )}
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"}>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: OrderForm(!isEmpty(form.quote?.opportunity), resolveCurrencySign(original.priceList?.currency.id), handleEntityClick).generalFields,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleInputDate,
                      data: form
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"} >
                <EntitySubFormCard title={'Service Delivery'}>
                  <Row form>
                    {generateFormFields({
                      fields: OrderForm(!isEmpty(form.quote?.opportunity), resolveCurrencySign(original.priceList?.currency.id), handleEntityClick).serviceDelivery,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleInputDate,
                      data: form
                    })}
                  </Row>
                </EntitySubFormCard>
              </Col>
              <Col className={"d-flex col"} >
                <Row>
                  <Col className={"d-flex"} md={12}>
                    <EntitySubFormCard title={'Red File Sign Off'} grow>
                      <RedFileSignOff
                        order={original}
                        optionSets={optionSets}
                        users={users}
                      />
                    </EntitySubFormCard>
                  </Col>
                  <Col className={"d-flex"} md={12}>
                    <EntitySubFormCard title={'Cease Details'} grow>
                      <Row form>
                        {generateFormFields({
                          fields: OrderForm(!isEmpty(form.quote?.opportunity), resolveCurrencySign(original.priceList?.currency.id), handleEntityClick).cease,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleInputDate,
                          data: form
                        })}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>
              </Col>

            </Row>
            <Row>
              <Col className={"d-flex"} >
                <EntityMainFormCard grow>
                  <OrderProductsTable
                    toggleAddProductWizard={canAmendOrder(user.permissions) && !isProvisionalOrder() ? toggleAddProductModal : null}
                    fetchData={refreshData}
                    sites={sites}
                    optionSets={productOptions}
                    contacts={contacts}
                    products={original.products ?? []}
                    onUpdated={(product) => dispatch(updateOrderProduct(product))}
                    currency={original.priceList?.currency}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Notes'}
                >
                  <Notes
                    withNew
                    notes={form.notes}
                    relatedTo={{type: 'order', data: form}}
                    onCreated={note => dispatch(addOrderNote(note))}
                    onUpdated={note => dispatch(updateOrderNote(note))}
                    onDeleted={note => dispatch(removeOrderNote(note))}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Cases'}
                >
                  <LoadingOverlay loading={casesLoading}>
                    <CasesTable
                      minRows={4}
                      cases={original.cases}
                      withNew
                      relatedTo={{type: 'order', data: original}}
                      fetchData={getCases}
                      loading={casesLoading}
                      onCreated={theCase => dispatch(addNewOrderCase(theCase))}
                    />
                  </LoadingOverlay>

                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  headerExtraRight={<div className='ms-2'>
                    {activeTasksCount > 0 &&
                      <Badge color="warning">{activeTasksCount}</Badge>
                    }
                  </div>}
                  title={'Tasks'}
                >
                  <Row>
                    <Col>
                      <TasksTable
                        withNew
                        tasks={original.tasks ?? []}
                        relatedTo={{type: 'order', data: form}}
                        types={taskOptions.type}
                        priorities={taskOptions.priority}
                        statusReason={taskOptions.statusReason}
                        pageSize={10}
                        onCreated={task => dispatch(addOrderTask(task))}
                      />
                    </Col>
                  </Row>
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Emails'}
                  onEntering={getEmails}
                >
                  <Emails loading={emailsLoading} loadMore={getEmailData} emails={emails}/>
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Audit History'}
                  onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity(entityInitialState)}
        size={'xlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
      <HeadlessModal
        open={showAddProductModal}
        size={'lg'}
        toggle={toggleAddProductModal}
      >
        <NewOrderProductRequest
          order={original}
          cancel={toggleAddProductModal}
          onCreated={product => {
            toggleAddProductModal()
            dispatch(addOrderProduct(product))
            dispatch(updateOrderField('contractTermInMonths', product.order.contractTermInMonths))
            dispatch(updateOrderField('totalAmount', product.order.totalAmount))
            dispatch(updateOrderField('yearOneValue', product.order.yearOneValue))
          }}
        />

      </HeadlessModal>
    </div>
  );
};

const mapStateToProps = ({
  order,
  helpers,
  authenticationState,
  priceLists
}) => ({
  order,
  optionSets: helpers.optionSets.order,
  productOptions: helpers.optionSets.product,
  taskOptions: helpers.optionSets.task,
  users: helpers.systemUsers,
  lineItems: helpers.optionSets.lineItem,
  priceLists: priceLists.list,
  user: authenticationState.account
});

export default connect(mapStateToProps)(Order);
