import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import {
  getRack,
  updateRack,
  setRackField,
  createRack,
  resetRack,
  deleteRack, getRackAuditHistory, addRackNote, updateRackNote, removeRackNote
} from '../../../actions/rack';
import DeploymentInfoComponent from '../DeviceDeployments/DeploymentInfoComponent';
import DeviceAlarms from '../../Alarms/DeviceAlarms';
import queryString from 'query-string';
import RackForm from './form';
import SiteService from '../../../utils/Site/SiteService';
import RackDiagram from './RackDiagram';
import { GenericModal } from '../../../components/Modals/GenericModal';
import DeviceDeployment from '../DeviceDeployment';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { canAccessServiceDb, canCreateRack } from '../../../utils/Auth/AuthService';
import LoadingOverlay from '../../../components/LoadingOverlay';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CollapsibleCard from '../../../components/CollapsibleCard';
import HeadlessModal from '../../../components/Modals/HeadlessModal';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { setConfirmDialog } from '../../../actions/dialogs';
import { formValidator } from '../../../helpers/FormValidator';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import resolveArgs from '../../../helpers/ArgumentResolver';
import pick from 'lodash.pick';
import Notes from '../../../components/Notes';
import AuditHistory from '../../../components/AuditHistory';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';
import CardTitleBold from '../../../components/Cards/CardTitleBold'


const entityInitialState = {type: '', id: ''}
const Rack = ({
  deploymentTypes,
  closeModal = null,
  id = null,
  permissions,
  data,
  original,
  simple = false,
  audits
}) => {

  // router
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);
  const [addDeviceOpen, setAddDeviceOpen] = useState(false);

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const rackId = useMemo(() => (id || params.rackId), [id, params.rackId])
  const isNew = useMemo(() => rackId === 'new' && !original.id, [rackId, original.id])

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }

  useEffect(() => {
  }, [selected])

  useEffect(() => {
    if(!isNew){
      fetchData()
    }
    return () => dispatch(resetRack())
  }, [])


  const getEntityComponent = () => {}

  useEffect(() => {
    if(!closeModal){
      if (original.name) {
        dispatch(addBreadcrumbs([{ name: original.name }]))
      }

      return () => {
        dispatch(resetBreadcrumbs());
      };
    }

  }, [original.name]);

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getRackAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }

  const aSyncOptions = {
    site: (search) => SiteService.list([], [
      { id: 'id', value: search },
      { id: 'name', value: search }
    ], [], 'or', [], true)
      .then((result) => result.data || [])
  };

  const requestStructure = (data) => ({
    name: data.name,
    size: data.size,
    hsoManaged: data.hsoManaged,
    siteId: data.site?.accountnumber,
    floor: data.floor,
    room: data.room
  });

  const fetchData = () => {
    toggleLoading()
    dispatch(getRack(rackId, ['deviceDeployments', 'deviceDeployments.stockItem', 'deviceDeployments.stockItem.deviceModel', 'deviceDeployments.zabbix', 'notes']))
      .then(() => {
        toggleLoading()
        const params = queryString.parse(location?.search);
        if (params.highlight !== undefined) {
          setSelected(parseInt(params.highlight));
        }
      });
  }

  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const validated = () => {
    let errorArr = formValidator(RackForm(), data)
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this rack!",
      proceed: () => dispatch(deleteRack(rackId)).then(result => {
        if(result){
          navigate('/sdb/racks')
        }
      })
    }))
  }

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createRack(omitBy(requestStructure(data), isNull), ['deviceDeployments', 'deviceDeployments.stockItem', 'deviceDeployments.stockItem.deviceModel'])).then((result) => {
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result){
            navigate(`/sdb/racks/${result.id}`)
          }
          toggleLoading()
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updateRack(rackId, resolveArgs(pick(requestStructure(data), Object.keys(toSave))), ['deviceDeployments', 'deviceDeployments.stockItem', 'deviceDeployments.stockItem.deviceModel'])).then(result => {
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  const canDelete = () => {
    return !original.deviceDeployments.length > 0
  }

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {original.name || 'New Rack'}
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {
                    !closeModal && !isNew && canCreateRack(permissions) && <ButtonIcon disabled={original.deviceDeployments.length > 0} icon={'fa fa-trash'} tooltip={canDelete() ? 'Delete' : 'Rack has existing device deployments'}  onClick={onDeleting}/>
                  }
                  <ButtonIcon disabled={!canCreateRack(permissions) || loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex"} md={selected ? 3: 4}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={RackForm()}
                    aSyncOptions={aSyncOptions}
                    setField={(field, value) => dispatch(setRackField(field, value))}
                  />
                </EntityMainFormCard>
              </Col>
              <Col className={"d-flex"} lg={selected ? 6: 8}>
                <RackDiagram
                  key={selected}
                  rack={data}
                  showAddDeviceButton={canAccessServiceDb() && !simple && !isNew}
                  setAddDeviceOpen={setAddDeviceOpen}
                  setSelected={!simple ? setSelected : undefined}
                  selected={selected}
                />
              </Col>
              {selected ? (
                <Col className={"d-flex flex-column col"} md={3}>
                  {data.deviceDeployments.find(obj => obj.id === selected) ? (
                    <>
                      <DeploymentInfoComponent
                        deviceDeployment={data.deviceDeployments.find(obj => obj.id === selected)}
                        deploymentTypes={deploymentTypes}
                      />
                      <DeviceAlarms
                        compact={true}
                        hostname={data.deviceDeployments.find(obj => obj.id === selected).hostname}
                      />
                    </>
                  ) : ''}
                </Col>
              ) : ''}
            </Row>
            <Row>

            </Row>
            {
              !isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{type: 'rack', data}}
                        onCreated={note => dispatch(addRackNote(note))}
                        onUpdated={note => dispatch(updateRackNote(note))}
                        onDeleted={note => dispatch(removeRackNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Cases'}
                    >

                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>
            }
          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity(entityInitialState)}
        size={'xlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
      <HeadlessModal
        open={addDeviceOpen}
        onClosed={fetchData}
        size={'xlg'}
        toggle={() => setAddDeviceOpen(prevState => !prevState)}
      >
        <Row className={'mb-0'}>
          <Col>
            <DeviceDeployment
              closeModal={() => setAddDeviceOpen(false)}
              id="new"
              rack={original}
              simple={true}
              onCreated={() => {
                setAddDeviceOpen(false);
                fetchData();
              }}
            />
          </Col>
        </Row>

      </HeadlessModal>
    </div>
  );
};

function mapStateToProps({ accounts, rack, authenticationState, helpers }) {
  return {
    deploymentTypes: helpers.optionSets.deviceDeployment.type,
    permissions: authenticationState.account.permissions,
    accounts,
    data: rack.data,
    original: rack.original,
    audits: rack.audits,
  };
}

export default connect(mapStateToProps)(Rack);
