import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { ButtonIcon } from '../../components/ButtonIcon';
import { SelectMod } from '../../components/Selects/SelectMod';

const QuoteRequestSite = ({
  rerun,
  loading,
  name,
  site,
  form,
  getSelectOptions,
  getSelectedOption,
  handleSelectInput,
  handleInputGroup,
  handleInputGroupClick,
  setDefaultValues,
  remove
}) => {

  const technology = useRef(null);
  useEffect(() =>{
    if(!rerun || (technology.current && technology.current !== site.technology)){
      setDefaultValues(site.technology, site.solution)
    }
    technology.current = site.technology;
  }, [site.technology])
  return (
    <Card outline color={'secondary'}>
      <CardHeader className={'bg-secondary'}>
        <Row className={'d-flex justify-content-between'}>
          <Col md={4} className={'d-flex flex-row align-items-center me-auto'}>
            <span className="me-3">{name}</span>
            <SelectMod
              options={getSelectOptions('solution')}
              isSearchable
              value={getSelectedOption('solution')}
              onChange={(selected) => handleSelectInput('solution', selected)}
              size={'sm'}
            />
          </Col>
          <Col className={'d-flex justify-content-end align-items-center'}>
            <ButtonIcon
              onClick={remove}
              tooltip={'remove site'}
              icon={'fa fa-trash-o'}
              colour={'danger'}
            />
          </Col>
        </Row>

      </CardHeader>
      <CardBody className={'bg-light'}>
        <LoadingOverlay loading={loading}>
          <Row>
            {generateFormFields({
              fields: form,
              getSelectOptions,
              getSelectedOption,
              handleSelectInput,
              handleInputGroup,
              handleInputGroupClick,
              data: site
            })}
          </Row>
        </LoadingOverlay>
      </CardBody>
    </Card>
  )

}

export default QuoteRequestSite;
