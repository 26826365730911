import React, { Component } from 'react';
import { SpinnerInfo } from '../../../components/Spinner/Spinner';
import { getCeleryQueues } from '../../../utils/CeleryQueues/CeleryQueuesService';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Loadable from 'react-loading-overlay';
import ReactTable from 'react-table-v6';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

class CeleryQueues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: []
    };
  }

  loadData() {
    this.setState({ loading: true });
    getCeleryQueues().then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const columns = [
      { Header: 'Name', accessor: 'name', filterable: false, sortable: false },
      { Header: 'Messages', accessor: 'messages', filterable: false, sortable: true },
      { Header: 'Ready', accessor: 'messages_ready', filterable: false, sortable: true },
      { Header: 'Processing', accessor: 'messages_unacknowledged', filterable: false, sortable: true }
    ];
    return (<div>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col className="d-flex justify-content-between">
                  <CardTitleBold className={'mb-0'}>Celery Queues</CardTitleBold>
                  <div className="h4 text-primary mb-0" style={{ cursor: 'pointer' }} onClick={() => this.loadData()}><i className="icon-refresh"></i></div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Loadable active={this.state.loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                <ReactTable
                  getTheadProps={() =>{
                    return {
                      style: { border: 'none' }
                    };
                  }}
                  getTheadTrProps={() =>{
                    return {
                      style: { border: 'none' }
                    };
                  }}
                  getTheadThProps={() =>{
                    return {
                      style: { border: 'none' }
                    };
                  }}
                  className="animated fadeIn -highlight"
                  data={this.state.data}
                  defaultPageSize={10}
                  minRows={4}
                  columns={columns}
                  filterable={false}
                  defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                  defaultSorted={[{ id: 'messages', desc: true }]}
                  multiSort={true}
                />
              </Loadable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>);
  }
}

export default CeleryQueues;
