import { Button, Card, CardBody, CardHeader, Col, Row, Alert } from 'reactstrap';
import FormValidationErrors from '../Errors/FormValidationErrors';
import generateFormFields from '../../helpers/FormFieldGenerator';
import LoadingOverlay from '../LoadingOverlay';
import React, { useEffect, useState } from 'react';
import { formValidator } from '../../helpers/FormValidator';
import isEmpty from 'lodash.isempty';
import { connect, useDispatch } from 'react-redux'
import { getAllPriceLists } from '../../actions/priceLists';
import NewOrderProductRequestForm from './form';
import { lookupPriceListItem } from '../../actions/priceList';
import { ProductEnums } from '../../utils/Constants/Product';
import { getAccountServices } from '../../actions/account';
import { isCancelProduct } from '../../utils/Products/ProductService';
import resolveArgs from '../../helpers/ArgumentResolver';
import { createOrderProduct } from '../../actions/order';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import EntityMainFormCard from '../Cards/EntityMainFormCard';
import { PriceListEnums } from '../../utils/Constants/PriceList';
import CardTitleBold from '../Cards/CardTitleBold'



const NewOrderProductRequest = ({
  order,
  cancel,
  orderTypes,
  priceLists,
  onCreated
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [errors, setErrors] = useState([]);
  const [activeServices, setActiveServices] = useState([]);
  const [data, setData] = useState({
    name: '',
    term: 1,
    install: 0,
    rental: 0,
    priceList: priceLists.list.find(list => list.uuid === order.priceList.uuid),
    priceListItem: null,
    orderType: ProductEnums.orderType.NEW_ITEM,
    linkedProducts: []
  })
  const toggleLoading = () => setLoading(prevState => !prevState)


  useEffect(() => {
    if(isEmpty(priceLists.list)){
      toggleLoading()
      setLoadingText('Loading price lists ...')
      dispatch(getAllPriceLists()).then(() => {
        toggleLoading()
        setLoadingText('')
      })
    }
  }, [])

  useEffect(() => {
    if(data.orderType !== ProductEnums.orderType.NEW_ITEM && isEmpty(activeServices)){
      toggleLoading()
      setLoadingText(`Loading ${order.company.name}'s active services ...`)
      dispatch(getAccountServices(order.company.accountid)).then((result) => {
        toggleLoading()
        setLoadingText('')
        if(result){
          setActiveServices(result.filter(service => service.isActive).map(service => {
            return {label: `${service.productNumber} - ${service.name}`, value: service.id}
          }))
        }

      })
    }
    if(data.orderType === ProductEnums.orderType.NEW_ITEM){
      setData({...data, linkedProducts: []})
    }
  }, [data.orderType])

  useEffect(() => {
    if(isCancelProduct(data.priceListItem?.template.productCode)){
      setData({
        ...data,
        orderType: ProductEnums.orderType.CANCEL
      })
    }
  }, [data.priceListItem])

  const submit = () => {
    if(validated()){
      toggleLoading()
      const payload = {...data}
      if(!isEmpty(payload.linkedProducts)){
        payload.linkedProducts = payload.linkedProducts.map(product => product.value)
      }
      dispatch(createOrderProduct(order.id, resolveArgs(payload))).then((result) => {
        if(result.errors){
          setErrors(ApiErrorResolver(result.errors))
        }else if(result){
          onCreated(result)
        }
        toggleLoading()
      })
    }
  }
  const validated = () => {
    let errorArr = formValidator(NewOrderProductRequestForm, data);
    if(isCancelProduct(data.priceListItem?.template.productCode) && data.orderType !== ProductEnums.orderType.CANCEL) {
      errorArr.push(`The Order Type must be Cancel for product ${data.priceListItem.template.productCode}`);
    }
    setErrors(errorArr);
    return isEmpty(errorArr);
  };

  const handleInput = (event) => {
    setData({...data, [event.target.id]: event.target.value})
  }

  const handleSelectInput = (field, selected) => {
    if(field === 'linkedProducts'){
      setData({...data, [field]: selected ?? []})
    }else{
      setData({...data, [field]: selected.value})
    }
  }
  const getSelectOptions = (field) => {

    if(field === 'priceList'){
      return [...priceLists.list]
    }
    if(field === 'orderType'){
      return [...orderTypes]
    }
    return activeServices
  }

  const getSelectedOption = (field) => {
    if(field === 'priceList'){
      return priceLists.list?.find(priceList => priceList.id === data.priceList?.id)
    }
    if(field === 'orderType'){
      return orderTypes?.find(orderType => orderType.value === data.orderType)
    }
    if(field === 'linkedProducts'){
      return [...data.linkedProducts]
    }
    return null
  }

  const handlePriceListItemLookup = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    return dispatch(lookupPriceListItem(data.priceList.id, search)).then((result) => {
      if(result){
        return result.map(item => {
          return {
            ...item,
            name: `${item.template.productCode} - ${item.name}`}
        })
      }
      return []
    })
  }


  return (
    <div className={'animated fadeIn'}>
      <LoadingOverlay loading={loading} text={loadingText}>
        <Card className='bg-light border-0 mb-0'>
          <CardHeader>
            <CardTitleBold className={'mb-0 fw-bolder'}>New Order Product</CardTitleBold>
          </CardHeader>
          <CardBody>
            <FormValidationErrors errors={errors}/>
            <Row form>
              <Col>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: NewOrderProductRequestForm,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleAsyncInput: handlePriceListItemLookup,
                      handleAsyncSelected: (field, selected) => setData({...data, [field]: selected}),
                      data
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>



            </Row>
            <Row>
              <Col className={'d-flex justify-content-end'}>
                <Button color={'primary'} onClick={submit}>Submit</Button>
                <Button className={'ms-2'} onClick={cancel}>Cancel</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
}

const mapStateToProps = ({helpers, priceLists}) => ({
  orderTypes: helpers.optionSets.product.orderType.options,
  priceLists: priceLists,
});
export default connect(mapStateToProps)(NewOrderProductRequest);
