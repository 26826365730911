import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Alert, Col, FormGroup, Label, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { getBillingQueueJobs, toggleBillingQueueLoading } from '../../actions/billingQueue';
import ReactTable from 'react-table-v6';
import CardTitleBold from '../../components/Cards/CardTitleBold'

class BillingQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      account: null,
      loading: true,
      emulatingContact: null,
      emulatingUrl: null
    };
  }

  render() {
    const { jobs } = this.props.billingQueue;
    const inProgress = jobs.filter((job) => job.failed_at === null && job.completed_at === null).map((item) => {
      item.state = item.started_at ? 'processing' : 'waiting';
      return item;
    });
    const failed = jobs.filter((job) => job.failed_at != null);
    const columns = [
      { Header: 'Account', accessor: 'crm_account_name', filterable: false, sortable: false },
      { Header: 'State', accessor: 'state', filterable: false, sortable: false, Cell: props => props.value === 'processing' ? <div className={'text-success'}>{props.value}</div> : props.value }
    ];

    if (inProgress.length > 0) {
      return (
                        <ReactTable
                            NoDataComponent={() => null}
                            getTheadProps={() =>{
                              return {
                                style: { border: 'none' }
                              };
                            }}
                            getTheadTrProps={() =>{
                              return {
                                style: { border: 'none' }
                              };
                            }}
                            getTheadThProps={() =>{
                              return {
                                style: { border: 'none' }
                              };
                            }}
                            getTdProps={(state, rowInfo, column) =>{
                              return {
                                style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                              };
                            }}
                            className="animated fadeIn -highlight -striped"
                            data={inProgress}
                            columns={columns}
                            minRows={10}
                            defaultPageSize={10}
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            filterable={false}
                            sortable={false}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                            defaultSorted={[{ id: 'state', desc: false }]}
                            showPagination={inProgress.length > 10}
                        />
      );
    }

    return (
                    <div className="d-flex flex-column justify-content-center">
                        <i className="swg swg-porg-1 swg-6x align-self-center" style={{ marginTop: '20px', marginBottom: '20px' }}></i>
                        <CardTitleBold className={'align-self-center'}>Nothing to do.</CardTitleBold>
                    </div>

    );
  }
}

function mapStateToProps({ billingQueue }) {
  return {
    billingQueue
  };
}
export default connect(mapStateToProps)(BillingQueue);
