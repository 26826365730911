import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,

  Col,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Alert
} from 'reactstrap';
import { SelectMod } from '../../../components/Selects/SelectMod';
import {
  getCloudWatchLog,
  getCloudWatchLogs,
  setLogError, setLogList, setLogsSearchString,
  setSelectedLog,
  toggleLogModal
} from '../../../actions/logs';
import moment from 'moment';
import isUUID from 'validator/lib/isUUID';
import FilteredTable from '../../../components/FilteredTable';
import LoadingOverlay from '../../../components/LoadingOverlay';
import JSONPretty from 'react-json-pretty';
import JSONPretty1337 from 'react-json-pretty/dist/1337';
import { defaultErrorFeedback } from '../../../actions/feedback';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const Logs = (props) => {
  const {
    dispatch,
    logs
  } = props;
  const [loading, setLoading] = useState(false);
  const [logGroup, setLogGroup] = useState({
    label: 'Admin API',
    value: 'hso-admin-api'
  });
  const [logInterval, setLogInterval] = useState({
    label: 'Last 24 Hours',
    value: 'last-24-hours'
  });
  const [logLevel, setLogLevel] = useState({
    label: 'Error',
    value: 'ERROR'
  });
  const [errorLogGroup, setErrorLogGroup] = useState({
    label: 'Admin API',
    value: 'hso-admin-api'
  });
  const [errorId, setErrorId] = useState('');
  const [error, setError] = useState('');

  const getLogs = () => {
    setLoading(true);
    setError('');
    dispatch(setLogList([]));
    const timestamps = getTimestamps();
    dispatch(getCloudWatchLogs(logGroup.value, timestamps.from.unix(), timestamps.to.unix(), logLevel.value))
      .then(() => setLoading(false));
  };
  const getLog = () => {
    setError('');
    if (!isUUID(errorId)) {
      setError('Invalid Id.');
      return;
    }
    setLoading(true);
    dispatch(setLogList([]));
    dispatch(getCloudWatchLog(errorLogGroup.value, errorId))
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setLogError(result.data));
          } else if (result.status === 404) {
            setError('Not found.');
          } else {
            dispatch(defaultErrorFeedback());
          }
          setLoading(false);
        });
  };

  const getTimestamps = () => {
    const to = moment();
    const from = moment();
    if (logInterval.value === 'last-24-hours') {
      from.subtract(24, 'hours');
    } else if (logInterval.value === 'last-7-days') {
      from.subtract(7, 'days');
    }

    return {
      from,
      to
    };
  };

  const setLog = (log) => {
    dispatch(setSelectedLog(log));
    dispatch(toggleLogModal());
  };
  const tableColumns = [
    {
      filterable: false,
      sortable: true,
      Header: 'Created',
      accessor: 'timestamp',
      width: 200,
      Cell: (props) => {
        return moment(props.value)
          .format('DD/MM/YYYY HH:mm:ss');
      }
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Url',
      accessor: 'data.context.url'
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Message',
      accessor: 'data.message'
    }
  ];
  return (
    <div className="mainCard animated fadeIn">
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader>
              <Row>
                <Col className="d-flex justify-content-between">
                  <CardTitleBold className={'mb-0'}>Cloudwatch Query</CardTitleBold>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <CardTitleBold>Log Level</CardTitleBold>
              <Row className={'mb-3'}>
                <Col md={3}>
                  <SelectMod
                    options={[{
                      label: 'Admin API',
                      value: 'hso-admin-api'
                    }, {
                      label: 'Customer API',
                      value: 'hso-api'
                    }, {
                      label: 'Compare API',
                      value: 'hso-compare-api'
                    }]}
                    value={logGroup}
                    onChange={setLogGroup}
                  />
                </Col>
                <Col md={3}>
                  <SelectMod
                    options={[{
                      label: 'Last 24 Hours',
                      value: 'last-24-hours'
                    }, {
                      label: 'Last 7 Days',
                      value: 'last-7-days'
                    }]}
                    value={logInterval}
                    onChange={setLogInterval}
                  />
                </Col>
                <Col md={3}>
                  <SelectMod
                    options={[{
                      label: 'Error',
                      value: 'ERROR'
                    }]}
                    value={logLevel}
                    onChange={setLogLevel}
                  />
                </Col>
                <Col md={3}>
                  <Button
                    block
                    color='primary'
                    onClick={getLogs}
                  >List
                  </Button>
                </Col>
              </Row>
              <CardTitleBold>Error Id</CardTitleBold>
              <Row>
                <Col md={3}>
                  <SelectMod
                    options={[{
                      label: 'Admin API',
                      value: 'hso-admin-api'
                    }, {
                      label: 'Customer API',
                      value: 'hso-api'
                    }]}
                    value={errorLogGroup}
                    onChange={setErrorLogGroup}
                  />
                </Col>
                <Col md={6}>
                  <Input placeholder='Enter Error Id' value={errorId} onChange={(e) => setErrorId(e.target.value)}/>
                </Col>
                <Col md={3}>
                  <Button
                    block
                    color='info'
                    onClick={getLog}
                  >
                    Show
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col className="d-flex justify-content-between">
                  <CardTitleBold className={'mb-0'}>Logs</CardTitleBold>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <LoadingOverlay loading={loading}>
                {error &&
                <Alert color='danger'>{error}</Alert>
                }
                <FilteredTable
                  showPagination={true}
                  data={logs.searchString ? logs.filteredList : logs.list}
                  value={logs.searchString}
                  columns={tableColumns}
                  onChange={(event) => dispatch(setLogsSearchString(event.target.value))}
                  minRows={2}
                  onRowClick={(log) => setLog(log)}
                  defaultPageSize={200}
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={logs.showLogModal} toggle={() => dispatch(toggleLogModal())} size={'xlg'} static>
        <ModalHeader toggle={() => dispatch(toggleLogModal())}>
        </ModalHeader>
        <ModalBody>
          <pre>
            <JSONPretty id="json-pretty" data={logs.log} theme={JSONPretty1337}></JSONPretty>
          </pre>
        </ModalBody>
      </Modal>
    </div>
  );

};

const mapStateToProps = ({ logs }) => {
  return {
    logs
  };
};

export default connect(mapStateToProps)(Logs);
