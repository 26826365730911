import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import { AsyncSelectMod } from '../Selects/AsyncSelectMod';
import React, { useEffect, useRef, useState } from 'react';
import HeadlessModal from './HeadlessModal';
import { api_getAccountCircuits, api_searchCircuits } from '../../utils/Circuits/CircuitService';
import isEmpty from 'lodash.isempty';
import debounce from 'debounce-promise';
import FormValidationErrors from '../Errors/FormValidationErrors';
import CardTitleBold from '../Cards/CardTitleBold'

const CircuitLookupModal = ({
  account,
  toggle,
  open,
  addCircuit,
  circuits,
  product
}) => {

  const [circuit, setCircuit] = useState(null)
  const [errors, setErrors] = useState([])

  const search = (text) => {
    if (!text || text.length <= 2) {
      return [];
    }
    if(account){
      return api_getAccountCircuits(account.accountid, text).then(result => result.data)
    }else{
      return api_searchCircuits(text).then(result => result.data)
    }
  }

  const add = () => {
    const errors = [];
    setErrors([])
    const isDuplicate = circuits.find(circuitData =>circuitData.id === circuit.id )
    if(isDuplicate){
      errors.push( "This circuit is already in use.")
    }
    if(circuit.managedByServiceDB && product && !/Cloud Connect|Broadband/.test(product.priceListItem.template.productGroup.name)){
      errors.push( "This is a Carrier Circuit managed by ServiceDB and can only be associated with Broadband and Cloud Connect products.")
    }
    setErrors(errors)
    if(isEmpty(errors)){
      addCircuit(circuit)
    }

  }
  return (

    <HeadlessModal
      open={open}
      toggle={toggle}
      onClosed={() => {
        setCircuit(null)
        setErrors([])
      }}
    >
      <Card className='bg-light border-0 mb-0'>
        <CardHeader className={'d-flex justify-content-between'}>
          <CardTitleBold className={'mb-0 fw-bolder'}>Existing Circuit Lookup</CardTitleBold>
          <Button close onClick={toggle}/>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormValidationErrors errors={errors}/>
            </Col>

          </Row>
          <Row>
            <Col>
              <FormGroup>
                <AsyncSelectMod
                  isClearable={true}
                  value={circuit}
                  onChange={selected => setCircuit(selected)}
                  getOptionLabel={({ name, circuitRef }) => `${name} (${circuitRef})`}
                  getOptionValue={({ id }) => id}
                  loadOptions={debounce((text) => search(text), 500)}
                  placeholder={'name, carrier reference or hso internal ref'}
                />
              </FormGroup>
              <FormGroup>
                <Button disabled={isEmpty(circuit)} block onClick={add}>Add</Button>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

    </HeadlessModal>


  )
}

export default CircuitLookupModal
