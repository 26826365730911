import isEmpty from 'lodash.isempty';

const form = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    width: 12,
    maxlength: 160
  },
  description: {
    label: 'Description',
    type: 'text',
    width: 12,
    maxlength: 160
  },
  aEndDevice: {
    label: 'A End Device',
    mandatory: true,
    type: 'select-async',
    entity: 'deviceDeployment',
    placeholder: 'Search by deployment name...',
    width: 6,
    readOnly: (key, field, data) => data.aEndProvided
  },
  aEnd: {
    label: 'A End',
    type: 'select',
    mandatory: true,
    width: 6,
    readOnly: (key, field, data) => data.aEndProvided || isEmpty(data.aEndDevice) || data.aEndDevice.isCluster,
  },
  bEndDevice: {
    label: 'B End Device',
    type: 'select-async',
    entity: 'deviceDeployment',
    mandatory: false,
    placeholder: 'Search by deployment name...',
    width: 6,
    readOnly: (key, field, data) => data.bEndProvided
  },
  bEnd: {
    label: 'B End',
    type: 'select',
    mandatory: false,
    width: 6,
    readOnly: (key, field, data) => data.bEndProvided || isEmpty(data.bEndDevice)
  }
};

export default form;
