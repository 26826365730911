import {
  Button,
  Card,
  CardBody,
  CardHeader,

  Col,
  Input,
  InputGroup,
  Row
} from 'reactstrap';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { downloadNoteAttachment } from '../../actions/note';
import isEmpty from 'lodash.isempty';
import HeadlessModal from '../Modals/HeadlessModal';
import Note from '../../views/Note';
import classnames from 'classnames';
import UnsavedChangesAlert from '../Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../ButtonIcon';
import CardTitleBold from '../Cards/CardTitleBold'



const NoteListItem = ({
  note,
  handleEdit,
  handleDownload
}) => {

  const [showButtons, setShowButtons] = useState(false);
  const toggleButtons = () => {
    setShowButtons(prevState => !prevState);
  };

  return (
    <>
      <Card className={showButtons ? 'border-secondary' : 'border-light'} onMouseOver={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
        <CardHeader className={classnames('d-flex', 'justify-content-between', 'bg-white', 'border-top', {'border-light' : !showButtons}, {'border-secondary' : showButtons}  )}>
          <CardTitleBold className={'mb-0'}>{note.subject}</CardTitleBold>
          {showButtons &&
            <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
              <ButtonIcon icon={'fa fa-pencil'} tooltip={'Edit'}  onClick={() => handleEdit(note)}/>
            </div>
          }
        </CardHeader>
        <CardBody>
          <Row>
            <Col style={{ whiteSpace: 'pre-line' }}>
              {note.description}
            </Col>
          </Row>
          {!isEmpty(note.attachment) &&
            <Row className={'mt-3'}>
              <Col md={6} lg={4}>
                <InputGroup>
                  <Input
                    className={'text-wrap'}
                    disabled={true}
                    value={note.attachment?.name}
                  />
                  <Button onClick={() => handleDownload(note.id) }>
                    <i className={'fa fa-cloud-download'}/>
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          }
          <Row>
            <Col>
              <span className={'float-end text-secondary'}>
                <small>
                  {moment(note.createdOn)
                    .format('DD/MM/YYYY HH:mm')} {note.createdBy?.name || note.createdBy }
                </small>
              </span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>

  );
};

export default connect()(NoteListItem);
