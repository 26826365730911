import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card } from 'reactstrap';
import { generateSummary, getSummary, toggleBillingBoothLoading } from '../../actions/billingBooth';
import Loadable from 'react-loading-overlay';
import ReactTable from 'react-table-v6';
import moment from 'moment';
import CardTitleBold from '../../components/Cards/CardTitleBold'

class BillingBooth extends Component {
  componentDidMount() {
    this.props.dispatch(toggleBillingBoothLoading());
    this.props.dispatch(getSummary()).then(() => this.props.dispatch(toggleBillingBoothLoading()));
  }

    generateSummary = () => {
      this.props.dispatch(toggleBillingBoothLoading());
      this.props.dispatch(generateSummary()).then(() => this.props.dispatch(toggleBillingBoothLoading()));
    }

    render() {
      const { summary, loading } = this.props.billingBooth;
      const tariffColumns = [{ Header: 'Tariff', accessor: 'tariff' }, { Header: 'Customers', accessor: 'customers', filterable: false }];
      const tariffData = Object.keys(summary.tariffs).map((tariff, index) => {
        return { tariff, customers: summary.tariffs[tariff] };
      });
      return (
            <Loadable animate={false} active={loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                <Row>
                    <Col>
                        <div className="b-b-2-dark mb-3 d-flex justify-content-between">
                            <div>
                                <CardTitleBold>Last Updated: {moment(summary.createdAt.date).local().format('LLL')}</CardTitleBold>
                                <CardTitleBold>Status: {summary.errors.length === 0 ? 'OK' : 'Errors'}</CardTitleBold>
                            </div>
                            <div className="h4 text-primary mb-0 ms-auto d-flex " style={{ cursor: 'pointer' }} onClick={this.generateSummary}><i className="icon-refresh"></i></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className={'w-100'}>
                        <ReactTable
                            className="animated fadeIn -highlight -striped"
                            NoDataComponent={() => null}
                            data={tariffData}
                            columns={tariffColumns}
                            minRows={10}
                            filterable={true}
                            resizable={false}
                            defaultPageSize={10}
                            getTheadProps={() =>{
                              return {
                                style: { border: 'none' }
                              };
                            }}
                            getTheadTrProps={() =>{
                              return {
                                style: { border: 'none' }
                              };
                            }}
                            getTheadThProps={() =>{
                              return {
                                style: { border: 'none', whiteSpace: 'unset' }
                              };
                            }}
                            sortable={true}
                            defaultSorted={[{ id: 'customers', desc: true }]}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                        />
                    </Col>
                    <Col md={6} className={'w-100'}>
                        {summary.errors.length > 0
                            && <Row>
                                <Col>
                                    <Card color={'danger'} body >
                                        <CardTitleBold>Errors</CardTitleBold>
                                        <ul>
                                            {summary.errors.map((error, index) => {
                                              return <li key={'error' + index}>{error}</li>;
                                            })}
                                        </ul>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Loadable>
      );
    }
}

function mapStateToProps({ billingBooth }) {
  return {
    billingBooth
  };
}

export default connect(mapStateToProps)(BillingBooth);
