import {createRoot} from 'react-dom/client';
import {init as initApm} from '@elastic/apm-rum'
import {Provider} from 'react-redux';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./authConfig";
import '../node_modules/animate.css/animate.css';
import {PersistGate} from 'redux-persist/integration/react';
import createStore from './configureStore';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import './scss/style.scss'
import App from './App';
import Login from './views/Login/Login';

const {store, persistor} = createStore();

const apm = initApm({
  serviceName: 'hso-admin-frontend',
  serverUrl: 'https://apm.hso-group.net',
  serviceVersion: process.env.REACT_APP_GIT_SHA,
  environment: process.env.REACT_APP_ENV,
  distributedTracingOrigins: [
    process.env.REACT_APP_API_URL as string,
    process.env.REACT_APP_NETAPI_URL as string,
    process.env.REACT_APP_SOCKETIO as string,
    process.env.REACT_APP_COMPARE_API_URL as string
  ]
});

export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root') as NonNullable<HTMLElement>;

const root = createRoot(container);


root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <App/>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login/>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </PersistGate>
  </Provider>
);

export {store, apm};
