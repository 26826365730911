import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { detailedDiff, diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { connect, useDispatch } from 'react-redux'
import LoadingOverlay from '../../../components/LoadingOverlay';
import DeactivatedEntityWarning from '../../../components/Alerts/DeactivatedEntityWarning';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import generateFormFields from '../../../helpers/FormFieldGenerator';
import BillTemplateForm from './form';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';
import CollapsibleCard from '../../../components/CollapsibleCard';
import {
  activateBillTemplate, addGroupToBillTemplate,
  createBillTemplate, deactivateBillTemplate,
  getBillTemplate, replaceBillTemplateGroup,
  resetBillTemplate,
  updateBillTemplate,
  updateBillTemplateFormField
} from '../../../actions/billTemplate';
import { formValidator } from '../../../helpers/FormValidator';
import { addBillTemplate, replaceBillTemplate } from '../../../actions/billTemplates';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import OrderProductsTable from '../../../components/Tables/OrderProducts';
import { searchAccounts } from '../../../actions/opportunity';
import resolveArgs from '../../../helpers/ArgumentResolver';
import { setConfirmDialog } from '../../../actions/dialogs';
import DirectDebit from '../DirectDebit';
import BillTemplateGroupsTable from '../../../components/Tables/BillTemplateGroups';
import CardTitleBold from '../../../components/Cards/CardTitleBold'
const BillTemplate = ({
  billTemplate : {
    form,
    original
  },
  closeModal,
  countries,
  onCreate,
  onUpdate,
  id
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const isNew = !id && !original.id
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  useEffect(() => {
    if(!isNew){
      refreshData()
    }
    return () => {
      dispatch(resetBillTemplate())
    }
  }, [])
  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createBillTemplate(resolveArgs(form))).then((result) => {
          toggleLoading()
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if (result && onCreate){
            onCreate(result)
          }
        })
      }else{
        const toSave = diff(original, form);
        if (!isEmpty(toSave)) {
          toggleLoading();
          dispatch(updateBillTemplate(original.id, resolveArgs(toSave))).then((result) => {
            if(result.errors){
              setErrors(ApiErrorResolver(result.errors))
            }else if (result && onUpdate){
              onUpdate(result)
            }
            toggleLoading()
          })
        }
      }
    }
  }

  const deactivate = () => {
    toggleLoading()
    dispatch(deactivateBillTemplate(original.id)).then((result) => {
      toggleLoading()
      if (result && onUpdate){
        onUpdate(result)
      }
    })
  }

  const restore = () => {
    toggleLoading()
    dispatch(activateBillTemplate(original.id)).then((result) => {
      toggleLoading()
      if (result && onUpdate){
        onUpdate(result)
      }
    })
  }

  const refreshData = () => {
    setErrors([])
    toggleLoading()
    dispatch(getBillTemplate(id || original.id)).then(() => toggleLoading())
  }
  const validated = () => {
    let errorArr = formValidator({
      ...BillTemplateForm(isNew).general,
      ...BillTemplateForm(isNew).address,
      ...BillTemplateForm(isNew).contacts,
    }, form);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const handleInput = (event) =>{
    const field = event.target.id;
    const value = event.target.value;
    dispatch(updateBillTemplateFormField({[field] : value}))
  }

  const handleSelectInput = (key, selected) => {
    dispatch(updateBillTemplateFormField({[key] : selected?.value ?? null}))
  }
  const getSelectedOption = (field) => {
    return countries.find(country => country.value === form.addressCountry)
  }

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    return dispatch(searchAccounts(search))
      .then((result) => result);
  };

  const handleAsyncSelected = (field, selected) => {
    dispatch(updateBillTemplateFormField({[field] : selected}))
    dispatch(updateBillTemplateFormField({addressCompany: selected.name}))
    dispatch(updateBillTemplateFormField({addressStreet1: selected.addressLine1}))
    dispatch(updateBillTemplateFormField({addressStreet2: selected.addressLine2}))
    dispatch(updateBillTemplateFormField({addressStreet3: selected.addressLine3}))
    dispatch(updateBillTemplateFormField({addressCity: selected.addressCity}))
    dispatch(updateBillTemplateFormField({addressCounty: selected.addressCounty}))
    dispatch(updateBillTemplateFormField({addressCountry: selected.addressCountry}))
    dispatch(updateBillTemplateFormField({addressPostcode: selected.addressPostcode}))
  }

  const getSelectOptions = () => {
    return countries
  }
  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes) && isActive()
  }
  const onClosing = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else if(closeModal){
      closeModal()
    }
  }

  const isActive = () => {
    return original.isActive || isNew
  }

  const onDeactivate = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "This will deactivate the template.",
      proceed: () => deactivate()
    }))
  }
  const onRestore = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "This will reactivate the template.",
      proceed: () => restore()
    }))
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {original.name}
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {isActive() && !isNew &&
                    <Button
                      onClick={onDeactivate}
                      color={'secondary'}
                      size={'sm'}
                      className={'ms-2'}
                    >
                      Deactivate
                    </Button>
                  }
                  {!isActive() &&
                    <Button
                      onClick={onRestore}
                      color={'secondary'}
                      size={'sm'}
                      className={'ms-2'}
                    >
                      Restore
                    </Button>
                  }
                  <ButtonIcon disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <DeactivatedEntityWarning deactivated={!original.isActive} message={'This template has been deactivated.'}/>
            <DeactivatedEntityWarning deactivated={original.company && !original.company.isActive} message={'Inactive Account Warning!'}/>
            <FormValidationErrors errors={errors}/>
            <Row className="d-flex justify-content-between">
              <Col className={"d-flex md-6"}>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: BillTemplateForm(isNew).general,
                      handleInput,
                      handleSelectInput,
                      handleAsyncInput,
                      handleAsyncSelected,
                      data: form
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
              <Col className={"d-flex md-6"} >
                <EntitySubFormCard title={'Address'}>
                  <Row form>
                    {generateFormFields({
                      fields: BillTemplateForm(isNew).address,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      data: form
                    })}
                  </Row>
                </EntitySubFormCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Contacts'}
                >
                  <Row form>
                    {generateFormFields({
                      fields: BillTemplateForm(isNew).contacts,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      data: form
                    })}
                  </Row>
                </CollapsibleCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Direct Debit'}
                    >
                      <DirectDebit billTemplate={form}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Products'}
                    >
                      <OrderProductsTable products={form.products ?? []} includeColumns={[
                        'productNumber', 'name', 'install', 'rental', 'goLiveDate'
                      ]}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Product Groups'}
                    >
                      <BillTemplateGroupsTable
                        groups={form.billTemplateGroups ?? []}
                        onCreate={(group) => dispatch(addGroupToBillTemplate(group))}
                        onUpdate={(group) => dispatch(replaceBillTemplateGroup(group))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>
            }
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
}

const mapStateToProps = ({
  billTemplate,
  helpers
}) => ({
  billTemplate,
  countries: helpers.optionSets.account?.addressCountry.options ?? []
});
export default connect(mapStateToProps)(BillTemplate);
