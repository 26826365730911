import FormFieldGenerator from '../../helpers/FormFieldGenerator';
import {
  Card,
  CardBody,

  Col,
  Row
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { ButtonIcon } from '../../components/ButtonIcon';
import classnames from 'classnames';
import LoadingOverlay from '../../components/LoadingOverlay';
import { connect } from 'react-redux';
import { setConfirmDialog } from '../../actions/dialogs';
import {
  addNewProductCircuit,
  addNewProductNote, createCircuitAssociation, deleteCircuitAssociation, getOrderProductAuditHistory,
  getProduct, getProductCircuits, removeProductCircuit, removeProductNote,
  resetProductData,
  updateProduct, updateProductCircuit,
  updateProductFormField, updateProductNote
} from '../../actions/product';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import CollapsibleCard from '../../components/CollapsibleCard';
import CircuitsTable from '../../components/Tables/Circuits';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import LinkedProductsTable from '../../components/Tables/LinkedProducts';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import { formValidator } from '../../helpers/FormValidator';
import AuditHistory from '../../components/AuditHistory';
import Notes from '../../components/Notes';
import { canAmendOrder } from '../../utils/Auth/AuthService';
import productForm from './form';
import { lookupPriceListItem } from '../../actions/priceList';
import resolveArgs from '../../helpers/ArgumentResolver';
import { ProductEnums } from '../../utils/Constants/Product';
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning';
import LifecycleStageBadge from '../../components/Badges/LifecycleStageBadge';
import { removeCarrierCircuitProduct } from '../../actions/carrierCircuit';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import { resolveCurrencySign } from '../../utils/Helpers/Currency';
import CardTitleBold from '../../components/Cards/CardTitleBold'


const OrderProduct = (props) => {

  const {
    productState,
    optionSets,
    sites,
    contacts,
    suppliers,
    closeModal,
    dispatch,
    onUpdated,
    user,
    currency
  } = props
  const{form, original, audits} = productState;
  const [loading, setLoading] = useState(false);
  const [circuitsLoading, setCircuitsLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [circuitErrors, setCircuitErrors] = useState([]);
  const accountSites = sites ?? form.company?.sites ?? []
  const toggleLoading = () => setLoading(prevState => !prevState);
  const toggleCircuitsLoading = () => setCircuitsLoading(prevState => !prevState);
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const id = props.id || original.id;
  const canAmend = canAmendOrder(user.permissions)

  useEffect(() => {
    getProductData()
    return () => dispatch(resetProductData())
  }, [id])

  const getProductData = () =>{
    setErrors([])
    toggleLoading()
    dispatch(getProduct(id)).then(()=> toggleLoading())
  }

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getOrderProductAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }
  const save = () => {
    const changes = diff(original, form);
    if(validated(changes)){
      toggleLoading()
      dispatch(updateProduct(original.id, resolveArgs(changes) )).then((result) => {
        if(result?.errors){
          setErrors(Object.values(result.errors))
        }else if(result && onUpdated){
          onUpdated(result)
        }
        toggleLoading()
      })
    }
  }

  const fetchCircuits = () => {
    toggleCircuitsLoading()
    dispatch(getProductCircuits(original.id)).then(() => toggleCircuitsLoading())
  }

  const getLifecycleStage = () => {
    return optionSets.lifecycleStage.options.find(option => option.value === original.lifecycleStage)?.label ?? ''
  }
  const validated = (changes) => {
    const forms = productForm(canAmend)
    let errorArr = formValidator({
      ...forms.general,
      ...forms.billing,
      ...forms.serviceDelivery,
    }, form);

    if(changes.lifecycleStage === ProductEnums.lifecycleStage.CANCELED && !canAmend ){
      errorArr.push('You have no permission to cancel this product.')
    }
    setErrors(errorArr);
    return isEmpty(errorArr);
  };

  const handleUpdateField = (event) => {
    dispatch(updateProductFormField({[event.target.id] : event.target.value}))
  }
  const handleSelectInput = (key, selected) => {
    if(key === 'billTemplate'){
      dispatch(updateProductFormField({[key] : selected ? form.company.billTemplates.find(template => template.id === selected.id) : null}))
    }
    else if(key === 'billTemplateGroup') {
      dispatch(updateProductFormField({[key] : selected ? form.billTemplate.billTemplateGroups.find(group => group.id === selected.id) : null}))
    }else{
      dispatch(updateProductFormField({[key] : selected ? selected?.value : null}))
    }
    if(key === 'billTemplate' && !isEmpty(form.billTemplatGroup)){
      dispatch(updateProductFormField({billTemplateGroup : null}))
    }
  }
  const handleInputDate = (field, value) => {
    dispatch(updateProductFormField({[field] : value ?? null}))
  }

  const handlePriceListItemLookup = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    return dispatch(lookupPriceListItem(original.priceListItem.priceList, search)).then((result) => {
      if(result){
        return result.map(item => {
          return {
            ...item,
            name: `${item.template.productCode} - ${item.name}`}
        })
      }
      return []
    })
  }

  const handleAsyncSelected = (field, selected) => {
    dispatch(updateProductFormField({ [field]: selected }));
  };
  const getSelectedOption = (field) => {
    if (field === 'contact') {
      const contactId = form.contact?.id || form.contact
      return contacts.find((contact) => {
        return form.contact && contact.id === contactId
      }) ?? null;
    }
    if (field === 'site') {
      const siteId = form.site?.accountid || form.site
      return accountSites.find((site) => {
        return form.site && site.accountid === siteId
      }) ?? null;
    }
    if (field === 'supplier') {
      const supplierId = form.supplier?.accountid || form.supplier
      return Object.values(suppliers).find((option) => {
        return form.supplier && option.id === supplierId;
      }) ?? null;
    }
    if (field === 'billTemplate') {
      return form.company?.billTemplates.find((option) => {
        return form.billTemplate && option.id === form.billTemplate.id;
      }) ?? null;
    }
    if (field === 'billTemplateGroup') {
      return form.billTemplate?.billTemplateGroups?.find((option) => {
        return form.billTemplateGroup && option.id === form.billTemplateGroup.id;
      }) ?? null;
    }
    return optionSets[field]?.options.find(
      (option) => option.value === form[field]
    ) ?? null;
  }
  const getSelectOptions = (field) => {
    if (field === 'contact') {
      return contacts.map((contact) => {
        return {
          value: contact.id,
          label: contact.name
        };
      });
    }
    if (field === 'site') {
      return (accountSites).map((site) => {
        return {
          value: site.accountid,
          label: site.name,
          isDisabled: !site.isActive
        };
      });
    }
    if (field === 'supplier') {
      return Object.values(suppliers).map((supplier) => {
        return { value: supplier.id, label: supplier.name };
      });
    }
    if (field === 'billTemplate') {
      return form.company?.billTemplates.map(template => {
        return {
          ...template,
          isDisabled: !template.isActive
        };
      });
    }
    if (field === 'billTemplateGroup') {
      return form.billTemplate?.billTemplateGroups?.filter(group => group.isActive);
    }
    return optionSets[field]?.options.map((option) => {
      return {
        value: option.value,
        label: option.label
      };
    }) ?? [];
  };

  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes)
  }

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => {
          closeModal()
        }
      }))
    }else{
      closeModal()
    }
  }

  const onRemoveCircuitAssociation = (circuitId) => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to remove the link between this product and the circuit!",
      proceed: () => {
        toggleCircuitsLoading()
        dispatch(deleteCircuitAssociation(original.id, circuitId)).then(result => {
          if(result){
            dispatch(removeProductCircuit(circuitId))
            dispatch(removeCarrierCircuitProduct(original.id))
          }
          toggleCircuitsLoading()
        })
      }
    }))
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='mb-0'>
          <CardBody className={'bg-light border-0'}>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {original.productNumber}
                </CardTitleBold>
                <div className={'ms-2'}>
                  <LifecycleStageBadge lifecycleStage={getLifecycleStage()}/>
                </div>

                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    !loading && hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  <ButtonIcon  disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon  disabled={loading} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={getProductData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <DeactivatedEntityWarning deactivated={original.company && !original.company.isActive} message={'Inactive Account Warning!'}/>
            <DeactivatedEntityWarning deactivated={!isEmpty(original.site) && !original.site.isActive} message={'Inactive Site Warning!!'}/>
            <Row className={"d-flex"}>
              <Col md={7} className={"d-flex flex-1"}>
                <EntityMainFormCard>
                  <Row>
                    {FormFieldGenerator(
                      {
                        fields: productForm(canAmend, resolveCurrencySign(currency ?? form.order?.priceList?.currency.id)).general,
                        data: form,
                        handleInput: handleUpdateField,
                        getSelectedOption,
                        handleSelectInput,
                        getSelectOptions,
                        handleAsyncInput: handlePriceListItemLookup,
                        handleAsyncSelected,
                        optionSets
                      }
                    )}
                  </Row>
                </EntityMainFormCard>
              </Col>
              <Col md={5} className={'d-flex flex-column flex-1'}>
                <Row className={'d-flex flex-1'}>
                  <Col md={12} className={'d-flex flex-1'}>
                    <EntitySubFormCard title={'Service Delivery'}>
                      <Row>
                        {FormFieldGenerator(
                          {
                            fields: productForm(canAmend, resolveCurrencySign(currency ?? form.order?.priceList?.currency.id)).serviceDelivery,
                            data: form,
                            handleInput: handleUpdateField,
                            getSelectedOption,
                            handleSelectInput,
                            getSelectOptions,
                            handleInputDate,
                            optionSets
                          }
                        )}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>

                <Row className={'d-flex flex-1'}>
                  <Col md={12} className={'d-flex flex-1'}>
                    <EntitySubFormCard title={'Billing'}>
                      <Row>
                        {FormFieldGenerator(
                          {
                            fields: productForm(canAmend, resolveCurrencySign(currency ?? form.order?.priceList?.currency.id)).billing,
                            data: form,
                            handleInput: handleUpdateField,
                            getSelectedOption,
                            handleSelectInput,
                            getSelectOptions,
                            handleInputDate,
                            optionSets
                          }
                        )}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>

              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Notes'}
                >
                  <Notes
                    withNew
                    notes={form.notes}
                    relatedTo={{type: 'orderProduct', data: form}}
                    onCreated={note => dispatch(addNewProductNote(note))}
                    onUpdated={note => dispatch(updateProductNote(note))}
                    onDeleted={note => dispatch(removeProductNote(note))}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Linked Circuits'}
                >
                  <LoadingOverlay loading={circuitsLoading}>
                    <CircuitsTable
                      noDataText={'No Linked Circuits'}
                      fetchData={fetchCircuits}
                      circuits={form.circuits}
                      product={original}
                      onCreated={circuit => dispatch(addNewProductCircuit(circuit?.crmCircuit ? circuit?.crmCircuit : circuit))}
                      onUpdated={circuit => dispatch(updateProductCircuit(circuit?.crmCircuit ? circuit?.crmCircuit : circuit))}
                      withNew={![ProductEnums.lifecycleStage.CEASED, ProductEnums.lifecycleStage.CANCELED].includes(original.lifecycleStage)}
                      remove={(event, circuitId) => {
                        event.stopPropagation()
                        onRemoveCircuitAssociation(circuitId)
                      }}
                      associate={(circuit) => {
                        toggleCircuitsLoading()
                        setCircuitErrors([])
                        dispatch(createCircuitAssociation(original.id, circuit.id)).then(result => {
                          if(result?.errors){
                            setCircuitErrors(ApiErrorResolver(result.errors))
                          }
                          else if(result){
                            dispatch(addNewProductCircuit(circuit))
                          }
                          toggleCircuitsLoading()
                        })
                      }}
                      errors={circuitErrors}
                    />
                  </LoadingOverlay>

                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Linked Products'}
                >
                  <LinkedProductsTable
                    products={form.linkedProducts}
                    sites={sites}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Audit History'}
                  onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>


  )
}
const mapStateToProps = ({
  product,
  helpers,
  authenticationState,
  circuit,
  carrierCircuit
}) => ({
  productState: product,
  suppliers: helpers.suppliers,
  optionSets: helpers.optionSets.product,
  user: authenticationState.account,
  circuitState: circuit,
  carrierCircuitState: carrierCircuit
})

export default connect(mapStateToProps)(OrderProduct)
