import { connect, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import form from './form';
import {
  createNNI,
  deleteNNI,
  getNNI,
  resetNNI,
  updateNNI,
  updateNNIField
} from '../../../actions/nni';
import { DeviceDeploymentService } from '../../../utils/ServiceDB/DeviceDeployment';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { canCreateCarrierCircuit, canCreateNNI } from '../../../utils/Auth/AuthService';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import isEmpty from 'lodash.isempty';
import { diff } from 'deep-object-diff';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Card, CardBody, Col, Row } from 'reactstrap';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import { formValidator } from '../../../helpers/FormValidator';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import resolveArgs from '../../../helpers/ArgumentResolver';
import pick from 'lodash.pick';
import { setConfirmDialog } from '../../../actions/dialogs';
import CarrierCircuitsTable from '../../../components/Tables/CarrierCircuits';
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';
import CardTitleBold from '../../../components/Cards/CardTitleBold'

const includes = [
  'connections',
  'carrier',
  'carrierCircuits',
  'carrierCircuits.opticalSystem', 'carrierCircuits.component', 'carrierCircuits.component.connection', 'carrierCircuits.type',
  'port',
  'device',
  'device.ports',
  'connections.aEnd.deviceDeployment',
  'connections.bEnd.deviceDeployment'
]
const NNI = ({
  closeModal = null,
  permissions,
  id = null,
  data,
  original,
  optionSets,
  suppliers,
  circuitOptionSets
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams()

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const nniId = useMemo(() => (id || params.nniId), [id, params.nniId])
  const isNew = useMemo(() => nniId === 'new' && !original.id, [nniId, original.id])

  const toggleLoading = useCallback(() => {
    setLoading(prevState => !prevState)
  }, [])

  const options = useMemo(() => ({
    port: data.device?.portConnectionDetails?.map(port => ({ value: port, label: port.name, isDisabled: Boolean(port.connectionType) })),
    bearerSpeed: optionSets.bearerSpeed.options,
    carrier: Object.values(suppliers).map(({id, name, accountNumber, isActive}) => ({
      value: { accountid: id, accountnumber: accountNumber, name },
      label: `${name} (${accountNumber})`,
      isDisabled: !isActive
    })),
  }), [data.device?.portConnectionDetails, optionSets.bearerSpeed.options, suppliers]);

  useEffect(() => {
    if(!isNew){
      fetchData()
    }

    return () => dispatch(resetNNI())
  }, [])

  useEffect(() => {
    if (!closeModal) {
      dispatch(addBreadcrumbs([{ name: original.name ?? 'New' }]))
    }

    return () => {
      if(!closeModal){
        dispatch(resetBreadcrumbs());
      }

    };
  }, [original.name]);

  useEffect(() => {
    if(data?.device && data.device !== original.device){
      dispatch(updateNNIField('port', null))
    }
  }, [data.device])
  const fetchData = () => {
    toggleLoading()
    dispatch(getNNI(nniId, includes))
      .then(() => toggleLoading())
  }
  const aSyncOptions = {
    device: (search) => DeviceDeploymentService.getDeviceDeployments(
      0,
      100,
      `hostname:${search}`,
      undefined,
      'items.ports,items.portConnectionDetails',
      'ports'

    )
      .then((result) => result.data?.items || [])
  };

  const requestStructure = (data) => {
    return {
      name: data.name,
      port: data.port?.id,
      ref: data.ref,
      bearerSpeed: data.bearerSpeed,
      carrier: data.carrier?.accountid
    };
  };

  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createNNI(omitBy(requestStructure(data), isNull), includes)).then(result => {
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result){
            navigate(`/sdb/nnis/${result.id}`)
          }
          toggleLoading()
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updateNNI(nniId, resolveArgs(pick(requestStructure(data), Object.keys(toSave))), includes)).then(result => {
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }

  const validated = () => {
    let errorArr = formValidator(form, data)
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this NNI!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteNNI(nniId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/nnis')
          }

        })
      }
    }))
  }
  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitleBold>
                  {original.name || 'New NNI'}
                </CardTitleBold>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {!isNew && !closeModal && <ButtonIcon
                    disabled={original.connections.length > 0}
                    icon={'fa fa-trash'}
                    tooltip={original.connections.length > 0 ? 'The NNI has active connections': 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canCreateCarrierCircuit(permissions) || loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6"}>
                <EntityMainFormCard>
                  <GenericForm
                    data={data}
                    form={form}
                    options={options}
                    aSyncOptions={aSyncOptions}
                    setField={(field, value) => {
                      dispatch(updateNNIField(field, value))
                    }}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <EntityMainFormCard grow>
                 <CarrierCircuitsTable
                  circuits={data.carrierCircuits ?? []}
                  circuitOptionSets={circuitOptionSets}
                  suppliers={suppliers}
                  connect={circuit => navigate(`/sdb/connections/new?side=aEnd&id=${data.id}&type=nni&circuit=${circuit.id}`)}
                  createNew={() => {
                    navigate(`/sdb/carrier-circuits/new?nni=${original.id}`)
                  }}
                 />
                </EntityMainFormCard>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  );
};

function mapStateToProps({ nni, authenticationState, helpers }) {
  return {
    permissions: authenticationState.account.permissions,
    optionSets: helpers.optionSets.nni,
    circuitOptionSets: helpers.optionSets.carrierCircuit,
    suppliers: helpers.suppliers,
    data: nni.data,
    original: nni.original,
  };
}

export default connect(mapStateToProps)(NNI);
