import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import form from './form';
import {
  addLicenseNote,
  attachLicenseStockItem,
  createLicense,
  deleteLicense,
  detachLicenseStockItem,
  getLicense, getLicenseAuditHistory, removeLicenseNote,
  resetLicenseData,
  setLicenseField,
  updateLicense, updateLicenseNote,
} from '../../../actions/ServiceDB/license';
import moment from 'moment/moment';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';
import LoadingOverlay from '../../../components/LoadingOverlay';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CollapsibleCard from '../../../components/CollapsibleCard';
import Notes from '../../../components/Notes';
import AuditHistory from '../../../components/AuditHistory';
import { setConfirmDialog } from '../../../actions/dialogs';
import { diff } from 'deep-object-diff';
import resolveArgs from '../../../helpers/ArgumentResolver';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import { formValidator } from '../../../helpers/FormValidator';
import StockItemsTable from '../../../components/Tables/StockItems';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import pick from 'lodash.pick';
import StockItemLookupModal from '../../../components/Modals/StockItemLookupModal';
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';
import CardTitleBold from '../../../components/Cards/CardTitleBold'


const includes = ['stockItems', 'deviceModel', 'stockItems.deviceModel', 'notes', 'notes.createdBy']
const License = ({
  id = null,
  data,
  original,
  onCreated = null,
  closeModal = null,
  audits,
  deviceModels,
  stockItemOptionSets
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const licenseId = useMemo(() => (id || params.licenseId), [id, params.licenseId])
  const isNew = useMemo(() => licenseId === 'new' && !original.id, [licenseId, original.id])

  const toggleLoading = useCallback(() => setLoading(prevState => !prevState), []);
  const toggleAuditsLoading = useCallback(() => setAuditsLoading(prevState => !prevState), []);
  const toggleModal = useCallback(() => setShowModal(prevState => !prevState), []);

  useEffect(() => {
    if(!isNew){
      fetchData(licenseId)
    }
    return () => dispatch(resetLicenseData())
  }, [])

  useEffect(() => {
    if (original.reference && !closeModal) {
      dispatch(addBreadcrumbs([{ name: original.reference }]))
    }

    return () => {
      if(!closeModal){
        dispatch(resetBreadcrumbs());
      }
    };
  }, [original.reference]);

  const fetchData = () => {
    toggleLoading()
    dispatch(getLicense(licenseId, includes)).then(() => toggleLoading())
  }
  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getLicenseAuditHistory(licenseId)).then(() => toggleAuditsLoading())
  }
  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createLicense(omitBy(requestStructure(data), isNull), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          }else if(result){
            if(onCreated){
              onCreated(result)
            }
            if(!closeModal){
              navigate(`/sdb/licenses/${result.id}`)
            }

            toggleLoading()
          }
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updateLicense(licenseId, resolveArgs(pick(requestStructure(data), Object.keys(toSave))), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }
  const validated = () => {
    let errorArr = formValidator(form, data, extraFieldValidation);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this License!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteLicense(licenseId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/licenses')
          }
        })
      }
    }))
  }

  const detachStockItem = (id) => {
    toggleLoading()
    dispatch(detachLicenseStockItem(data.id, id)).then(() => toggleLoading())
  }

  const attachStockItem = (id) => {
    toggleLoading()
    toggleModal()
    dispatch(attachLicenseStockItem(data.id, id)).then(() => toggleLoading())
  }
  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }
  const requestStructure = (data) => ({
    reference: data.reference,
    activationCode: data.activationCode,
    quota: data.quota,
    data: data.data,
    issuedAt: data.issuedAt ? moment(data.issuedAt).format('YYYY-MM-DD') : null,
    supportedUntil: data.supportedUntil ? moment(data.supportedUntil).format('YYYY-MM-DD') : null,
    expiredAt: data.expiredAt ? moment(data.expiredAt).format('YYYY-MM-DD') : null,
    deviceModel: data.deviceModel || null
  });
  const getDeviceModelOptions = () => deviceModels.map(model => ({
    value: model.id,
    label: `${model.vendor?.name} ${model.name}`,
  }))
  const options = {
    deviceModel: getDeviceModelOptions()
  };

  const selectValue = {
    deviceModel: () => getDeviceModelOptions().find(model => model.value === data.deviceModel?.id)
  }

  const extraFieldValidation = (key, value) => {
    const errorArr = [];
    if (['reference', 'activationCode'].includes(key) && !isEmpty(data[key]) && data[key].length > 191) {
      errorArr.push(`${value.label} must not be over 191 characters.`);
    }
    if (key === 'issuedAt' && !isEmpty(data[key]) && moment(data[key]).isAfter(moment().endOf('day'))) {
      errorArr.push(`${value.label} must be in the past.`);
    }
    if ((key === 'supportedUntil' || key === 'expiredAt') && !isEmpty(data[key]) &&
      data.issuedAt && moment(data[key]).isSameOrBefore(moment(data.issuedAt).endOf('day'))) {
      errorArr.push(`${value.label} must be after Issued Date.`);
    }
    return errorArr;
  };

  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  return (

    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className={classnames('bg-light', 'border-0', { 'mb-3': !closeModal }, {'mb-0' : closeModal})}>
          <CardBody>
            <Row className='mb-2'>
              <Col className="d-flex justify-content-between">
                <CardTitleBold>
                  {original.id ? data.reference : 'New License'}
                </CardTitleBold>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {!isNew && <ButtonIcon
                    disabled={!isEmpty(original.stockItems)}
                    icon={'fa fa-trash'}
                    tooltip={!isEmpty(original.stockItems) ? 'License has Stock Items' : 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canAccessServiceDb() || loading || !hasChanges()}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={classnames("d-flex col-12 col-sm-12", {'col-md-6': !closeModal, 'col-md-12' : closeModal})}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={form}
                    setField={(field, value) => dispatch(setLicenseField(field, value))}
                    options={options}
                    selectValue={selectValue}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Stock Items'}
                      open
                    >
                     <StockItemsTable
                      stockItems={original.stockItems}
                      optionSets={stockItemOptionSets}
                      minRows={5}
                      withoutNew
                      withoutFetch
                      onDetach={stockItemId => detachStockItem(stockItemId)}
                      canEdit={canAccessServiceDb()}
                      extraButtons={[<Button size={'sm'} color={'secondary'} onClick={toggleModal}>Add Stock</Button>]}
                     />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{
                          type: 'license',
                          data
                        }}
                        onCreated={note => dispatch(addLicenseNote(note))}
                        onUpdated={note => dispatch(updateLicenseNote(note))}
                        onDeleted={note => dispatch(removeLicenseNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>
            }
          </CardBody>
        </Card>
      </LoadingOverlay>
      <StockItemLookupModal
        open={showModal}
        toggle={toggleModal}
        addStock={(stockItemId) => attachStockItem(stockItemId)}
        stockItems={original.stockItems}
      />
    </div>
  );
};

function mapStateToProps({
  license,
  deviceModels,
  helpers
}) {
  return {
    deviceModels: deviceModels.deviceModels,
    stockItemOptionSets: helpers.optionSets.stockItem,
    data: license.data,
    original: license.original,
    audits: license.audits,
  };
}

export default connect(mapStateToProps)(License);
