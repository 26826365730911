import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,

  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import classnames from 'classnames'
import AccountInfo from './AccountInfo'
import { useDispatch, useSelector } from 'react-redux'
import resolveArgs from '../../helpers/ArgumentResolver'
import {
  addAccountContact, addAccountCreditRequest,
  createAccount,
  deleteAccountDocument,
  downloadCurrentSignatureDocument,
  downloadFinalSignatureDocument,
  dropAccountCache,
  getAccountBills,
  getAccountCases,
  getAccountCircuits,
  getAccountConfigItems,
  getAccountContacts, getAccountCreditRequests,
  getAccountDocuments,
  getAccountInfo,
  getAccountLogs, getAccountOpportunities,
  getAccountOrders, getAccountPartnerships,
  getAccountServices,
  getAccountSignatures,
  getAccountSites,
  inviteToPortal,
  removeAccountDocument,
  resetAccount,
  setAccountBills,
  setAccountCases,
  setAccountCircuits,
  setAccountConfigItems,
  setAccountContacts, setAccountCreditRequests,
  setAccountDocuments,
  setAccountLogs, setAccountOpportunities,
  setAccountOrders, setAccountPartnerships,
  setAccountServices,
  setAccountSignatures,
  setAccountSites,
  updateAccount,
  updateAccountContact, updateAccountCreditRequest
} from '../../actions/account'
import { addSupplierToCRMSuppliers } from '../../actions/helpers';
import { ButtonIcon } from '../../components/ButtonIcon'
import SitesTable from '../../components/Tables/Sites'
import { diff } from 'deep-object-diff'
import isEmpty from 'lodash.isempty'
import pick from 'lodash.pick';
import { formValidator } from '../../helpers/FormValidator'
import AccountForm from './AccountInfo/form'
import LoadingOverlay from '../../components/LoadingOverlay'
import ContactsTable from '../../components/Tables/Contacts'
import { getEmulatorUrl, } from '../../actions/accounts'
import HeadlessModal from '../../components/Modals/HeadlessModal'
import { SelectMod } from '../../components/Selects/SelectMod'
import isEmail from 'validator/lib/isEmail'
import FormValidationErrors from '../../components/Errors/FormValidationErrors'
import ApiErrorResolver from '../../helpers/ApiErrorResolver'
import AccountOrdersTable from '../../components/Tables/AccountOrders'
import OrderProductsTable from '../../components/Tables/OrderProducts'
import BillsTable from '../../components/Tables/Bills'
import CasesTable from '../../components/Tables/Cases'
import ConfigItemsTable from '../../components/Tables/ConfigItems'
import CircuitsTable from '../../components/Tables/Circuits'
import AccountDocumentsTable from '../../components/Tables/AccountDocuments'
import AccountSignaturesTable from '../../components/Tables/AccountSignatures'
import AccountReports from '../Accounts/AccountReports'
import AccountCache from '../Accounts/AccountCache'
import AccountLogsTable from '../../components/Tables/AccountLogs'
import OpportunitiesTable from '../../components/Tables/Opportunities'
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert'
import { setConfirmDialog } from '../../actions/dialogs'
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning'
import { AccountEnums } from '../../utils/Constants/Account'
import PartnershipsTable from '../../components/Tables/Partnerships'
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs'
import CreditRequestsTable from '../../components/Tables/CreditRequests'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { NavigationBlocker } from '../../components/NavigationBlocker';
import CardTitleBold from '../../components/Cards/CardTitleBold'

const Account = ({
  toggleModal = null,
  id = null
}) => {

  // router
  const navigate = useNavigate()
  const { accountNumber, tab = 'info' } = useParams()
  const location = useLocation()
  let [searchParams, setSearchParams] = useSearchParams();
  // redux
  const dispatch = useDispatch()
  const {
    form,
    original,
    sites,
    contacts,
    orders,
    services,
    bills,
    cases,
    configItems,
    partnerships,
    opportunities,
    signatures,
    documents,
    circuits,
    logs,
    creditRequests
  } = useSelector(state => state.account)
  const auth = useSelector(state => state.authenticationState)

  const [activeTab, setActiveTab] = useState(tab)
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState({
    accountInfo: false,
    sites: false,
    invite: false
  })
  const [showPortalInvite, setShowPortalInvite] = useState(false)
  const [inviteContact, setInviteContact] = useState(null)
  const [inviteErrors, setInviteErrors] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const idOrNumber = useMemo(() => (accountNumber || id || original.accountid), [accountNumber, id, original.accountid])

  const toggleDropdown = useCallback(() => setDropdownOpen(prevState => !prevState), [])

  useEffect(() => {
    if (!toggleModal) {
      dispatch(addBreadcrumbs([{ name: original.name }]))
    }
  }, [original.name])

  useEffect(() => {
    dispatch(resetAccount())
    if (!isNew()) {
      initAccount()
    }
  }, [idOrNumber])

  useEffect(() => {
    return () => {
      dispatch(resetAccount())
      if (!toggleModal) {
        dispatch(resetBreadcrumbs())
      }

    }
  }, [])

  useEffect(() => {
    if (toggleModal) {
      return
    }
    const tabRegEx = new RegExp(`${activeTab}$`)
    const accountIdOrNumber = new RegExp(`${idOrNumber}$`)
    if (activeTab === 'info' && !accountIdOrNumber.test(location.pathname)) {
      navigate(`/accounts/${idOrNumber}/info`)
    } else if (location && !tabRegEx.test(location.pathname)) {
      navigate(`/accounts/${idOrNumber}/${activeTab}`)
    }
  }, [activeTab])

  useEffect(() => {
    if (activeTab === 'sites' && isEmpty(sites)) {
      fetchSites()
    }
    if (activeTab === 'partnerships' && isEmpty(partnerships)) {
      fetchPartnerships()
    }
    if (activeTab === 'contacts' && isEmpty(contacts)) {
      fetchContacts()
    }
    if (activeTab === 'orders' && isEmpty(orders)) {
      fetchOrders()
    }
    if (activeTab === 'services' && isEmpty(services)) {
      fetchServices()
    }
    if (activeTab === 'opportunities' && isEmpty(services)) {
      fetchOpportunities()
    }
    if (activeTab === 'bills' && isEmpty(bills)) {
      fetchBills()
    }
    if (activeTab === 'creditRequests' && isEmpty(creditRequests)) {
      fetchCreditRequests()
    }
    if (activeTab === 'cases' && isEmpty(cases)) {
      fetchCases()
    }
    if (activeTab === 'configItems' && isEmpty(configItems)) {
      fetchConfigItems()
    }
    if (activeTab === 'circuits' && isEmpty(circuits)) {
      fetchCircuits()
    }
    if (activeTab === 'documents' && isEmpty(documents)) {
      fetchDocuments()
    }
    if (activeTab === 'signatures' && isEmpty(signatures.items)) {
      fetchSignatures(1, 20, '', [{ id: 'updatedAt', desc: true }])
    }
    if (activeTab === 'logs' && isEmpty(logs)) {
      fetchLogs()
    }
  }, [activeTab])

  const initAccount = () => {
    const promises = []
    toggleLoading('info')
    toggleLoading('contacts')
    toggleLoading('sites')
    promises.push(dispatch(getAccountInfo(idOrNumber)))
    promises.push(dispatch(getAccountContacts(idOrNumber, ['portalUser'])).then(result => {
      if (result) {
        dispatch(setAccountContacts(result))
      }
    }))
    promises.push(dispatch(getAccountSites(idOrNumber, ['primaryContact'])).then((result) => {
      if (result) {
        dispatch(setAccountSites(result))
      }
    }))
    Promise.all(promises).then(() => {
      toggleLoading('info')
      toggleLoading('contacts')
      toggleLoading('sites')
    })
  }

  const toggleTab = useCallback((tab) => setActiveTab(tab), [])

  const toggleLoading = useCallback((tabName) => {
    setLoading(prevState => ({ ...prevState, [tabName]: !prevState[tabName] }))
  }, [])

  const togglePortalInvite = useCallback(() => {
    setShowPortalInvite(prevState => !prevState)
  }, [])

  const getLoading = useCallback(tabName => loading[tabName] ?? false, [loading])

  const fetchAccountInfo = useCallback(() => {
    toggleLoading('info')
    dispatch(getAccountInfo(idOrNumber)).then(() => toggleLoading('info'))
  }, [idOrNumber])

  const fetchSites = useCallback(() => {
    toggleLoading('sites')
    dispatch(getAccountSites(idOrNumber, ['primaryContact'])).then((result) => {
      if (result) {
        dispatch(setAccountSites(result))
      }
      toggleLoading('sites')
    })
  }, [idOrNumber])

  const fetchPartnerships = useCallback(() => {
    toggleLoading('partnerships')
    dispatch(getAccountPartnerships(idOrNumber, ['partner', 'customer'])).then((result) => {
      if (result) {
        dispatch(setAccountPartnerships(result))
      }
      toggleLoading('partnerships')
    })
  }, [idOrNumber])

  const fetchContacts = useCallback(() => {
    toggleLoading('contacts')
    dispatch(getAccountContacts(idOrNumber, ['portalUser'])).then((result) => {
      if (result) {
        dispatch(setAccountContacts(result))
      }
      toggleLoading('contacts')
    })
  }, [idOrNumber])

  const fetchOrders = useCallback(() => {
    toggleLoading('orders')
    dispatch(getAccountOrders(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountOrders(result))
      }
      toggleLoading('orders')
    })
  }, [idOrNumber])

  const fetchServices = useCallback(() => {
    toggleLoading('services')
    let promises = []
    if (isEmpty(sites)) {
      toggleLoading('sites')
      promises.push(dispatch(getAccountSites(idOrNumber, ['primaryContact'])).then((result) => {
        if (result) {
          dispatch(setAccountSites(result))
        }
        toggleLoading('sites')
      }))
    }
    promises.push(dispatch(getAccountServices(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountServices(result))
      }
    }))
    Promise.all(promises).then(() => toggleLoading('services'))
  }, [sites, idOrNumber])

  const fetchOpportunities = useCallback(() => {
    toggleLoading('opportunities')
    dispatch(getAccountOpportunities(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountOpportunities(result))
      }
      toggleLoading('opportunities')
    })
  }, [idOrNumber])

  const fetchBills = useCallback(() => {
    toggleLoading('bills')
    dispatch(getAccountBills(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountBills(result))
      }
      toggleLoading('bills')
    })
  }, [idOrNumber])

  const fetchCases = useCallback(() => {
    toggleLoading('cases')
    dispatch(getAccountCases(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountCases(result))
      }
      toggleLoading('cases')
    })
  }, [idOrNumber])

  const fetchConfigItems = useCallback(() => {
    toggleLoading('configItems')
    dispatch(getAccountConfigItems(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountConfigItems(result))
      }
      toggleLoading('configItems')
    })
  }, [idOrNumber])

  const fetchCircuits = useCallback(() => {
    toggleLoading('circuits')
    dispatch(getAccountCircuits(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountCircuits(result))
      }
      toggleLoading('circuits')
    })
  }, [idOrNumber])

  const fetchDocuments = useCallback(() => {
    toggleLoading('documents')
    dispatch(getAccountDocuments(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountDocuments(result))
      }
      toggleLoading('documents')
    })
  }, [idOrNumber])

  const fetchSignatures = useCallback((page, size, searchString, sorting) => {
    toggleLoading('signatures')
    dispatch(getAccountSignatures(idOrNumber, page, size, searchString, sorting)).then((result) => {
      if (result) {
        dispatch(setAccountSignatures(result))
      }
      toggleLoading('signatures')
    })
  }, [idOrNumber])

  const fetchLogs = useCallback(() => {
    toggleLoading('logs')
    dispatch(getAccountLogs(idOrNumber)).then((result) => {
      if (result) {
        dispatch(setAccountLogs(result))
      }
      toggleLoading('logs')
    })
  }, [idOrNumber])

  const fetchCreditRequests = useCallback(() => {
    toggleLoading('creditRequests')
    const promises = []
    if (isEmpty(bills)) {
      promises.push(dispatch(getAccountBills(idOrNumber)).then((result) => {
        if (result) {
          dispatch(setAccountBills(result))
        }
      }))
    }
    if (isEmpty(services)) {
      promises.push(dispatch(getAccountServices(idOrNumber)).then((result) => {
        if (result) {
          dispatch(setAccountServices(result))
        }
      }))
    }
    promises.push(dispatch(getAccountCreditRequests(idOrNumber)).then(result => {
      if (result) {
        dispatch(setAccountCreditRequests(result))
      }
    }))

    Promise.all(promises).then(() => {
      toggleLoading('creditRequests')
    })

  }, [bills, idOrNumber, services])

  const handleCurrentSignatureDownload = useCallback((sigId) => {
    dispatch(downloadCurrentSignatureDocument(sigId))
  }, [])

  const handleFinalSignatureDownload = useCallback((sigId) => {
    dispatch(downloadFinalSignatureDocument(sigId))
  }, [])

  const validated = useCallback(() => {
    setErrors([])
    const errors = formValidator({
      ...AccountForm.infoFields,
      ...AccountForm.contactFields,
      ...AccountForm.legalFields,
      ...AccountForm.billingFields,
    }, form)
    setErrors(errors)
    return isEmpty(errors)
  }, [form])

  const updateAccountInfo = useCallback(() => {
    if (validated() && hasChanges()) {
      const toSave = diff(original, form)
      toggleLoading('info')
      dispatch(updateAccount(idOrNumber, resolveArgs(toSave))).then((result) => {
        if (result?.errors) {
          setErrors(ApiErrorResolver(result.errors))
        }
        toggleLoading('info')
      })
    }
  }, [original, form, idOrNumber])

  const createNewAccount = useCallback(() => {
    if (validated()) {
      toggleLoading('info')
      dispatch(createAccount(form)).then((result) => {
        if (result?.errors) {
          setErrors(ApiErrorResolver(result.errors))
        }else if (result) {
          onAccountCreated(result);
          navigate(`/accounts/${result.accountid}`)
        }

        toggleLoading('info')
      })
    }
  }, [form])

  const onAccountCreated = (data) => {
    if (data.customerType === AccountEnums.type.SUPPLIER) {
      dispatch(addSupplierToCRMSuppliers({
        id: data.accountid,
        accountNumber: data.accountnumber,
        ...pick(data, ['name', 'isActive'])
      }))
    }
  }

  const sendInviteToContact = useCallback(() => {
    setInviteErrors([])
    if (!inviteContact?.email || !isEmail(inviteContact.email)) {
      setInviteErrors([`${inviteContact.name} has no valid email address`])
      return
    }
    toggleLoading('invite')
    dispatch(inviteToPortal(original.accountid, inviteContact.id)).then((result) => {
      if (result.errors) {
        setInviteErrors(ApiErrorResolver(result.errors))
      } else if (result) {
        togglePortalInvite()
      }
      toggleLoading('invite')
    })
  }, [inviteContact?.email, inviteContact?.name, original?.accountid, inviteContact?.id])

  const deleteDocument = useCallback((id) => {
    toggleLoading('documents')
    dispatch(deleteAccountDocument(original.accountid, id)).then((result) => {
      if (result) {
        dispatch(removeAccountDocument(id))
      }
      toggleLoading('documents')
    })
  }, [original.accountid])

  const onCreatedContact = useCallback((data) => {
    dispatch(addAccountContact(data))
  }, [])

  const onUpdatedContact = useCallback((data) => {
    dispatch(updateAccountContact(data))
  }, [])

  const dropCache = useCallback(() => {
    toggleLoading('cache')
    dispatch(dropAccountCache(original.accountid))
      .then(() => toggleLoading('cache'))
  }, [original.accountid])

  const hasChanges = useCallback(() => {
    const changes = diff(original, form)
    return !isEmpty(changes)
  }, [original, form])

  const isActive = useCallback(() => original.isActive, [original.isActive])

  const isNew = useCallback(() => idOrNumber === 'new', [idOrNumber])

  const isPartnershipType = useCallback(() => {
    return [
      AccountEnums.type.RESALE_PARTNER,
      AccountEnums.type.RESALE_CUSTOMER,
      AccountEnums.type.BUSINESS_PARTNER,
      AccountEnums.type.BUSINESS_CUSTOMER
    ].includes(original.customerType)
  }, [original.customerType])

  const isPartnerCustomer = useCallback(() => {
    return [
      AccountEnums.type.RESALE_CUSTOMER,
      AccountEnums.type.BUSINESS_CUSTOMER
    ].includes(original.customerType)
  }, [original.customerType])

  const emulate = useCallback((url, portal) => {
    window.open(url, portal)
  }, [])

  const emulateUser = useCallback((contact) => {
    toggleLoading('contacts')
    dispatch(getEmulatorUrl(contact.id))
      .then(
        (result) => {
          if (result) {
            emulate(result.url, result.portal)
          }
          toggleLoading('contacts')
        }
      )
  }, [])

  const getInviteContacts = useCallback(() => {
    return contacts.filter(contact => contact.isActive).map(contact => {
      return {
        value: contact.id,
        label: contact.name,
      }
    })
  }, [contacts])

  const onClosing = useCallback(() => {
    if (hasChanges() || isNew()) {
      dispatch(setConfirmDialog({
        color: 'danger',
        text: 'You have unsaved changes! Closing this window will result losing the changes you\'ve made.',
        proceed: () => toggleModal()
      }))
    } else if (toggleModal) {
      toggleModal()
    }
  }, [])

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <Card className="border-0 mb-0">
        <CardBody>
          <Row className={'mb-3'}>
            <Col className={'d-flex'}>
              <span className={'font-xl text-primary fw-bold'}>
                {original.name} {original.accountnumber ? ` - ${original.accountnumber}` : ''}
              </span>
              {activeTab === 'info' &&
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    !isNew() && hasChanges() && !getLoading('info') && <UnsavedChangesAlert save={updateAccountInfo}/>
                  }
                  {original.hasPortalAccess &&
                    <Alert className="ms-2 mt-0 mb-0 px-2 py-1" color={'success'}>
                      <span className="font-xs">registered</span>
                    </Alert>
                  }
                  {
                    !isNew() && !getLoading('info') && !original.hasPortalAccess &&
                    <Button
                      className={'ms-2'}
                      onClick={togglePortalInvite}
                      size={'sm'}
                      color={'secondary'}>
                      Invite to Portal
                    </Button>

                  }
                  <ButtonIcon disabled={!hasChanges()} icon={'fa fa-save'} tooltip={'save'}
                              onClick={isNew() ? createNewAccount : updateAccountInfo}/>
                  <ButtonIcon disabled={isNew()} icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchAccountInfo}/>
                  {toggleModal &&
                    <ButtonIcon onClick={onClosing} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              }
            </Col>
          </Row>
          {!isNew() && original.accountid &&
            <DeactivatedEntityWarning deactivated={!isActive()} message={'This Account has been deactivated.'}/>}
          {!isNew() && !isEmpty(original.primaryContact) &&
            <DeactivatedEntityWarning deactivated={!original.primaryContact.isActive}
                                      message={'Deactivated Primary Contact Warning!'}/>}
          <Nav tabs>
            <NavItem>
              <NavLink href="#"
                       className={classnames({ active: activeTab === 'info' })}
                       onClick={() => toggleTab('info')}>
                Info
              </NavLink>
            </NavItem>
            {!isNew() &&
              <>
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'sites' })}
                           onClick={() => toggleTab('sites')}>
                    Sites
                  </NavLink>
                </NavItem>
                {isPartnershipType() &&
                  <NavItem>
                    <NavLink href="#"
                             className={classnames({ active: activeTab === 'partnerships' })}
                             onClick={() => toggleTab('partnerships')}>
                      Partnerships
                    </NavLink>
                  </NavItem>
                }
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'contacts' })}
                           onClick={() => toggleTab('contacts')}>
                    Contacts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'orders' })}
                           onClick={() => toggleTab('orders')}>
                    Orders
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'services' })}
                           onClick={() => toggleTab('services')}>
                    Services
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'opportunities' })}
                           onClick={() => toggleTab('opportunities')}>
                    Opportunities
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'bills' })}
                           onClick={() => toggleTab('bills')}>
                    Bills
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#"
                           className={classnames({ active: activeTab === 'cases' })}
                           onClick={() => toggleTab('cases')}>
                    Cases
                  </NavLink>
                </NavItem>
                <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle nav caret>
                    More
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => toggleTab('cache')}>Cached Data</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('circuits')}>Circuits</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('configItems')}>Config Items</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('creditRequests')}>Credit Requests</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('documents')}>Documents</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('logs')}>Logs</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('reports')}>Reports</DropdownItem>
                    <DropdownItem onClick={() => toggleTab('signatures')}>Signatures</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            }

          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={'info'}>
              <AccountInfo
                isNew={isNew()}
                errors={errors}
                loading={getLoading('info')}
                fetchData={fetchAccountInfo}
              />
            </TabPane>
            <TabPane tabId={'sites'}>
              {activeTab === 'sites' &&
                <LoadingOverlay loading={getLoading('sites')}>
                  <SitesTable sites={sites} account={original} fetchData={fetchSites}/>
                </LoadingOverlay>
              }
            </TabPane>
            <TabPane tabId={'partnerships'}>
              {activeTab === 'partnerships' &&
                <LoadingOverlay loading={getLoading('partnerships')}>
                  <PartnershipsTable partnerships={partnerships} account={original} fetchData={fetchPartnerships}
                                     isCustomer={isPartnerCustomer()}/>
                </LoadingOverlay>
              }
            </TabPane>
            <TabPane tabId={'contacts'}>
              {activeTab === 'contacts' &&
                <LoadingOverlay loading={getLoading('contacts')}>
                  <ContactsTable
                    showContact={searchParams.get('showContact')}
                    emulate={emulateUser}
                    fetchData={fetchContacts}
                    contacts={contacts}
                    account={original}
                    onUpdated={onUpdatedContact}
                    onCreated={onCreatedContact}/>
                </LoadingOverlay>
              }

            </TabPane>
            <TabPane tabId={'orders'}>
              {activeTab === 'orders' &&
                <LoadingOverlay loading={getLoading('orders')}>
                  <AccountOrdersTable fetchData={fetchOrders} orders={orders} account={original} contacts={contacts}/>
                </LoadingOverlay>
              }

            </TabPane>
            <TabPane tabId={'services'}>
              {activeTab === 'services' &&
                <LoadingOverlay loading={getLoading('services')}>
                  <OrderProductsTable
                    withDeactivatedRow
                    withStateFilter
                    excludeColumns={['circuits']}
                    size={'lg'}
                    products={services}
                    sites={sites}
                    contacts={contacts}
                    fetchData={fetchServices}
                  />
                </LoadingOverlay>
              }

            </TabPane>
            <TabPane tabId={'opportunities'}>
              {activeTab === 'opportunities' &&
                <LoadingOverlay loading={getLoading('opportunities')}>
                  <OpportunitiesTable
                    account={original}
                    user={auth.account}
                    opportunities={opportunities}
                    fetchData={fetchOpportunities}
                    excludeColumns={['customer']}
                  />
                </LoadingOverlay>
              }
            </TabPane>
            <TabPane tabId={'bills'}>
              {activeTab === 'bills' &&
                <LoadingOverlay loading={getLoading('bills')}>
                  <BillsTable bills={bills} fetchData={fetchBills}/>
                </LoadingOverlay>
              }

            </TabPane>
            <TabPane tabId={'creditRequests'}>
              {activeTab === 'creditRequests' &&
                <>
                  <CardTitleBold className={'mb-0'}>Credit Requests</CardTitleBold>
                  <LoadingOverlay loading={getLoading('creditRequests')}>
                    <CreditRequestsTable
                      creditRequests={creditRequests}
                      fetchData={fetchCreditRequests}
                      account={original}
                      onCreated={creditRequest => dispatch(addAccountCreditRequest(creditRequest))}
                      onUpdated={creditRequest => dispatch(updateAccountCreditRequest(creditRequest))}
                    />
                  </LoadingOverlay>
                </>
              }
            </TabPane>
            <TabPane tabId={'cases'}>
              {activeTab === 'cases' &&
                <LoadingOverlay loading={getLoading('cases')}>
                  <CasesTable cases={cases} fetchData={fetchCases}/>
                </LoadingOverlay>
              }

            </TabPane>
            <TabPane tabId={'configItems'}>
              {activeTab === 'configItems' &&
                <>
                  <CardTitleBold>Config Items</CardTitleBold>
                  <LoadingOverlay loading={getLoading('configItems')}>
                    <ConfigItemsTable configItems={configItems} fetchData={fetchConfigItems}/>
                  </LoadingOverlay>
                </>

              }
            </TabPane>
            <TabPane tabId={'circuits'}>
              {activeTab === 'circuits' &&
                <>
                  <CardTitleBold>Circuits</CardTitleBold>
                  <LoadingOverlay loading={getLoading('circuits')}>
                    <CircuitsTable circuits={circuits} fetchData={fetchCircuits} withNew={false}/>
                  </LoadingOverlay>
                </>
              }

            </TabPane>
            <TabPane tabId={'documents'}>
              {activeTab === 'documents' &&
                <>
                  <CardTitleBold>Documents</CardTitleBold>
                  <LoadingOverlay loading={getLoading('documents')}>
                    <AccountDocumentsTable documents={documents} fetchData={fetchDocuments} remove={deleteDocument}/>
                  </LoadingOverlay>
                </>

              }
            </TabPane>
            <TabPane tabId={'signatures'}>
              {activeTab === 'signatures' &&
                <>
                  <CardTitleBold>Signatures</CardTitleBold>
                  <LoadingOverlay loading={getLoading('signatures')}>
                    <AccountSignaturesTable
                      signatures={signatures}
                      fetchData={fetchSignatures}
                      user={auth.account}
                      handleCurrentDownload={handleCurrentSignatureDownload}
                      handleFinalDownload={handleFinalSignatureDownload}
                    />
                  </LoadingOverlay>
                </>

              }

            </TabPane>
            <TabPane tabId={'reports'}>

              {activeTab === 'reports' &&
                <>
                  <CardTitleBold>Reports</CardTitleBold>
                  <AccountReports/>
                </>

              }

            </TabPane>
            <TabPane tabId={'cache'}>
              {activeTab === 'cache' &&
                <>
                  <CardTitleBold>Cache</CardTitleBold>
                  <AccountCache
                    handleCache={dropCache}
                    processing={getLoading('cache')}
                  />
                </>
              }

            </TabPane>
            <TabPane tabId={'logs'}>
              <CardTitleBold className={'mb-0'}>Logs</CardTitleBold>
              {activeTab === 'logs' &&
                <LoadingOverlay loading={getLoading('logs')}>
                  <AccountLogsTable logs={logs}/>
                </LoadingOverlay>
              }

            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <HeadlessModal
        open={showPortalInvite}
        toggle={togglePortalInvite}
        className={'modal-lg'}
        onClosed={() => {
          setInviteContact(null)
          setInviteErrors([])
        }}

      >
        <div className={'animated fadeIn p-3'}>
          <LoadingOverlay loading={getLoading('invite')}>
            <FormValidationErrors errors={inviteErrors}/>
            <CardTitleBold>
              Select a Contact
            </CardTitleBold>
            <Row>
              <Col>
                <SelectMod
                  name="addressCountryCode"
                  id={'addressCountryCode'}
                  options={getInviteContacts()}
                  onChange={selected => setInviteContact(contacts.find(contact => contact.id === selected.value))}
                />
              </Col>
            </Row>
            <Row className={'mt-3'}>
              <Col className={'d-flex justify-content-end'}>
                <Button size={'sm'} color={'primary'} onClick={sendInviteToContact}
                        disabled={!inviteContact}>Invite</Button>
                <Button size={'sm'} className={'ms-2'} onClick={togglePortalInvite}>Cancel</Button>
              </Col>
            </Row>
          </LoadingOverlay>
        </div>
      </HeadlessModal>
    </div>
  )

}

export default Account
